import * as React from "react";
import ReactDOM from "react-dom";
import { ILoader, ILoaderState } from "../common/common.models";
import { LoaderComponent } from "../components/loader/loader.component";

export const LoaderContext = React.createContext<ILoader>(null!);

export const LoaderContextComponent = (props: any) => {
  const { children } = props;

  const [state, setState] = React.useState<ILoaderState>({
    show: false,
  });

  const show = () => {
    document.body.style.overflow = "hidden";
    setState({
      show: true
    });
  };
  
  const hide = () => {
    document.body.style.overflow = "initial";
    setState({
      show: false
    });
  };

  return(
    <LoaderContext.Provider
      value={{
        show: show,
        hide: hide,
        state: state
      }}
    >
      {children}
      {state.show &&
        ReactDOM.createPortal(
          <LoaderComponent />,
          document.body
        )
      }
    </LoaderContext.Provider>
  );
};