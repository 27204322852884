import { StyledInput } from "./input.style";
import * as React from "react";

/**
 * INPUT COMPONENT
 *
 *
 * @param {string} icon   = fontawesome code
 * @param {string} errors = diplay errors under input element
 *
 *
 */

export const InputElement = (props: any) => {
  const [isRevealPwd, setIsRevealPwd] = React.useState(false);
  const {
    type,
    optional = false,
    className,
    label,
    checked,
    errors,
    hidden,
    hint,
    externalRegister,
    placeholder,
    id,
  } = props;

  const optionalMessage = () => {
    return <span>(opzionale)</span>;
  };

  return (
    <>
      <StyledInput
        className={`cmp_input ${className} ${hidden && "hidden"} ${hint && "hint"
          }`}
      >
        {type === "password" && (
          <>
            <div className="pwd-container">
              <input
                {...props}
                {...externalRegister}
                autoComplete="new-password"
                checked={checked}
                placeholder={placeholder ? placeholder : " "}
                type={isRevealPwd ? "text" : "password"}
                id={id}
                className=""
              />
              {label && (
                <>
                  <label className="" htmlFor={id}>
                    {label}
                    {optional && optionalMessage()}
                    {hint && (
                      <i
                        className="ml-2 fas fa-info-circle"
                        aria-haspopup="true"
                        data-toggle="collapse"
                        aria-expanded="false"
                        data-target="#delivery-compatible-info"
                      ></i>
                    )}
                  </label>
                  {hint && (
                    <div
                      className="hint-message p-2 collapse"
                      id="delivery-compatible-info"
                    >
                      {hint}
                    </div>
                  )}
                </>
              )}

              {!label && hint && (
                <>
                  <label className="text-left label-hint">
                    {hint && (
                      <i
                        className="ml-2 fas fa-info-circle"
                        aria-haspopup="true"
                        data-toggle="collapse"
                        aria-expanded="false"
                        data-target="#delivery-compatible-info"
                      ></i>
                    )}
                  </label>
                  {hint && (
                    <div
                      className="hint-message p-2 collapse"
                      id="delivery-compatible-info"
                    >
                      {hint}
                    </div>
                  )}
                </>
              )}
              <div
                className={
                  isRevealPwd
                    ? "pwdVisibility far fa-eye-slash"
                    : " pwdVisibility far fa-eye"
                }
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
          </>
        )}
        {type !== "password" && (
          <>
            <input
              {...props}
              {...externalRegister}
              placeholder={placeholder ? placeholder : " "}
              type={type}
              checked={checked}
              id={id}
              className=""
            />

            {label && (
              <>
                <label className="" htmlFor={id}>
                  {label}
                  {optional && optionalMessage()}
                  {hint && (
                    <i
                      className="ml-2 fas fa-info-circle"
                      aria-haspopup="true"
                      data-toggle="collapse"
                      aria-expanded="false"
                      data-target="#delivery-compatible-info"
                    ></i>
                  )}
                </label>
                {hint && (
                  <div
                    className="hint-message p-2 collapse"
                    id="delivery-compatible-info"
                  >
                    {hint}
                  </div>
                )}
              </>
            )}

            {!label && hint && (
              <>
                <label className="text-left label-hint">
                  {hint && (
                    <i
                      className="ml-2 fas fa-info-circle"
                      aria-haspopup="true"
                      data-toggle="collapse"
                      aria-expanded="false"
                      data-target="#delivery-compatible-info"
                    ></i>
                  )}
                </label>
                {hint && (
                  <div
                    className="hint-message p-2 collapse"
                    id="delivery-compatible-info"
                  >
                    {hint}
                  </div>
                )}
              </>
            )}
          </>
        )}

        {errors && <p className="error message--alert small">{errors}</p>}
      </StyledInput>
    </>
  );
};
