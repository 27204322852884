import { EAddressType, EShippingType } from "../../common/common.models"

export const ESetAddress = {
  [EAddressType.Shipping]: "setShippingAddress",
  [EAddressType.Billing]: "setBillingAddress",
}

export const ESelectedAddress: { [EAddressType.Shipping]: "shippingAddress", [EAddressType.Billing]: "billingAddress" } = {
  [EAddressType.Shipping]: "shippingAddress",
  [EAddressType.Billing]: "billingAddress",
}

export const EAddAddressId = {
  [EAddressType.Shipping]: "addShippingAddressId",
  [EAddressType.Billing]: "addBillingAddressId",
}

export const EUserDataAddressesIdentifier: { [EAddressType.Shipping]: "shippingAddressIds", [EAddressType.Billing]: "billingAddressIds" } = {
  [EAddressType.Shipping]: "shippingAddressIds",
  [EAddressType.Billing]: "billingAddressIds",
}

export interface IAddressList {
  type: EAddressType;
  shippingType?: EShippingType;
  callbackData?: any;
  className?:string;  
}