import * as React from "react";
import ReactDOM from "react-dom";
import { Login } from "../components/login/login.component";
import { SignupPreview } from "../components/signup/signupPreview.component";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { CustomercareInfoBoxes } from "../components/customercare/customercareInfo.component";
import { StyledPages } from "./commonPages.style";
import { GeneralContext } from "../core/general.context";
import { LabelContext } from "../core/label.context";

export const LoginPage = (props: any) => {
    const container = document.getElementById("login");
    const { userData } = React.useContext(GeneralContext);
    const { t } = React.useContext(LabelContext);


    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
            {!userData &&
                <StyledPages>
                    <HeaderTitle title={t("ecommerce.login.page.title")} message={t("ecommerce.login.page.message")}></HeaderTitle>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h2 className="text-center">{t("ecommerce.login.is-registerd-title")}</h2>
                                <Login></Login>
                            </div>
                            <div className="col-md-6">
                                <h2 className="text-center">{t("ecommerce.login.is-new-user-title")}</h2>
                                <SignupPreview></SignupPreview>
                            </div>
                            <CustomercareInfoBoxes display="row" />
                        </div>
                    </div>
                </StyledPages>
            }            
            </>
            , container
        )
};