import * as React from "react"
import { Box } from "../box/box.component"
import { StyledAuthentication } from "./authentication.style"
import { useForm} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { LabelContext } from "../../core/label.context"
import { UserContext } from "../../core/user.context"
import { inputMaxLength } from "../../common/constants"
import { LoginAsGuest } from "./loginAsGuest.component"
import { SignupPreview } from "../signup/signupPreview.component"
import { Login } from "./login.component"
import { isDesktop } from "react-device-detect"

/**
 * LOGIN COMPONENT
 *
 *
 * @param {string} show   = list of elements to show on login like(register)ì
 *
 *
 */


import { PROJECT_COUNTRY } from "../../core/APIUtils"

export const Authentication = (props: any) => {
  const { className } = props

  const { t } = React.useContext(LabelContext)

  let requiredError = t("ecommerce.form-messagge.required")
  let emailError = t("ecommerce.form-messagge.invalid-email")

  const schema = yup.object().shape({
    email: yup.string().email(emailError).required(requiredError),
    password: yup.string().max(inputMaxLength).required(requiredError),
  })

  const {
    register,
  } = useForm({
    resolver: yupResolver(schema),
  })


  // controllo al onChange se la password è settata
  const fieldPassword = register("password", { required: true })
  const origOnChange = fieldPassword.onChange
  fieldPassword.onChange = (e) => {
    const res = origOnChange(e)
    return res
  }

  const { setCustomerEmail } = React.useContext(UserContext)


  const accordions = [
    {
      id: 0,
      title: t("ecommerce.checkout.is-registerd-title"),
      content: <Login className={"hideBackground"} theme={"theme-minimal"} />,
      header: "headingOne",
      collapse: "collapseOne",
    },
    {
      id: 1,
      title: t("ecommerce.checkout.is-new-user-title"),
      content: <SignupPreview className={"hideBackground"} theme={"theme-minimal"}></SignupPreview>,
      header: "headingTwo",
      collapse: "collapseTwo",
    },
  ]

  if(PROJECT_COUNTRY !== "BE"){
    accordions.push({
      id: 2,
      title: t("ecommerce.checkout.guest-title"),
      content: (
          <LoginAsGuest
              onClick={(email: string) => {
                props.setGuest(true)
                setCustomerEmail(email)
              }}
              className={"hideBackground"}
              theme={"theme-minimal"}
          ></LoginAsGuest>
      ),
      header: "headingThree",
      collapse: "collapseThree",
    })
  }

  return (
    <StyledAuthentication className={className}>
      <Box className="secondary unbordered">
        <div className="row justify-content-center">
          <div className="col-12" style={{maxWidth:"550px"}}>
            <div id="accordion">
              {accordions.map((accordion, index) => (
                <div className="card" key={accordion.id}>
                  <div className="card-header head" id={accordion.header}>
                    <button
                      className={` btn btn-header-link w-100 text-decoration-none collapsed`}
                      data-toggle="collapse"
                      data-target={`#${accordion.collapse}`}
                      aria-expanded="false"
                      aria-controls={accordion.collapse}
                    >
                      <h3 className="header-link-title my-0">{accordion.title}</h3>
                    </button>
                  </div>
                  <div
                    id={accordion.collapse}
                    className={`collapse`}
                    aria-labelledby={accordion.header}
                    data-parent="#accordion"
                  >
                    <div className="card-body">{accordion.content}</div>
                  </div>
                </div>
              ))}

              {/* <div className="card">
                    <div className="" id="headingTwo">
                        <button className="btn btn-link collapsed w-100 text-left text-decoration-none" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <h2 className="mb-0">
                                {t("ecommerce.checkout.is-new-user-title")}
                            </h2>
                        </button>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="">
                            <SignupPreview></SignupPreview>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="" id="headingThree">
                        <button className="btn btn-link collapsed w-100 text-left text-decoration-none" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h2 className="mb-0">
                                {t("ecommerce.checkout.guest-title")}
                            </h2>
                        </button>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="">
                            <LoginAsGuest onClick={(email: string) => { setGuest(true); setCustomerEmail(email) }}></LoginAsGuest>												</div>
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </Box>
    </StyledAuthentication>
  )
}
