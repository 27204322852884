import * as React from "react";
import ReactDOM from "react-dom";
import { IAlert, IAlertState, IAlertOptions } from "../common/common.models";
import { Alert } from "../components/alert/alert.component";
import { RESTContext } from "./rest.context";

export const AlertContext = React.createContext<IAlert>(null!);

export const AlertContextComponent = (props: any) => {
  const { children } = props;

  const { AWSlogger } = React.useContext(RESTContext);

  const [state, setState] = React.useState<IAlertState>({
    show: false,
    options: {}
  });

  const show = (options?: IAlertOptions) => {    
    setState({
      show: true,
      options: options
    });
  };

  const hide = () => {
    setState({
      show: false,
      options: {}
    });
  };

  return(
    <AlertContext.Provider
      value={{
        show: show,
        hide: hide,
        state: state
      }}
    >
      {children}
      {state.show &&
        ReactDOM.createPortal(
          <Alert />,
          document.body
        )
      }
    </AlertContext.Provider>
  );
};