import * as React from "react";
import { GeneralContext } from "../../core/general.context";
import { Box } from "../box/box.component";
import { StyledUser } from "./user.style";
import { Property } from "../elements/property/property.component";
import { Button } from "../button/button.component";
import { Address } from "../elements/address/address.component";
import { AddAddress } from "../elements/address/address.component";
import { BannerContext } from "../../core/banner.context";
import { LabelContext } from "../../core/label.context";
import { EditUserData } from "./edituser.component";
import { BRX_BASE_URL, calls, PROJECT_COUNTRY, PROJECT_KEY, PROJECT_SETTINGS, PROJECT_CURRENCY } from "../../core/APIUtils";
import { UserContext } from "../../core/user.context";
import { RESTContext } from "../../core/rest.context"
import {createPrice, parsePrice} from "../../common/constants"
import {CartContext} from "../../core/cart.context"

export const User = (props: any) => {
  const { userData } = React.useContext(GeneralContext);
  const { addresses, billingAddressIds, shippingAddressIds } = userData;
  const { removeAddress } = React.useContext(UserContext);
  const [selectedAddress, setSelectedAddress] = React.useState<any>()
  const [selectedAddressType, setSelectedAddressType] = React.useState('shipping')
  const [formEditOpen, setFormEditOpen] = React.useState(false)
  const [emailSentMessage, setEmailSentMessge] = React.useState(false)
  const bannerConsumer = React.useContext(BannerContext);
  const { t } = React.useContext(LabelContext);
  const { ajax$ } = React.useContext(RESTContext)

  const billingAddressList = billingAddressIds?.map((item: string) => { return (addresses.find((address: any) => address.id === item)) });
  const shippingAddressList = shippingAddressIds?.map((item: string) => { return (addresses.find((address: any) => address.id === item)) });
  const gender = userData && (t("ecommerce.personalinfo." + userData.salutation));

  const handleVerifyEmail = () => {
    let body_verify = {
      "ttlMinutes": 720,
      "id": userData.id,
      "projectKey": PROJECT_KEY,
      "baseUrl": BRX_BASE_URL
    }
    ajax$(calls.Project.sendVerifyMail(), undefined, body_verify).subscribe(res => {
      setEmailSentMessge(true)
    })

  }

  return (
    <StyledUser>

      <Box className="unboxed secondary unbordered">
        <h2>{t("ecommerce.myaccount.personal-info-title")}</h2>
        {!formEditOpen &&
          <Box className="secondary">
            <div className="row">
              <div className="col-md-8">
                <Property label={t("ecommerce.personalinfo.email")} item={userData.email}>
                  <p className="emailVerifiedMessage"><i>{userData.isEmailVerified && t("ecommerce.myaccount.email-verified-short-message")}</i>
                    {!userData.isEmailVerified && !emailSentMessage && <a href="" className="secondary text-bold" style={{ color: 'var(--color-alert-dark)', fontSize: '0.8rem' }} onClick={(event) => { event.preventDefault(); handleVerifyEmail() }}>{t("ecommerce.myaccount.email-not-verified-short-message-link")}</a>}
                    {emailSentMessage && <div className="w-100 emailSend--success message--success text-bold">{t("ecommerce.myaccount.resent-verify-email-message")}</div>}</p>
                </Property>
                <Property label={t("ecommerce.personalinfo.name")} item={userData.firstName}></Property>
                <Property label={t("ecommerce.personalinfo.lastname")} item={userData.lastName}></Property>
                <Property label={t("ecommerce.personalinfo.birthdate")} item={userData.dateOfBirth}></Property>
                <Property label={t("ecommerce.personalinfo.gender")} item={gender}></Property>
                <>
                  {["DE"].includes(PROJECT_COUNTRY) && (
                    <>
                      <Property label={t("ecommerce.personalinfo.profiling")} item={userData?.custom?.fields?.consentProfiling ? t("ecommerce.personalinfo.consent-profiling-yes") : t("ecommerce.personalinfo.consent-profiling-no")}></Property>
                    </>
                  )}
                </>
              </div>
              <div className="col-md-2 ">
                <Button label={t("ecommerce.common.edit")} className="secondary" onClick={() => setFormEditOpen(true)}></Button>
              </div>

            </div>
          </Box>
        }
        {formEditOpen &&
          <div className="row">
            <div className="col-12 col-md-8">
              <Box className="secondary">
                <EditUserData callback={() => { setFormEditOpen(false) }} />
              </Box>
            </div>
          </div>
        }
      </Box>

      {!PROJECT_SETTINGS?.isKlarnaActive &&
        <div className="row">
          <div className="col-md-6">
            <Box className="secondary">
              <h3>{t("ecommerce.shippingAddress.shipping-address-title")}</h3>
              {selectedAddress && selectedAddressType && selectedAddressType === "shipping" &&
                <Box className="secondary unbordered unboxed">
                  <div className="row">
                    <div className="col">
                      <AddAddress type={selectedAddressType} dataEdit={selectedAddress} callback={() => { setSelectedAddress(null!); }} />
                    </div>
                  </div>
                </Box>
              }
              {shippingAddressList?.map((address: any, i) =>
                <Box className="secondary unbordered unboxed" key={address.id}>
                  <React.Fragment >
                    <Address item={address}></Address>
                    <div className="row">
                      <div className="col-md-12">
                        <Button label={t("ecommerce.common.edit")} className="simple actionForm" icon="fas fa-pencil-alt" onClick={() => { setSelectedAddressType("shipping"); setSelectedAddress(address) }}></Button>
                        <Button label={t("ecommerce.common.delete")} className="simple actionForm" icon="fas fa-trash" onClick={() => { bannerConsumer.confirm({ type: t("ecommerce.personalinfo.confirm-delete-address"), onConfirm: () => { removeAddress(address) } }) }}></Button>
                      </div>
                    </div>
                  </React.Fragment>
                </Box>
              )}
              <div className="row">
                <div className="col-md-2">
                  <Button label={t("ecommerce.shippingAddress.add-shipping-address")} className="simple" icon="fas fa-plus" onClick={() => { setSelectedAddressType("shipping"); setSelectedAddress({ country: PROJECT_COUNTRY }!) }}></Button>
                </div>
              </div>
            </Box>
          </div>
          <div className="col-md-6">
            <Box className="secondary">
              <h3>{t("ecommerce.billingAddress.billing-address-title")}</h3>

              {selectedAddress && selectedAddressType && selectedAddressType === "billing" &&
                <Box className="secondary unbordered unboxed">
                  <div className="row">
                    <div className="col">
                      <AddAddress type={selectedAddressType} dataEdit={selectedAddress} callback={() => { setSelectedAddress(null!) }} />
                    </div>
                  </div>
                </Box>
              }
              {billingAddressList?.map((address: any, i) =>
                <Box className="secondary unbordered unboxed" key={address.id}>
                  <React.Fragment key={i}>

                    <Address item={address}></Address>
                    <div className="row">
                      <div className="col-md-12">
                        <Button label={t("ecommerce.common.edit")} className="simple actionForm" icon="fas fa-pencil-alt" onClick={() => { setSelectedAddressType("billing"); setSelectedAddress(address) }}></Button>
                        <Button label={t("ecommerce.common.delete")} className="simple actionForm" icon="fas fa-trash" onClick={() => { bannerConsumer.confirm({ type: t("ecommerce.personalinfo.confirm-delete-address"), onConfirm: () => { removeAddress(address) } }) }}></Button>
                      </div>
                    </div>

                  </React.Fragment>
                </Box>
              )}


              <div className="row">
                <div className="col-md-2">
                  <Button label={t("ecommerce.billingAddress.add-billing-address")} className="simple" icon="fas fa-plus" onClick={() => { setSelectedAddressType("billing"); setSelectedAddress({ country: PROJECT_COUNTRY }!) }}></Button>
                </div>
              </div>

            </Box>
          </div>
        </div>
      }
    </StyledUser>
  );
};


