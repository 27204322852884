import styled from "styled-components"

export const StyledInput = styled.div`
  max-height: 1000px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  transition: max-height 0.5s ease-in-out;
  &.small {
    width: 50%;
    display: inline-block;
  }
  &.small input {
    width: 100%;
    display: inline;
    display: table;
  }
  &.small label {
    display: block;
  }

  select,
  input[type="checkbox"],
  input[type="radio"] {
    display: inline-block;
    width: auto;
    margin-left: 0px;
  }
  select,
  input {
    border-radius: none;
    width: 100%;
    background-color: var(--color-secondary);
    border: 1px solid var(--color-light-gray);
    padding: 10px;
    @media (min-width: 1200px) and (max-width: 1367px) {
      padding: 0 10px;
    }
  }
  &.small {
    width: 50%;
    display: inline-block;
  }
  &.small input {
    width: 100%;
    display: inline;
  }
  &.small label {
    display: block;
  }
  select,
  input[type="checkbox"],
  input[type="radio"] {
    display: inline-block;
    width: auto;
    margin-left: 0px;
  }
  select,
  input {
    border-radius: 0;
    width: 100%;
    background-color: var(--color-secondary);
    border: none;
    border-bottom: 1px solid var(--color-primary);
    padding: 10px;
    color: #000;
    @media (min-width: 1200px) and (max-width: 1367px) {
      padding: 0 10px;
    }
  }
  label {
    line-height: 20px;
  }
  select {
    text-align: center;
    text-align-last: center;

    // padding: 10px 15px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 0.65rem;
    text-transform: uppercase;
    @media (min-width: 1200px) and (max-width: 1367px) {
      padding: 10px;
    }
    option {
      text-align: left;
      padding: 16px 15px;
      font-weight: initial;
      font-size: 0.8rem;
      text-transform: initial;
    }
  }
  label + p.error {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  .form-group {
    text-align: left;
  }
  .inputDefault {
    background-color: var(--color-primary);
    color: var(--color-secondary);
  }
  .inputLabel {
    background-color: var(--color-alert);
    color: var(--color-secondary);
  }
  .message--alert {
    color: #ff0000;
    font-size: 0.8rem;
    text-align: center;
  }
  &.hidden {
    display: none;
  }
  .pwd-container {
    position: relative;
    .pwdVisibility {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -5px;
      padding: 10px;
    }
  }
  .hint-message {
    background-color: var(--color-porcelain);
    line-height: initial;
  }
  input,
  input[type="date"],
  select,
  .pwd-container {
    &::placeholder {
      opacity: 1;
      font-family: 'Mulish', sans-serif;
      font-size: 0.9rem;
    }
  }
  select {
    @media (min-width: 768px) {
      margin-top: -18px;
    }
  }
  .pwd-container input {
    margin-top: unset;
  }
  &.hint {
    input {
      padding-left: 1rem;
    }
    position: relative;
    label.label-hint {
      position: absolute;
      left: 0;
      top: 3rem;
    }
  }

  &.cmp_input {
    display: flex;
    flex-flow: column;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    position: relative;
    &[class*="col-"] {
      input:focus + label,
      input:not(:placeholder-shown) + label {
        top: -20px;
        left: calc(0% + 15px);
        transform: translateX(0%);
      }
    }

    &.height-0 {
      height: 0;
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      margin: 0;
      padding: 0;
      flex-basis: 0;
      display: static;
      transition: opacity 1s ease-in-out;
      transition: max-height 1s ease-in-out;
    }
  }

  label,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
  }
  input {
    font-size: 0.9rem;
    border: 0;
    border-bottom: 1px solid var(--color-primary);
    -webkit-appearance: none;
    border-radius: 0;
    cursor: text;
    text-align: center;
    font-family: 'Mulish', sans-serif;
    &:not(:placeholder-shown) + label {
      top: -20px;
      left: 0%;
      transform: translateX(0%);
    }
    & + label,
    &:placeholder-shown + label {
      cursor: text;
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "Raleway", sans-serif;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::placeholder {
      opacity: 0;
    }
    &:focus + label {
      cursor: pointer;
      color: var(--color-primary);
      top: -20px;
      left: 0%;
      transform: translateX(0%);
    }
    &:not(:focus) {
      &:not(:placeholder-shown) + label {
        top: -20px;
        left: 0%;
        transform: translateX(0%);
      }
    }
    &:focus {
      outline: 0;
      border-bottom: 1px solid #666;
    }
  }

  #birthday {
    padding-left: 50px;
  }

  label {
    display: flex;
    margin-bottom: unset;
    font-size: 0.65rem;
    font-weight: bold;
    text-align: center;
    color: var(--color-primary);
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    transform: translate(0, -1.5rem);
    cursor: text;
  }

  textarea {
    border: .5px solid #000;
    border-radius: 0;
  }
`
