import * as React from "react";
import {createPrice, parsePrice} from "../../common/constants";
import { GeneralContext } from "../../core/general.context";
import { CustomercareInfoTelephone } from "../customercare/customercareInfoTelephone.component";
import { CustomercareInfoCompressed } from "../customercare/customercareInfoCompressed.component";
import { LabelContext } from "../../core/label.context";
import {
  ADYEN_ENABLEDPAYMENTMETHODS,
  BRX_BASE_URL,
  BRX_LOCALE,
  PAYMENT_METHODS,
  IS_GIFT_CARD_ACTIVE,
  PROJECT_CURRENCY,
  MAX_GIFTCARDS_IN_CART
} from "../../core/APIUtils";
import { CartContext } from "../../core/cart.context";
import { Button } from "../button/button.component";
import { PromoCodeForm } from "../promoCode/promoCodeForm.component";
import { YounitedPay } from "../younitedPay/younitedPay.component";

export const CartRecap = (props: any) => {
  const { t } = React.useContext(LabelContext);
  let isCartPage = props.isCartPage;
  const { cart, giftCards } = React.useContext(GeneralContext);
  const {
    isCartChanged,
    checkIsCartChange,
    hasServicesInCart,
    productsPrices,
    servicesPrices,
    savingTotalPrice,
    loadGiftCards,
    checkGiftCardMaxQtaReached,
    maxGiftCardsReached,
  } = React.useContext(CartContext);
  const [hasGiftCards, setHasGiftCards] = React.useState(false);
  const [giftCardAmountAddedToCart, setGiftCardAmountAddedToCart] = React.useState<number>(0);
  const [marginTop, setMarginTop] = React.useState<string>("");
  const [accessWay, setAccessWay] = React.useState<any>(undefined);


  const [promoValue, setPromoValue] = React.useState({ promo: "" });
  const setPromoValue_ = (prop: any) => {
    setPromoValue(prev => ({
      ...prev,
      ...prop
    }));
  };
  let klarna_snippet = BRX_LOCALE === "de_DE" ? 
      "<klarna-placement\n" +
      "   data-key=\"top-strip-promotion-badge\"\n" +
      "   data-locale=\"{LOCALE}\"\n" +
      "></klarna-placement> " 
      : 
      "<klarna-placement\n" +
      "   data-key=\"credit-promotion-badge\"\n" +
      "   data-locale=\"{LOCALE}\"\n" +
      "   data-purchase-amount=\"{PRICE}\"\n" +
      "></klarna-placement> "
  klarna_snippet = klarna_snippet.replace('{LOCALE}', BRX_LOCALE.replace("_","-"))
  klarna_snippet = klarna_snippet.replace('{PRICE}', cart.totalPrice.centAmount.toString())

  let priceDetailLabel = t("ecommerce.checkout.recap.priceDetail")
  const maxGiftCardReachedLabel = t("ecommerce.checkout.max-giftcards-reached").replace("[NUMBER]", MAX_GIFTCARDS_IN_CART.toString());

  React.useEffect(() => {
    checkIsCartChange(cart)
    checkGiftCardMaxQtaReached(cart)
    if(ADYEN_ENABLEDPAYMENTMETHODS.indexOf("klarna_account") !== -1){
      window.KlarnaOnsiteService?.push({ eventName: 'refresh-placements' });
    }

    let total = 0
    cart?.customLineItems.map((item: any) => {
      if(item.slug.startsWith('gift-card-')){
        total += Math.abs(item.money.centAmount)
      }
    });
    setGiftCardAmountAddedToCart(total)
  }, [cart])

  React.useEffect(() => {
    if(IS_GIFT_CARD_ACTIVE && isCartPage){
      //carica le giftcard
      loadGiftCards(true);
    }
  }, []);

  React.useEffect(() => {
    if(giftCards != null && giftCards.length > 0){
      setHasGiftCards(true)
    }
  }, [giftCards]);

  const targetRef = React.useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const handleScroll = () => {
    const isMobile = window.innerWidth < 767;
    const targetRect = targetRef.current?.getBoundingClientRect();
    const isTargetVisible = isMobile && targetRect && targetRect.top < window.innerHeight && targetRect.bottom > 0;
    if(accessWay === undefined || accessWay?.length === 0){
      setAccessWay(document.querySelectorAll('access-widget-ui[data-acsb=""]'));
    }
    setIsVisible(isTargetVisible ?? false);

    //gestisce eventuali resize per mantenere il cart.recap in modalitá sitcky
    updateMarginTop()
  };

  const updateMarginTop = () => {
    const element = document.getElementsByClassName("base-layout")[0];
    if (element) {
      const style = window.getComputedStyle(element);
      setMarginTop(style.getPropertyValue('margin-top'));
    }
  };

  const callback = (entries: any) => {
    entries.forEach((entry: any) => {
      setIsVisible(entry.isIntersecting);
    });
  };

  const observer = new IntersectionObserver(callback);

  React.useEffect(() => {
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    const cookiefirstRoot = document.querySelector('.cookiefirst-root');
    const footerBackToTop = document.querySelector('.footer_backToTop');

    if (isVisible && window.innerWidth < 767) {
      cookiefirstRoot?.classList.remove('moveTop');
      footerBackToTop?.classList.remove('moveTop');
      accessWay?.forEach((element: any) => element.classList.remove('moveTop'));

    } else {
      cookiefirstRoot?.classList.add('moveTop');
      footerBackToTop?.classList.add('moveTop');
      accessWay?.forEach((element: any) => element.classList.add('moveTop'));
    }
  }, [isVisible, accessWay]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return <>

      <div className="wrapper"  style={{top: marginTop}}>

      {isCartPage && <PromoCodeForm />}
      <div className="checkout-priceRecap">
        <div className="priceRecap-prices">
          <div className="priceRecap-label">{t("ecommerce.checkout.recap.subtotal")}:</div>

          <div className="prices">
            {productsPrices.totalPriceDiscounted.centAmount !== productsPrices.totalPrice.centAmount
              ?
              <>
                <div className="price price--strikethrough">{parsePrice(productsPrices.totalPrice)}</div>
                <div className="price price--discounted">{parsePrice(productsPrices.totalPriceDiscounted)}</div>
              </>

              : ///////////// else
              <>
                <div className="price price--discounted">{parsePrice(productsPrices.totalPrice)}</div>
              </>
            }
          </div>

        </div>
        {priceDetailLabel && <div className="priceRecap-label">{priceDetailLabel}</div>}
        {hasServicesInCart &&
          <div className="priceRecap-prices">
            <div className="priceRecap-label">{t("ecommerce.checkout.recap.services")}:</div>
            <div className="prices">
              {servicesPrices.totalPriceDiscounted.centAmount !== servicesPrices.totalPrice.centAmount
                ?
                <>
                  <div className="price price--strikethrough">{parsePrice(servicesPrices.totalPrice)}</div>
                  <div className="price price--discounted">{parsePrice(servicesPrices.totalPriceDiscounted)}</div>
                </>

                : ///////////// else
                <>
                  <div className="price price--discounted">{parsePrice(servicesPrices.totalPrice)}</div>
                </>
              }
            </div>
          </div>
        }

        {cart?.shippingInfo?.price && cart?.shippingInfo?.price &&
          <div className="priceRecap-prices">
            <div className="priceRecap-label">{t("ecommerce.checkout.recap.shipping")}:</div>
            <div className="prices">
              <div className="price price--discounted">{
                cart?.shippingInfo?.price && cart?.shippingInfo?.price?.centAmount > 0 ?
                parsePrice(cart?.shippingInfo?.price)
                : cart?.shippingInfo?.price && cart?.shippingInfo?.price?.centAmount === 0 ?
                t("ecommerce.checkout.free")
                :null
              }</div>
            </div>
          </div>
        }

        {IS_GIFT_CARD_ACTIVE && giftCardAmountAddedToCart > 0 &&
          <div className="priceRecap-prices">
            <div className="priceRecap-label">{t("ecommerce.giftcard.amountAddedToCartLabel")}:</div>
            <div className="price">- {parsePrice(createPrice(giftCardAmountAddedToCart, PROJECT_CURRENCY))}</div>
          </div>
        }
        
        <div className="priceRecap-total">
          <div className="priceRecap-label">{t("ecommerce.checkout.recap.total")}</div>
          <div className="price" ref={targetRef} >{parsePrice(cart.totalPrice)}</div>
          {parseFloat(parsePrice(savingTotalPrice, true)) > 0 &&
            <div className="discount-applied">
              {t("ecommerce.checkout.saving")} {parsePrice(savingTotalPrice)}
            </div>
          }
        </div>

        {IS_GIFT_CARD_ACTIVE && hasGiftCards && isCartPage &&
          <>
            <div className="titolo text-bold">{t("ecommerce.giftcard.canAddGiftCardLater")}</div>
          </>
        }
      </div>


      {(isCartPage && !isCartChanged) && !maxGiftCardsReached &&
        <Button
          redirectPath={BRX_BASE_URL + '/checkout'}
          className="primary fullwidth"
          label={t("ecommerce.checkout.btn.proceed")} >
        </Button>
      }
      {isCartPage && isCartChanged &&
        <>
          <div className="text-center my-4 font-weight-normal"
              style={{lineHeight:'22px', color:'var(--color-alert-light)'}}>
            {t("ecommerce.checkout.check-you-cart")}
          </div>
        </>
      }
      {isCartPage && maxGiftCardsReached &&
        <>
          <div className="text-center focus">
            {maxGiftCardReachedLabel}
          </div>
          <div><br /></div>
        </>
      }
      {!isVisible &&
        <div className="d-block d-sm-block d-md-none fixed-bottom">
          <div className="fixed-bar ">
          <div className="priceRecap-total">
            <div className="priceRecap-label">{t("ecommerce.checkout.recap.total")}</div>
              <div className="price">{parsePrice(cart.totalPrice)}</div>
              {parseFloat(parsePrice(savingTotalPrice, true)) > 0 &&
                <div className="discount-applied">
                  {t("ecommerce.checkout.saving")} {parsePrice(savingTotalPrice)}
                </div>
              }
            </div>
              {(isCartPage && !isCartChanged) &&
            <Button
              redirectPath={BRX_BASE_URL + '/checkout'}
              className="primary fullwidth"
              label={t("ecommerce.checkout.btn.proceed")} >
            </Button>
          }
          {isCartPage && isCartChanged &&
            <>
              <div className="text-center focus">
                {t("ecommerce.checkout.check-you-cart")}
              </div>
              <div><br /></div>
            </>
          }
          </div>
        </div>
      }

      {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("klarna_account") !== -1 && cart.totalPrice.centAmount < 300000 && <div className="pb-5" dangerouslySetInnerHTML={{ __html: klarna_snippet }} />}
      {PAYMENT_METHODS?.includes("younited") 
        && <div className="younited-container">
            <YounitedPay
              cartid={cart.id}
              amount={cart.totalPrice.centAmount / 100}
              showPayButton={false}
              setInModal={true}
              />
          </div>
      }

      <div className="checkout-information">
        <ul>
          <li className="delivery"><span className="icon"></span><span className="label">{t("ecommerce.checkout.info.delivery")}</span></li>
          <li className="return"><span className="icon"></span><span className="label">{t("ecommerce.checkout.info.returns")}</span></li>
          <li className="securepayment"><span className="icon"></span><span className="label">{t("ecommerce.checkout.info.securepayments")}</span></li>
        </ul>
      </div>

      {isCartPage && 
        <div className="customareCare-wrap">
          <div className="titolo" dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.ccare.title") }} />
          <CustomercareInfoTelephone />
          <CustomercareInfoCompressed />
        </div>
      }
      

    </div>

  </>

};