import styled from "styled-components";

export const StyledTabs = styled.div`
.nav-item{   
    position: relative;
    font-size: 1.25rem;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--color-primary);
    &:first-of-type{
            padding-left: 0px;
    }
    &.active{
        text-decoration: underline;
    }
    &:before{
        content: '';
        display: inline-block;
        border-left: 2px solid var(--color-primary);
        position: absolute;
        width: 5px;
        height: 20px;
        left: 0;
        vertical-align: middle;
        top: 50%;
        transform: translateY(-50%);
    }
    &:first-of-type{
        &:before{
            content: '';
            display: none;
        }
    }
    @media (max-width: 768px) {
        display:block;
        width: 100%;
        padding-left: 0px;
        &:before{
            display:none;
        }
    }
}
`;