import * as React from "react";
import {GeneralContext} from "../../core/general.context";
import {StyledPromoCodeForm} from "./promoCodeForm.style";
import {LabelContext} from "../../core/label.context";
import {inputMaxLength} from "../../common/constants";
import {CartContext} from "../../core/cart.context";

/**
 * LOGIN COMPONENT
 *
 *
 * @param {string} show   = list of elements to show on login like(register)ì
 *
 *
 */

export const PromoCodeForm = (props: any) => {
    const {className} = props;
    const {t} = React.useContext(LabelContext);

    const {cart} = React.useContext(GeneralContext);
    const {addPromo, removePromo, promoError, showError, setShowError} = React.useContext(CartContext);
    const [promoValue, setPromoValue] = React.useState({promo: ""});
    const [notMatchCart, setNotMatchCart] = React.useState(true);
    const [showCoupon, setShowCoupon] = React.useState(
        cart.discountCodes.length ? true : false
    );
    const [lineItemCount, setLineItemCount] = React.useState<number>(null!)
    const setPromoValue_ = (prop: any) => {
        setPromoValue((prev) => ({
            ...prev,
            ...prop,
        }));
    };

    React.useEffect(() => {
        let val = cart.lineItems.reduce((sum, item) => sum += item.quantity, 0)
        setLineItemCount(val)

    },[cart])

    const promocodeFormInput = React.useRef<any>(null);

    React.useEffect(() => {
        if(!showError && promocodeFormInput && promocodeFormInput.current && promocodeFormInput.current.value) {
            promocodeFormInput.current.value = ""
        }
    },[showError, cart])

    return (
        <StyledPromoCodeForm className={className}>
            <div className="promoCodeForm">
                <div className="checkboxButton">
                    <input type="checkbox" checked={showCoupon} onChange={() => setShowCoupon(!showCoupon)}
                           id="promoCode"/>
                    <label htmlFor="promoCode">{t("ecommerce.checkout.coupon.intro")}</label>
                </div>

                <>
                    {showCoupon && (
                        <>
                            {Boolean(cart.discountCodes.length)}
                            <label htmlFor="promocodeForm_1">{t("ecommerce.checkout.coupon.insert")}</label>
                            <div className="promoInput">
                                <input
                                    ref={promocodeFormInput}
                                    id="promocodeForm_1"
                                    name="promocodeForm_1"
                                    type="text"
                                    onChange={(evt: { target: { value: any } }) => setPromoValue_({promo: evt.target.value})}
                                    maxLength={inputMaxLength}
                                />
                                <button className="ec_btn_secondary" onClick={() => {
                                    addPromo(promoValue.promo)
                                }}>
                                    {t("ecommerce.checkout.coupon.send")}
                                </button>
                            </div>
                            {showError && promoError !== "" &&
                                <>
                                    <div className="row">
                                        <div className="col-10">
                                            {lineItemCount !== 1 ?
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t("ecommerce.error.multiple." + promoError)
                                                    }}
                                                    className="w-100 error message--alert small"
                                                />
                                                :
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t("ecommerce.error.single." + promoError)
                                                    }}
                                                    className="w-100 error message--alert small"
                                                />
                                            }
                                        </div>
                                        <div className="col-2">
                                            <button
                                                onClick={() => {
                                                    setShowError(false)
                                                }}
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    )}
                </>
                <>
                    {Boolean(cart.discountCodes.length) && cart.discountCodes[0].state === "MatchesCart" && (
                        <label htmlFor="promocodeForm_1">{t("ecommerce.checkout.coupon.applied")}</label>

                    )}
                    {Boolean(cart.discountCodes.length) && cart.discountCodes[0].state !== "MatchesCart" && notMatchCart &&
                        <>
                            <div className="row">
                                <div className="col-10">
                                    {lineItemCount !== 1 ?
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t("ecommerce.error.multiple." + cart.discountCodes[0].state)
                                            }}
                                            className="w-100 error message--alert small"
                                        />
                                        :
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t("ecommerce.error.single." + cart.discountCodes[0].state)
                                            }}
                                            className="w-100 error message--alert small"
                                        />
                                    }
                                </div>
                                <div className="col-2">
                                    <button
                                        onClick={() => {
                                            setNotMatchCart(false)
                                        }}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                    {cart.discountCodes.map((code, i) => {
                        return (
                            <React.Fragment key={i}>

                                <div className="promoInput">
                                    <input
                                        id="promocodeForm_1"
                                        name="promocodeForm_1"
                                        type="text"
                                        value={code.discountCode.obj.code}
                                        disabled
                                        maxLength={inputMaxLength}
                                    />
                                    <button
                                        className="ec_btn_secondary"
                                        onClick={() => {
                                            removePromo(code.discountCode)
                                        }}
                                    >
                                        {t("ecommerce.checkout.coupon.remove")}
                                    </button>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </>
            </div>
        </StyledPromoCodeForm>
    )
}
