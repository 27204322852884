import * as React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { LabelContext } from "../core/label.context";
import { GeneralContext } from "../core/general.context";
import { StyledPages } from "./commonPages.style";
import { CartContext } from "../core/cart.context";
import { calls } from "../core/APIUtils";
import { RESTContext } from "../core/rest.context";
import { getAnalyticsProducts, parsePrice, redirectTo } from "../common/constants";

export const BuyBoxConfirmationPage = (_props: any) => {
    const [buyBoxCartId, setBuyBoxCartId] = useState<string>();
    const [buyBoxPayerId, setBuyBoxPayerId] = useState<string>();
    const [buyBoxToken, setBuyBoxToken] = useState<string>();
    const [buyBoxOrder, setBuyBoxOrder] = useState<any>();
    const container = document.getElementById("reactPlaceholder_buybox-confirmation");
    const { t } = React.useContext(LabelContext);
    const { userData, buyBox } = React.useContext(GeneralContext);
    const { confirmBuyBoxPayment } = React.useContext(CartContext);
    const { ajax$ } = React.useContext(RESTContext);
    const localStorage = (window as any).localStorage || []
    const dataLayer = (window as any).dataLayer || []
    const analytics: any = localStorage?.analytics ? JSON.parse(localStorage?.analytics) : {}

    useEffect(() => {
      let params: URLSearchParams = new URLSearchParams(window.location.search)
      if (params?.get("bbcid")) {
          const sid: string = params?.get("bbcid") || ""
          setBuyBoxCartId(sid);
      }
      if (params?.get("PayerID")) {
        const payerId: string = params?.get("PayerID") || ""
        setBuyBoxPayerId(payerId);
      }
      if (params?.get("token")) {
        const bbtoken: string = params?.get("token") || ""
        setBuyBoxToken(bbtoken);
      }
  }, [])

    useEffect(() => {
        if (buyBoxCartId && buyBoxToken && buyBoxPayerId) {
          confirmBuyBoxPayment(buyBoxCartId, buyBoxToken, buyBoxPayerId)
        }
    }, [buyBoxCartId, buyBoxToken, buyBoxPayerId])

    useEffect(() => {
        if (buyBox) {
            if (buyBox.code > 302){
                redirectTo('checkout?status=error');
            }
            else if (buyBox?.obj?.ctOrder) {
                setBuyBoxOrder(buyBox.obj.ctOrder);
            } else {
                if (buyBox?.obj?.cartId) {
                    ajax$(calls.Project.getMyCartById(buyBox?.obj?.cartId))
                        .subscribe(
                            res => {
                                setBuyBoxOrder(res);
                            }
                        );
                } 
            }
        }
    }, [buyBox])

    useEffect(() => {
        if (buyBoxOrder) {
            dataLayer.push({
                pageLang: `${analytics?.currentLang || ""}`,
                pageType: `${buyBoxOrder ? "purchase" : analytics?.pageType}`,
                visitorId: `${userData?.id}`,
                visitorLoginState: `${userData?.id ? "logged-in" : "not-logged"}`,
                visitorNewsletterSubscription: `${userData?.custom?.fields?.subscribed ? "yes" : "no"}`,
            })

            dataLayer.push({
                event: "checkoutOption",
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: 2,
                            option: [
                                `${buyBoxOrder?.shippingInfo?.shippingMethodName} - ${buyBoxOrder?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.paymentInterface}`,
                            ],
                        },
                    },
                },
            })

            const { lineItems } = buyBoxOrder

            dataLayer.push({
                pageType: "purchase",
                visitorId: `${userData?.id}`,
            })
            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    currencyCode: `${buyBoxOrder?.totalPrice?.currencyCode}`,
                    purchase: {
                        actionField: {
                            id: `${buyBoxOrder?.orderNumber}`,
                            affiliation: 'Online store',
                            revenue: `${parsePrice(buyBoxOrder?.totalPrice, true)}`,
                            tax: `${parsePrice(buyBoxOrder?.taxedPrice?.taxPortions[0]?.amount, true)}`,
                            shipping: `${buyBoxOrder?.shippingInfo?.price?.centAmount}`,
                            coupon: `${buyBoxOrder?.discountCodes?.map((item: any) => { return item?.discountCode?.obj?.code }).toString()}`,
                        },
                        products: getAnalyticsProducts(lineItems),
                    }
                }
            });
        } else {
            dataLayer.push({
                'pageLang': `${analytics?.currentLang}`,
                'pageType': `${analytics?.pageType ? analytics?.pageType : (analytics?.sitemap === 'root') ? 'homepage' : 'other'}`,
                'visitorId': `${userData?.id}`,
                'visitorLoginState': `${userData?.id ? 'logged-in' : 'not-logged'}`,
                'visitorNewsletterSubscription': `${userData?.custom?.fields?.subscribed ? 'yes' : 'no'}`,
            });
        }
    }, [buyBoxOrder])

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
            {!buyBoxOrder && <div className="isLoading" />}
            {buyBoxOrder && <ThankYouMessage currentOrder={buyBoxOrder} />}
            </>
            , container
        )
};

const ThankYouMessage = (props: any) => {
    const { t } = React.useContext(LabelContext)
    let order = props.currentOrder
    let messageOrderNumberConfirmed: string = ""
  
    if (order.type === "Order") {
      messageOrderNumberConfirmed = t("ecommerce.checkout.order-confirmed-message-with-order-number")
      messageOrderNumberConfirmed = messageOrderNumberConfirmed.replace(
        "[ORDER_NUMBER]",
        "<b>" + order.orderNumber + "</b>"
      )
    }
  
    return (
      <StyledPages>
        <HeaderTitle></HeaderTitle>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              {order.type === "Order" && <h1>{t("ecommerce.checkout.order-confirmed-page-title")}</h1>}
              {order.type === "Cart" && <h1>{t("ecommerce.checkout.temporary-order-confirmed-page-title")}</h1>}
              <br />
              {order.type === "Order" && <p dangerouslySetInnerHTML={{ __html: messageOrderNumberConfirmed }}></p>}
              {order.type === "Order" && <p>{t("ecommerce.checkout.order-confirmed-title-shipping")}</p>}
              {order.type === "Cart" && <p>{t("ecommerce.checkout.temporary-order-confirmed-title-shipping")}</p>}
              
                <p>
                    <b>
                    {order.shippingAddress.firstName} {order.shippingAddress.lastName}, {order.shippingAddress.streetName}{" "}
                    {order.shippingAddress.streetNumber}, {order.shippingAddress.postalCode}, {order.shippingAddress.city}{" "}
                    ({order.shippingAddress.region})
                    </b>
                </p>
  
              {order.type === "Order" && (
                <>
                  <p dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.order-confirmed-message-in-page-1") }}></p>
                  <p dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.order-confirmed-message-in-page-2") }}></p>
                </>
              )}
              {order.type === "Cart" && (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("ecommerce.checkout.temporary-order-confirmed-message-in-page-1"),
                    }}
                  ></p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("ecommerce.checkout.temporary-order-confirmed-message-in-page-2"),
                    }}
                  ></p>
                </>
              )}
            </div>
          </div>
        </div>
      </StyledPages>
    )
  }