import * as React from "react"
import { Box } from "../box/box.component"
import { Button } from "../button/button.component"
import { StyledGuest } from "./loginAsGuest.style"
import { InputElement } from "../input/input.component"
import { LabelContext } from "../../core/label.context"
import * as yup from "yup"
import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

export const LoginAsGuest = (props: any) => {
  const { className, onClick } = props
  //const [ email, setEmail ] = React.useState(null);
  const { t } = React.useContext(LabelContext)
  /*
  const onChange = (e:any) => {
   setEmail(e.currentTarget.value);
  }*/

  let requiredError = t("ecommerce.form-messagge.required")

  const schema = yup.object().shape({
    email: yup.string().email().required(requiredError).trim(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<any> = (data) => {
    //login(data.email, data.password, null, () => {window.location.href = `${BRX_BASE_URL}/my-account`;});

    onClick(data.email)
  }

  return (
    <StyledGuest className={`cmp_loginAsGuest ${className}`}>
      <Box className="boxed-mobile fixed-height secondary unbordered">
        <div className="text-center">
          <h4>{t("ecommerce.checkout.signup-guest-title")}</h4>
          <p>{t("ecommerce.checkout.signup-guest-description")}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputElement
              externalRegister={register("email")}
              label={t("ecommerce.signup.email")}
              placeholder={t("ecommerce.common.email")}
              id="emailGuest"
              errors={errors.email?.message}
            ></InputElement>
            <Button
              className="primary mt-4"
              label={t("ecommerce.checkout.signup-guest-proced")}
              type="submit"
              value={t("ecommerce.checkout.guest-title")}
            ></Button>
          </form>
        </div>
      </Box>
    </StyledGuest>
  )
}
