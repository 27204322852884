/**
 * Payment Methods ICONS
 *
 *
 */

import React, {useEffect} from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import {
    BRX_ASSETS_URL,
    FOOTER_PARTNERSHIP_LOGOS,
    FOOTER_PAYMENT_LOGOS,
    PROJECT_KEY
} from "../../core/APIUtils"
import { LabelContext } from "../../core/label.context"

export const PaymentMethodsIcons = (props: any) => {
    const { t } = React.useContext(LabelContext)

    useEffect(() => {
        if(["smeg-sweden", "smeg-sweden-staging", "smeg-sweden-staging2"].includes(PROJECT_KEY)){
            const script = document.createElement('script');
            script.src = "https://cert.tryggehandel.net/js/script.js?id=921a2e68-ad51-49cd-91a8-1c0547e6306d";
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }
    }, []);

    return (
        <>
            <PaymentStyle>

                {FOOTER_PAYMENT_LOGOS.length > 0 && (
                    <>
                        <div className="footerPartnership payments">
                            <span className="titolo">{t("ecommerce.common.footer.paymentMethods")}</span>
                            <div className="icons">
                                {FOOTER_PAYMENT_LOGOS.map((logo: string) => (
                                    <img className={"payment-icon"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())} />
                                ))}
                            </div>
                        </div>
                    </>
                )}

                {FOOTER_PARTNERSHIP_LOGOS.length > 0 && (
                    <>
                        <div className="footerPartnership partners">
                            <div className="titolo">{t("ecommerce.common.footer.partnership")}</div>
                            <div className="icons">
                                {FOOTER_PARTNERSHIP_LOGOS.map((logo: string) => (
                                    <img className={"payment-icon"} src={`${BRX_ASSETS_URL}/images/icons/partnership/${logo}.svg`} alt={logo.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())} />
                                ))}
                            </div>
                        </div>
                    </>
                )}

                {["smeg-sweden", "smeg-sweden-staging", "smeg-sweden-staging2"].includes(PROJECT_KEY) && (
                    <div className="footerPartnership certifications">
                        <div className="titolo">{t("ecommerce.common.footer.certifications")}</div>
                        <div className="icons">
                            <div className="teh-certificate" data-size="64"></div>
                        </div>
                    </div>
                )}

            </PaymentStyle>
        </>
    )
}

export const PaymentMethodsIconsLayer = () => {
    const { t } = React.useContext(LabelContext)
    const container = document.getElementById("reactPlaceholder_paymentMethods")

    if (!container) return null
    else {
        return ReactDOM.createPortal(
            <>
                <PaymentMethodsIcons />
            </>,
            container
        )
    }
}

export const PaymentStyle = styled.div`
  max-width: 100%;
  .footerPartnership{
    align-items: baseline;
    @media (min-width: 1200px) {
      align-items: baseline;
    }
  }
  .payments,
  .partners,
  .certifications {
    display: flex;
    flex-direction: column;
    justify-content: start;
    .titolo {
      flex:1
    }
    .icons {
      display: flex;
      justify-content: flex-start;
      flex-wrap:wrap;
      flex:2 1 0%;
      width:100%;
      height:100%;
      .paymentMethodsIcons{
        justify-content: flex-start;
        li{
          width: 4rem;
          .icon{
            width:80%;
          }
        }
      }
      img {
        width: 4rem;
        rect {
          fill: red;
        }
      }
    }
  }
`
