import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  #cart .checkout-column-aside > .customerCareInfo-box-tel{
    display:none;
    @media (min-width: 768px) {
      display:flex;
    }
  }
  .checkout-column-aside{
    .footerPartnership.payments{
      flex-direction: column;
      justify-content: center;
      .titolo {
        text-align: center;
        width: 100%;
        font-weight: 700;
      }
    }
    .paymentMethodsIcons{
      justify-content: center!important;
      .icon{
        width:80%;
      }
    }
  }
  #reactPlaceholder_paymentMethods{
    max-width:100%;
  }
  [data-country="FR"] {
    button.icon-cards-visa-mastercard{
      &:after {
        background-image: url(../webfiles/latest/images/icons/paymentMethods/visa-mastercard-cb-amex.svg);
      }
    }
  }
  [data-country="IT"] {
    button.icon-cards-visa-mastercard{
      &:after {
        background-image: url(../webfiles/latest/images/icons/paymentMethods/visa-mastercard-amex.svg);
        background-position: 60% center;
      }
    }
  }
  .cursor-default {
    cursor: default!important;
  }
`;