import React from "react";
import ReactDOM from "react-dom";
import {ILineItem} from "../../common/cart.models";
import {calls, PROJECT_LANGUAGE} from "../../core/APIUtils";
import {RESTContext} from "../../core/rest.context";
import {SliderItems} from "../sliders/slideritems.component";


export const RelatedItemsLayer = () => {
    const container = document.getElementById("reactPlaceholder_relatedProducts");
    const [items, setItems] = React.useState<unknown>([]);
    const {ajax$} = React.useContext(RESTContext);

    React.useEffect(() => {
        if (container) {
            ajax$(calls.Project.getProduct(container?.getAttribute('data-id') || ""))
                .subscribe((res: ILineItem) => {
                    const auto_vent = res.masterVariant?.attributes.find(attr => attr.name === "AUTO_VENT_2_0")?.value || null;
                    if (auto_vent != null) {
                        const sku = res.masterVariant?.sku
                        ajax$(calls.Project.queryProductProjection('masterVariant(attributes(name%3D"AUTO_VENT_2_0")) and masterVariant(sku!%3D"' + sku + '")'))
                            .subscribe((res: any) => {
                                setItems(res?.results)
                            })
                    } else {
                        const ri = res.masterVariant?.attributes.find(attr => attr.name === "related-products")?.value || [];
                        //rimuovo elementi non piu referenziati. (manca il campo obj)
                        const filteredRi = ri.filter(item => 'obj' in item)
                        setItems(filteredRi);
                    }
                })
        }
    }, [container]);

    if (!container){
        return null;
    } else {
        return ReactDOM.createPortal(
            <SliderItems items={items} type={"related"} alignTitle={"center"}/>,
            container
        )
    }

};

export const SliderListFromCategoryCT = () => {
    const container = document.getElementsByClassName("reactPlaceholder_slider");
    return <>
        {Array.from(container).map((slider, i) =>
            <SliderFromCategoryCT container={slider} key={i}/>
        )}
    </>
};

export const SliderFromCategoryCT = (props: any) => {
    const container = props.container;
    const [relatedItems, setRelatedItems] = React.useState<any>([]);
    const [categoryName, setCategoryName] = React.useState<string>();
    const [numberOfSlidesToShow, setNumberOfSlidesToShow] = React.useState<string>();
    const {ajax$} = React.useContext(RESTContext);

    React.useEffect(() => {
        if (container) {
            let title = container.getAttribute('data-title') || undefined
            let categoriesKeyField = container.getAttribute('data-categories-key') || ""
            let numberOfSlidesToShow = container.getAttribute('data-number-of-slides') || ""
            let orderField = container.getAttribute('data-order-field') || "price"
            let orderType = container.getAttribute('data-order-type') || "ASC"
            let categoriesKey: any[] = []
            if (categoriesKeyField) {
                categoriesKey = categoriesKeyField.split(',')
            }

            if (categoriesKey.length > 0) {
                let products: any [] = []
                categoriesKey.forEach((key, i) => {
                    ajax$(calls.Project.getCategoryByKey(key), undefined, {}, false ).subscribe(
                        category => {
                            if (!title && i === 0) {
                                title = category.name[PROJECT_LANGUAGE]
                            }

                            let filterQuery = 'categories.id:"' + category.id + '"&expand=productType&expand=masterVariant.attributes[*].value[*]'
                            switch (orderField){
                                case "default":
                                    filterQuery += '&sort=price%20' + orderType
                                    break;
                                case "price":
                                    filterQuery += '&sort=price%20' + orderType
                                    break;
                                case "availability":
                                    filterQuery += '&sort=variants.availability.availableQuantity%20' + orderType
                                    break;
                                default:
                                    filterQuery += '&sort=price%20' + orderType
                                    break;
                            }


                            ajax$(calls.Project.getProductProjection(filterQuery), undefined, {}, false )
                                .subscribe(
                                    res => {
                                        products = [...products, ...res['results']]
                                    })
                        }
                    )
                })

                //rimuove i duplicati
                products = products.filter((obj: any, i) => products.findIndex((other: any) => Object.keys(obj).every((key) => obj["key"] === other["key"])) === i)

                setCategoryName(title)
                setRelatedItems(products)
                setNumberOfSlidesToShow(numberOfSlidesToShow)
            }
        }
    }, [container]);


    if (!container || !categoryName || !relatedItems)
        return null;
    else {
        return ReactDOM.createPortal(
            <SliderItems items={relatedItems} title={categoryName} type={"related"} numberOfSlidesToShow={numberOfSlidesToShow}/>,
            container
        )
    }

};
