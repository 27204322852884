
/**
 * CUSTOMER CARE INFO BOXES
 *
 *
 * @param {string} display - by default the boxes are shown in column
 * @param {string} display=row - add cssclass layout-row
 * 
 * 
 */

import * as React from "react";
import { LabelContext } from "../../core/label.context";
import { CustomercareInfoTelephone } from "./customercareInfoTelephone.component";


export const CustomercareInfoBoxes = (props: any) => {
    const { t } = React.useContext(LabelContext);
    let layoutType = props.display ? ("layout-" + props.display) : "";
    let componentClassName = "customerCareInfo " + layoutType;

    let ecommerce_customerCare_info_faq = t("ecommerce.customerCare.info.faq");
    let ecommerce_customerCare_info_telephone = t("ecommerce.customerCare.info.telephone");
    let ecommerce_customerCare_info_mail = t("ecommerce.customerCare.info.mail");

    if (!ecommerce_customerCare_info_faq && !ecommerce_customerCare_info_telephone && !ecommerce_customerCare_info_mail)
        return null;
    else {
        return <>
            <div className={componentClassName}>

                <h2 className="text-center">{t("ecommerce.customerCare.info.title")}</h2>
                <div className="wrapper">
                    {ecommerce_customerCare_info_faq &&
                        <div className="customerCareInfo-box-faq">
                            <span className="icon"></span>
                            <div className="testo" dangerouslySetInnerHTML={{ __html: ecommerce_customerCare_info_faq }} />
                        </div>
                    }

                    <CustomercareInfoTelephone />

                    {ecommerce_customerCare_info_mail &&
                        <div className="customerCareInfo-box-mail">
                            <span className="icon"></span>
                            <div className="testo" dangerouslySetInnerHTML={{ __html: ecommerce_customerCare_info_mail }} />
                        </div>
                    }

                </div>
            </div>
        </>
    }


};

