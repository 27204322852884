import * as React from "react"
import {EAddressType, EShippingType} from "../../common/common.models"
import {
  ADYEN_CLIENT_KEY,
  ADYEN_ENVIRONMENT,
  ADYEN_LOCALE,
  ADYEN_PAYMENTMETHODS,
  ADYEN_PAYMENTMETHODSCONFIGURATION,
  ADYEN_ENABLEDPAYMENTMETHODS,
  calls,
  PROJECT_COUNTRY,
  PROJECT_CURRENCY,
  PROJECT_LANGUAGE,
  PROJECT_SETTINGS,
  BRX_LOCALE,
  PAYMENT_METHODS,
  PAYPAL_CLIENT_ID,
  IS_GIFT_CARD_ACTIVE,
  PAYFAST_CANCEL_URL,
  PAYFAST_NOTIFY_URL,
  PAYFAST_RETURN_URL,
  PAYFAST_MERCHANT_ID,
  PAYFAST_MERCHANT_KEY,
  PAYFAST_BASE_URL, 
  PROJECT_KEY,
  COOKIES,
} from "../../core/APIUtils"
import {GeneralContext} from "../../core/general.context"
import {LabelContext} from "../../core/label.context"
import {ESelectedAddress, EUserDataAddressesIdentifier, IAddressList} from "./checkout.models"
import {Box} from "../box/box.component"
import {Button} from "../button/button.component"
import {AddAddress} from "../elements/address/address.component"
import {CartRecap} from "../cart/cart.recap.component"

import AdyenCheckout from "@adyen/adyen-web"
import "@adyen/adyen-web/dist/adyen.css"
import { CartContext } from "../../core/cart.context"
import { AWSlogger, RESTContext } from "../../core/rest.context"
import { getBrowserInfo, getCartInfo, parsePrice, redirectTo } from "../../common/constants"
import { ILineItem, ITotalPrice } from "../../common/cart.models"
import { StyledCheckout } from "./checkout.style"
import { YounitedPay } from "../younitedPay/younitedPay.component"
import { loadScript } from "@paypal/paypal-js"
import {Property} from "../elements/property/property.component"
import {InputElement} from "../input/input.component";
import {SubmitHandler, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup"
import SelectMultiOptions from "../elements/select/SelectMultiOptions"

export const Checkout = (props: any) => {
    const {redirectResult} = props

  const { ajax$ } = React.useContext(RESTContext)
  const { cart, shippingMethods, userData, payU, younitedUri, giftCards, setCurrentOrder, setSelectedShippingMethodDescription, buyBox, payFastSignature } = React.useContext(GeneralContext)
  const {
    checkIsCartChange,
    isCartChanged,
    createPayment,
    setNoteOnCart,
    setShippingMethod,
    resetCartForCheckout,
    additionalsDetailsPayment,
    setConsentThrdParty,
    processingOrder,
    setNlConsent,
    startPayU,
    initYounitedContract,
    createOrderWithoutPayment,
    createPayPalOrder,
    capturePayPalOrder,
    populateGiftCardRecipientInfo,
    initBuyBoxPayment,
    differenceBy,
    setApplyGiftCardToCart,
    setRemoveGiftCardFromCart,
    selectedGiftcards,
    setSelectedGiftcards,
    setRemoveAllGiftCardFromCart,
    loadGiftCards,
    getSelectedShippingMethod,
    generatePayFastSignature,
  } = React.useContext(CartContext)
  const { t } = React.useContext(LabelContext)
  const [shippingType, setShippingType] = React.useState(EShippingType.Home);

  const [tempAddresses, setTempAddresses] = React.useState(null!)
  const [privacy, setPrivacy] = React.useState<any>(null!)
  const [consent, setConsent] = React.useState(null!)
  const [externalWarrantyCheck, setEternalWarrantyCheck] = React.useState(false)
  const [nlConsentValue, setNlConsentValue] = React.useState(false)

  const [commercial, setCommercial] = React.useState(null!)
  const [activeStep, setActiveStep] = React.useState(0)
  const [focusPrivacy, setFocusPrivacy] = React.useState(false)
  const [consent3Party, setConsent3Party] = React.useState(false)
  const [clickedPay, setClickedPay] = React.useState(false)
  const [clickedPayWithPaypal, setClickedPayWithPaypal] = React.useState(false)
  const [paypalError, setPaypalError] = React.useState("")
  const [applepayEnabled, setApplepayDisabled] = React.useState(false)
  const [lineItemWithNoAvailability, setLineItemWithNoAvailability] = React.useState(false)

  const [sepaComponent, setSepaComponent] = React.useState<any>(null!)
  const [adyenComponent, setAdyenComponent] = React.useState<any>(null!)
  const [paypalComponent, setPaypalComponent] = React.useState<any>(null!)
  const [klarnaComponent, setKlarnaComponent] = React.useState<any>(null!)
  const [bancontactComponent, setBancontactComponent] = React.useState<any>(null!)
  const [applepayComponent, setApplepayComponent] = React.useState<any>(null!)
  const [idealComponent, setIdealComponent] = React.useState<any>(null!)
  const [showPaypal, setShowPaypal] = React.useState<boolean>(false)
  const [loadingPaypal, setLoadingPaypal] = React.useState<boolean>(false)

  //GIFTCARD STATES
  const [enabledGiftCardEditButton, setEnabledGiftCardEditButton] = React.useState(false)
  const [giftcardInsideCard, setGiftcardInsideCart] = React.useState(false)
  const [hasGiftCards, setHasGiftCards] = React.useState(false);
  const [showGiftCardSelect, setShowGiftCardSelect] = React.useState(false);

  let requiredError = t("ecommerce.form-messagge.required");
  let invalidEmail = t("ecommerce.form-messagge.email-invalid")
  let confirmEmailNotMatchError = t("ecommerce.form-messagge.email-must-match")
  const giftCardItemSchema = yup.object().shape({
    value: yup.object().shape({
      name: yup.string().required(requiredError),
      surname: yup.string().required(requiredError),
      email: yup.string().email(invalidEmail).required(requiredError),
      confirmEmail: yup.string().email(invalidEmail).required(requiredError).test(
          'email-match',
          confirmEmailNotMatchError,
          function(value) {
            const { email } = this.parent;
            return email === value;
          }
      ),
    })
  });

  const giftCardSchema = yup.object().shape({
    "gift-card": yup.lazy(objectShape => {
      const obj: any = {};
      Object.keys(objectShape).forEach(key => {
        obj[key] = yup.array().of(giftCardItemSchema);
      });
      return yup.object().shape(obj);
    })
  });

  //to debuggin form console.log(watch())
  const {register, formState: {errors}, handleSubmit, reset, watch } = useForm<any>({
    resolver: yupResolver(giftCardSchema),
    mode: "onSubmit",
    defaultValues: {
      "gift-card": {}
    }
  });


  const isPickUpAvailable = shippingMethods?.find((store: any) => store.custom?.fields["shipping-type-list"] === EShippingType.Pickup)
  const selectedShippingMethod = getSelectedShippingMethod(cart, shippingMethods)
  if(selectedShippingMethod){
    if(selectedShippingMethod && selectedShippingMethod?.localizedDescription?.[PROJECT_LANGUAGE]){
      setSelectedShippingMethodDescription(selectedShippingMethod?.localizedDescription?.[PROJECT_LANGUAGE])
    }
  }

  const hasExternalWarrantyService = cart?.lineItems.filter((item: ILineItem) => item.productType.obj?.key === "services" && item.productKey === "extra-warranty").length > 0

  const unmountAdyenComponents = () => {
    if (showPaypal) setShowPaypal(false)
    if (paypalComponent) paypalComponent.unmount()
    if (klarnaComponent) klarnaComponent.unmount()
    if (adyenComponent) adyenComponent.unmount()
    if (sepaComponent) sepaComponent.unmount()
    if (bancontactComponent) bancontactComponent.unmount()
    if (idealComponent) idealComponent.unmount()
    if (applepayComponent) applepayComponent.unmount()
    const paymentsDiv = document.getElementById("payments-row")
    let bounding = paymentsDiv?.getBoundingClientRect()
    if (bounding) {
        let y = bounding?.top + window.pageYOffset - 400
        window.scrollTo({top: y, behavior: "smooth"})
    }
    
  }

  const componentElement = React.useRef(null)

  let options: any[] = []
  if (giftCards) {
    giftCards.forEach((giftCard: any, i: number) => {
      options.push({ value: giftCard?.serialNumber.toString(), label: giftCard?.giftCardName, residual: giftCard?.residualValue })
    })
  }

  React.useEffect(() => {
    const selectedShippingMethod = getSelectedShippingMethod(cart, shippingMethods);
    if (selectedShippingMethod?.custom?.fields?.['shipping-type-list'] === 'pickup') {
      setShippingType(EShippingType.Pickup)
    }
  },[shippingMethods])

  React.useEffect(() => {
    const data: any = { "gift-card": {}}
    checkIsCartChange(cart)

    //carica le giftcard
    loadGiftCards(true);

    //controlla se ci sono giftcard aggiunte a carrello
    const hasActiveGiftCardsInCart = cart.customLineItems.length > 0
    //mostra la select se ci sono giftcard attive
    if(hasActiveGiftCardsInCart){
      setShowGiftCardSelect(true)
    }

    const selectedGiftCard: any = [];
    cart.customLineItems.map((item: any) => {
      selectedGiftCard.push({ value: item.slug.split('gift-card-')[1], label: item.name[Object.keys(item.name)[0]], residual: Math.abs(item.money.centAmount) })
    });
    setSelectedGiftcards(selectedGiftCard)

    const selectedGiftCards = cart.customLineItems
        .filter((item: any) => item.slug && item.slug.includes('gift-card-')) // Filtra solo le customLineItems di tipo giftcard
        .map((item: any) => ({
          value: item.slug.split('gift-card-')[1],
          label: item.name[Object.keys(item.name)[0]],
          residual: Math.abs(item.money.centAmount)
        }));
    setSelectedGiftcards(selectedGiftCards);

    if (["DE", "PL"].includes(PROJECT_COUNTRY)) {
      setPrivacy(true)
    }

    cart.lineItems.map((item: any, i) => {
      const discounts = item && item["discountedPrice"] && item["discountedPrice"]["includedDiscounts"] && item["discountedPrice"]["includedDiscounts"].length > 0
      if (discounts && PROJECT_SETTINGS.externalPromo) {
        item["discountedPrice"]["includedDiscounts"].map((_item: any) => {
          if (_item["discount"]["id"] === PROJECT_SETTINGS.externalPromo)
            setConsent3Party(true)
        })
      }

      if (item?.productType?.obj?.key === "GIFTCARD") {
        setGiftcardInsideCart(true)

        if (item?.productType?.obj?.key === "GIFTCARD") {
          let dataRecipient: any;
          if (item?.custom?.fields['recipients-giftcard']) {
            try {
              dataRecipient = JSON.parse(item?.custom?.fields['recipients-giftcard']);
              data["gift-card"][item.id] = dataRecipient
            } catch (e) {
              dataRecipient = [];
            }
          }
        }
      }
    })
    reset(data)

    if (["PL"].includes(PROJECT_COUNTRY)) {
      cart.lineItems.map((item: ILineItem) => {
        if (item.variant.availability.availableQuantity <= 0) {
          setLineItemWithNoAvailability(true)
        }
      })
    }
  }, [])

  React.useEffect(() => {
      checkPrivacyFlagged()
  }, [privacy, commercial, clickedPay, externalWarrantyCheck])

  React.useEffect(() => {
    if (cart.customLineItems.length <= 0) {
      setSelectedGiftcards([])
    }

    if(giftCards != null && giftCards.length > 0){
      setHasGiftCards(true)
    }

    const allGiftCardRecipientsFilled = cart.lineItems.every((giftCard) => {
      if (!giftCard?.productType?.obj?.key || giftCard?.productType?.obj?.key !== "GIFTCARD") {
        return true;
      }

      const { quantity } = giftCard;

      if (giftCard?.custom?.fields['recipients-giftcard']) {
        let dataRecipient: any;
        try {
          dataRecipient = JSON.parse(giftCard?.custom?.fields['recipients-giftcard']);
        } catch (e) {
          return false;
        }

        return [...Array(quantity)].every((_, i) => {
          return dataRecipient[i]?.value.name &&
              dataRecipient[i]?.value.surname &&
              dataRecipient[i]?.value.email;
        });
      }
    });
    setEnabledGiftCardEditButton(allGiftCardRecipientsFilled)
  }, [cart, giftCards])

  React.useEffect(() => {
    if (payU && payU.obj?.redirectUri) {
      redirectTo(payU.obj.redirectUri)
    }
  }, [payU])

  React.useEffect(() => {
    if (younitedUri && younitedUri?.redirectUri) {
      redirectTo(younitedUri?.redirectUri)
    }
  }, [younitedUri])

  React.useEffect(() => {
    if (redirectResult) {
      let details = {
        details: {
          redirectResult: redirectResult,
        },
      }
      additionalsDetailsPayment(details, adyenComponent)
    }
  }, [redirectResult])

  React.useEffect(() => {
    if (componentElement) {
      window.scrollTo({top: 0, behavior: "smooth"})
    }
  }, [componentElement])

  React.useEffect(() => {
    let tmpActiveCart = 0
    if (cart.shippingAddress?.custom?.fields.internalType !== "placeholder") {
      tmpActiveCart = 1
    }

    if (
        shippingType === EShippingType.Home &&
        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
        cart.billingAddress
    ) {
      tmpActiveCart = 2

      if (shippingMethods) {
        let sMethods = shippingMethods?.filter((shippingMethod: any) => shippingMethod.custom?.fields["shipping-type-list"] !== EShippingType.Pickup)
        if (sMethods.length === 1) {
          if (!cart.shippingInfo || cart.shippingInfo?.shippingMethod?.id !== sMethods[0].id) {
            setShippingMethod(sMethods[0].id)
          }
        }
      }

      if(!cart.shippingInfo && shippingMethods){
        let giftCardShippingMethod = shippingMethods?.filter((shippingMethod: any) => shippingMethod.key === "giftcard")
        if(giftCardShippingMethod.length > 0){
          setShippingMethod(giftCardShippingMethod[0].id)
        }
      }
    }

    if (
        !userData &&
        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
        cart.billingAddress &&
        cart.shippingInfo
    ) {
      tmpActiveCart = 3
    }

    if (
        userData &&
        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
        cart.billingAddress &&
        cart.shippingInfo
    ) {
      tmpActiveCart = 4
    }

    if (
        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
        commercial &&
        (privacy || userData) &&
        cart.billingAddress &&
        cart.shippingInfo
    ) {
      tmpActiveCart = 5
    }
    setActiveStep(tmpActiveCart)
  }, [cart, shippingType, userData, commercial, privacy])

  React.useEffect(() => {
    if(payFastSignature !== ""){
      const cartTotal = (cart.totalPrice.centAmount / Math.pow(10, cart.totalPrice.fractionDigits)).toFixed(2);

      const form = document.createElement('form');
      form.method = 'post';
      form.action = PAYFAST_BASE_URL;
      form.style.display = 'none';

      const addField = (name: string, value: any) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        form.appendChild(input);
      };

      const returnUrl = `${PAYFAST_RETURN_URL}?pfcid=${cart.id}`
      addField('merchant_id', PAYFAST_MERCHANT_ID);
      addField('merchant_key', PAYFAST_MERCHANT_KEY);
      addField('return_url', returnUrl);
      addField('cancel_url', PAYFAST_CANCEL_URL);
      addField('notify_url', PAYFAST_NOTIFY_URL);
      addField('amount', cartTotal);
      addField('item_name', cart.id);
      addField('custom_str1', PROJECT_KEY)
      addField('signature', payFastSignature);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
      setClickedPay(false)
    }
  },[payFastSignature])

  let checkout: any

  const onAdditionalDetails = (state: any, component: any) => {
      additionalsDetailsPayment(state.data, component)
  }

  const handleOnChange = () => {}

  const handleSelectChange = (values: any) => {
    const diffValue = differenceBy(values, selectedGiftcards)
    const wasThereBefore = selectedGiftcards.some((x: any) => x?.value === diffValue?.value)
    //capisco se sto rimuovendo o aggiungeno una giftcard a carrello
    if (wasThereBefore) {
      const removeGiftcard = cart.customLineItems.find((item: any) => (item.slug.split('gift-card-')[1] === diffValue?.value));
      setRemoveGiftCardFromCart(removeGiftcard)
    } else {
      setApplyGiftCardToCart(diffValue)
    }
    //smonto i componenti di adyen e younited se seleziono una giftcard
    unmountAdyenComponents()
    const younitedComponent = document.getElementById("younited-close");
    if(younitedComponent){
      younitedComponent.click();
    }
  };

  const checkPrivacyFlagged = () => {
      if(hasExternalWarrantyService){
        setFocusPrivacy(clickedPay && !(commercial && (privacy || userData) && externalWarrantyCheck))
        return commercial && (privacy || userData) && externalWarrantyCheck
      }else {
        setFocusPrivacy(clickedPay && !(commercial && (privacy || userData)))
        return commercial && (privacy || userData)
      }
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    populateGiftCardRecipientInfo(data)
  };

  const payWithBankTransfer = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    createOrderWithoutPayment("bankTransfer")
  }

  const payLaterWithPayByLink = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    createOrderWithoutPayment("payLater")
  }

  const closeOrderWithGiftCard = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    createOrderWithoutPayment("giftcard")
  }

  const loadIdealForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
      AWSlogger({
        type: "log",
        action: "clickPaymentButton",
        cartId: active_cart.id,
        payment: "ideal",
        browserInfo: getBrowserInfo(),
        cart: getCartInfo(active_cart)
      })

      const idealConfiguration = {
        locale: ADYEN_LOCALE,
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
        countryCode: PROJECT_COUNTRY,
        paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
        onSubmit: (state: any, component: any) => {
          createPayment(state, component, checkout, false, "ideal")
        },
        onAdditionalDetails: onAdditionalDetails,
        showPayButton: true,
      };

      checkout = await AdyenCheckout(idealConfiguration)
      unmountAdyenComponents()
      setIdealComponent(checkout.create('ideal', idealConfiguration).mount('#ideal-container'))
      setApplepayDisabled(false)
      const younitedComponent = document.getElementById("younited-close");
      if (younitedComponent) {
        younitedComponent.click();
      }
    })
  }

  const loadBancontactForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
      AWSlogger({
        type: "log",
        action: "clickPaymentButton",
        cartId: active_cart.id,
        payment: "bancontact",
        browserInfo: getBrowserInfo(),
        cart: getCartInfo(active_cart)
      })

      const bancontactConfiguration = {
        locale: ADYEN_LOCALE,
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: false, // Set to true to show the billing address input fields.
        enableStoreDetails: true,
        brands: ['bcmc','mc','visa'],
        showPayButton: true,
        autoFocus: true,
        setStatusAutomatically: true,
        onSubmit: (state: any, component: any) => {
          createPayment(state, component, checkout, false, "bancontact")
        },
        onAdditionalDetails: onAdditionalDetails,
        onChange: handleOnChange,
      };

      checkout = await AdyenCheckout(bancontactConfiguration)
      unmountAdyenComponents()
      setBancontactComponent(checkout.create('bcmc', bancontactConfiguration).mount('#bcmc-container'))
      setApplepayDisabled(false)
      const younitedComponent = document.getElementById("younited-close");
      if (younitedComponent) {
        younitedComponent.click();
      }
    })
  }

  const loadApplePayForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
      AWSlogger({
        type: "log",
        action: "clickPaymentButton",
        cartId: active_cart.id,
        payment: "applepay",
        browserInfo: getBrowserInfo(),
        cart: getCartInfo(active_cart)
      })

      const applepay_configuration = {
        locale: ADYEN_LOCALE,
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
        countryCode: PROJECT_COUNTRY,
        paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
        paymentMethodsConfiguration: ADYEN_PAYMENTMETHODSCONFIGURATION,
        onAdditionalDetails: onAdditionalDetails,
        onSubmit: (state: any, component: any) => {
          createPayment(state, component, checkout, false, "applepay")
        },
      }
      checkout = await AdyenCheckout(applepay_configuration)
      let comp = checkout.create('applepay')
      comp.isAvailable()
        .then(() => {
          unmountAdyenComponents()
          setApplepayComponent(comp.mount("#applepay-container"));
          setApplepayDisabled(false)
        })
        .catch((e: any) => {
          setApplepayDisabled(true)
        });

      const younitedComponent = document.getElementById("younited-close");
      if (younitedComponent) {
        younitedComponent.click();
      }
    })
  }

  const loadSepaForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
      AWSlogger({
        type: "log",
        action: "clickPaymentButton",
        cartId: active_cart.id,
        payment: "sepa",
        browserInfo: getBrowserInfo(),
        cart: getCartInfo(active_cart)
      })

      const adyen_configuration = {
        locale: ADYEN_LOCALE,
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
        paymentMethodsConfiguration: ADYEN_PAYMENTMETHODSCONFIGURATION,
        onAdditionalDetails: onAdditionalDetails,
        onSubmit: (state: any, component: any) => {
          createPayment(state, component, checkout, false, "sepa")
        },
        showPayButton: true,
        setStatusAutomatically: true,
        onChange: handleOnChange,
        amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
      }
      checkout = await AdyenCheckout(adyen_configuration)
      unmountAdyenComponents()
      setSepaComponent(checkout.create("sepadirectdebit").mount("#sepadirectdebit"))
      setApplepayDisabled(false)
      const younitedComponent = document.getElementById("younited-close");
      if (younitedComponent) {
        younitedComponent.click();
      }
    })
  }

  const loadAdyenForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
      AWSlogger({
        type: "log",
        action: "clickPaymentButton",
        cartId: active_cart.id,
        payment: "adyen",
        browserInfo: getBrowserInfo(),
        cart: getCartInfo(active_cart)
      })

      const translations = {
        "de_DE": {
          "payButton": "Jetzt kostenpflichtig bestellen für",
        }
      };

      const adyen_configuration = {
        locale: ADYEN_LOCALE,
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
        paymentMethodsConfiguration: ADYEN_PAYMENTMETHODSCONFIGURATION,
        onAdditionalDetails: onAdditionalDetails,
        onSubmit: (state: any, component: any) => {
          createPayment(state, component, checkout, false, "cc")
        },
        showPayButton: true,
        setStatusAutomatically: true,
        onChange: handleOnChange,
        amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
        translations: translations
      }
      checkout = await AdyenCheckout(adyen_configuration)
      //setCheckout(await AdyenCheckout(adyen_configuration))
      // adyenComponent = checkout.create("card", { hasHolderName: true, holderNameRequired: true }).mount("#adyen")
      unmountAdyenComponents()
      setAdyenComponent(checkout.create("card", { hasHolderName: true, holderNameRequired: true }).mount("#adyen"))
      const younitedComponent = document.getElementById("younited-close");
      if (younitedComponent) {
        younitedComponent.click();
      }
      setApplepayDisabled(false)
    })
  }

  const loadPaypalForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
      AWSlogger({
        type: "log",
        action: "clickPaymentButton",
        cartId: active_cart.id,
        payment: "paypal",
        browserInfo: getBrowserInfo(),
        cart: getCartInfo(active_cart)
      })

      const adyen_configuration = {
        locale: ADYEN_LOCALE, // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/online-payments/components-web/localization-components.
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS }, // The payment methods response returned in step 1.
        onAdditionalDetails: onAdditionalDetails,
        onSubmit: (state: any, component: any) => {
          createPayment(state, component, checkout, false, "paypal")
        },
        showPayButton: true,
        setStatusAutomatically: true,
        onChange: handleOnChange, // Your function for handling onChange event
        amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
      }
      checkout = await AdyenCheckout(adyen_configuration)
      //setCheckout(await AdyenCheckout(adyen_configuration))
      unmountAdyenComponents()
      let tmp_paypalComponent = checkout
        .create("paypal", {
          environment: ADYEN_ENVIRONMENT, // Change this to "live" when you're ready to accept live PayPal payments
          countryCode: PROJECT_COUNTRY, // Only needed for test. This will be automatically retrieved when you are in production.
          intent: "authorize",
          amount: {
            currency: PROJECT_CURRENCY,
            value: active_cart.totalPrice.centAmount,
          },
          onSubmit: (state: any, component: any) => {
            // Your function calling your server to make the /payments request.
            createPayment(state.data, component, component, true, "paypal")
          },
          onCancel: (data: any, component: any) => {
            // Sets your prefered status of the component when a PayPal payment is cancelled. In this example, return to the initial state.
            component.setStatus("ready")
          },
          onError: (error: any, component: any) => {
            // Sets your prefered status of the component when an error occurs. In this example, return to the initial state.
            component.setStatus("ready")
          },
          onAdditionalDetails: (state: any, component: any) => {
            // Your function to submit a state.data object to the payments/details endpoint.
            additionalsDetailsPayment(state.data, component)
          },
          blockPayPalCreditButton: true,
          blockPayPalPayLaterButton: true
        })
        .mount("#paypal-container")
      setPaypalComponent(tmp_paypalComponent);
      const younitedComponent = document.getElementById("younited-close");
      if(younitedComponent){
        younitedComponent.click();
      }
      setApplepayDisabled(false)
    })
  }

  const loadPaypalDirectForm = async () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    let paypal: any

    try {
      paypal = await loadScript({
        clientId: PAYPAL_CLIENT_ID,
        currency: PROJECT_CURRENCY,
        enableFunding: "card",
        components: ["buttons","card-fields"]
      });
    } catch (error) {
      console.error("failed to load the PayPal JS SDK script", error);
    }

    if (paypal && !showPaypal) {
      ajax$(calls.Project.getActiveCart()).subscribe( async (active_cart) => {
        AWSlogger({
          type: "log",
          action: "clickPaymentButton",
          cartId: active_cart.id,
          payment: "paypal",
          browserInfo: getBrowserInfo(),
          cart: getCartInfo(active_cart)
        })
        try {
          const cardField = await paypal.CardFields({
            async createOrder() {
              try {
                const response = await createPayPalOrder(active_cart.id)

                if (response.obj.orderData.id) {
                  return response.obj.orderData.id;
                } else {
                  const errorDetail = response.obj.orderData?.details?.[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${response.orderData.debug_id})`
                    : JSON.stringify(response.obj.orderData);

                  throw new Error(errorMessage);
                }
              } catch (error) {
                console.error(error);
                setPaypalError("Si è verificato un errore in fase di pagamento, si prega di riprovare");
              }
            },
            async onApprove(data: any, actions: any) {
              try {
                const response = await capturePayPalOrder(active_cart.id, data.orderID)
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message

                const errorDetail = response.obj.orderData?.details?.[0];

                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart();
                } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(`${errorDetail.description} (${response.obj.orderData.debug_id})`);
                } else if (!response.obj.orderData.purchase_units || !response.obj.ctOrder) {
                  throw new Error(JSON.stringify(response.obj.orderData));
                } else {
                  // (3) Successful transaction -> Show confirmation or thank you message
                  // Or go to another URL:  actions.redirect('thank_you.html');
                  setCurrentOrder(response.obj.ctOrder)
                }
              } catch (error) {
                console.error(error);
                setPaypalError("Si è verificato un errore in fase di pagamento, si prega di riprovare");
              }
            },
            async onError(error: any) {
              // Handle the error object
              console.error(error);
              setPaypalError("Si è verificato un errore in fase di pagamento, si prega di riprovare");
              setClickedPayWithPaypal(false);
            },
          })
          unmountAdyenComponents()
          setShowPaypal(true)
          setLoadingPaypal(true)
          if (cardField.isEligible()) {
            const nameField = cardField.NameField({
              placeholder: "Nome sulla carta"
            });
            nameField.render("#card-name-field-container");

            const numberField = cardField.NumberField({
              placeholder: "Numero carta"
            });
            numberField.render("#card-number-field-container");

            const cvvField = cardField.CVVField();
            cvvField.render("#card-cvv-field-container");

            const expiryField = cardField.ExpiryField({
              placeholder: "Data di scadenza (MM/AA)"
            });
            expiryField.render("#card-expiry-field-container");

            // Add click listener to submit button and call the submit function on the CardField component
            const paypalContainer = document.getElementById('paypal-direct-container')
            const cardFieldButton = document.getElementById("card-field-submit-button")
            if (cardFieldButton){
              cardFieldButton.addEventListener("click", () => {
                cardField.getState().then((data: any) => {
                  // Submit only if the current state of the form is valid
                  if (data.isFormValid) {
                    setPaypalError("")
                    setClickedPayWithPaypal(true)
                    cardField.submit().then(() => {
                      setClickedPayWithPaypal(false)
                    });
                  } else {
                    setPaypalError("Ricontrolla i dati inseriti")
                  }
                });
              });
            }
            if (paypalContainer) {
              setTimeout(() => {
                setLoadingPaypal(false);
                paypalContainer.style.display = 'block';
              }, 3000);
            }
          }

          const younitedComponent = document.getElementById("younited-close");
          if (younitedComponent) {
            younitedComponent.click();
          }
          setApplepayDisabled(false)
        } catch (error) {
          console.error("failed to render the PayPal Buttons", error);
        }
      })
    }
  }

  const loadKlarnaForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
      AWSlogger({
        type: "log",
        action: "clickPaymentButton",
        cartId: active_cart.id,
        payment: "klarna",
        browserInfo: getBrowserInfo(),
        cart: getCartInfo(active_cart)
      })

      const adyen_configuration = {
        locale: ADYEN_LOCALE,
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
        onAdditionalDetails: onAdditionalDetails,
        onSubmit: (state: any, component: any) => {
          createPayment(state, component, checkout, false, "klarna")
        },
        showPayButton: true,
        setStatusAutomatically: true,
        onChange: handleOnChange,
        amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
      }
      checkout = await AdyenCheckout(adyen_configuration)
      unmountAdyenComponents()
      if (PROJECT_COUNTRY === "DE") {
        setKlarnaComponent(checkout.create("klarna").mount("#klarna-container"))
      } else {
        setKlarnaComponent(checkout.create("klarna_account").mount("#klarna-container"))
      }
      const younitedComponent = document.getElementById("younited-close");
      if (younitedComponent) {
        younitedComponent.click();
      }
      setApplepayDisabled(false)
    })
  }

  const redirectToPayU = (isRatyPay: boolean) => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    startPayU(cart.id, isRatyPay)
  }

  const redirectToYounitedPay = (maturity: number, phone: string) => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    initYounitedContract(cart.id, maturity, phone)
  }

  const redirectToBuyBox = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    initBuyBoxPayment(cart.id)
  }

  const redirectToPayFast = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    generatePayFastSignature(cart.id, PROJECT_KEY)
  }

  React.useEffect(() => {
    if (buyBox && buyBox.obj?.redirectUri) {
      redirectTo(buyBox.obj.redirectUri)
    }
  }, [buyBox])

  return (
    <StyledCheckout>
      {processingOrder && <div className="processingOrderMessage title">Creating order <div className="loader">...</div></div>}
      <div style={{position: "relative"}} className={`row no-gutters ${processingOrder && "processingOrderStyle"}`}>
        <div className="checkout-column-main col-lg-8">
          <div className="wrapper">
            {/* Seleziona Store o Consega a casa */}
            {isPickUpAvailable && (
              <div className="row">
                <div className="col-6">
                  <Box className="secondary compact unbordered">
                    <h4>
                      <a
                        href="#"
                        className={
                          shippingType === EShippingType.Home
                            ? "font-weight-bold title-devivery-mode"
                            : "font-weight-normal title-devivery-mode"
                        }
                        onClick={() => {
                          setShippingType(EShippingType.Home)
                          resetCartForCheckout(tempAddresses, true)
                          unmountAdyenComponents()
                          setApplepayDisabled(false)
                          const younitedComponent = document.getElementById("younited-close");
                          if (younitedComponent) {
                            younitedComponent.click();
                          }
                        }}
                      >
                        {" "}
                        {t("ecommerce.checkout.delivery-to-home-title")}{" "}
                      </a>
                    </h4>
                  </Box>
                </div>

                <div className="col-6">
                  <Box className="secondary compact unbordered">
                    <h4>
                      <a
                        href="#"
                        className={
                          shippingType === EShippingType.Pickup
                            ? "font-weight-bold title-devivery-mode"
                            : "font-weight-normal title-devivery-mode"
                        }
                        onClick={() => {
                          setShippingType(EShippingType.Pickup)
                          resetCartForCheckout(null, true)
                          unmountAdyenComponents()
                          setApplepayDisabled(false)
                          const younitedComponent = document.getElementById("younited-close");
                          if (younitedComponent) {
                            younitedComponent.click();
                          }
                        }}
                      >
                        {" "}
                        {t("ecommerce.checkout.pickup-in-store-title")}
                      </a>
                    </h4>
                  </Box>
                </div>
              </div>
            )}

            {/* Lista indirizzi di cosnegna  */}
            <AddressList
              className={` ${activeStep == 0 && "checkout-active-step"} `}
              type={EAddressType.Shipping}
              shippingType={shippingType}
              callbackData={setTempAddresses}
            />

            {/* Lista indirizzi di fatturazione  */}

            {cart.shippingAddress?.custom?.fields.internalType !== "placeholder" && (
              <AddressList className={` ${activeStep === 1 && "checkout-active-step"} `} type={EAddressType.Billing} />
            )}

            {/* Metodi di spedizione - Solo se consegna a casa  */}
            {shippingType === EShippingType.Home &&
              cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
              cart.billingAddress && (
                <div className="component">
                  <Box className={`secondary compact ${activeStep === 2 && "checkout-active-step"} `}>
                    <h3>{t("ecommerce.checkout.shipping-section-title")}</h3>
                    <div>{t("ecommerce.checkout.shipping-section-description")}</div>
                    <ul>
                      {shippingMethods
                        ?.filter(
                          (shippingMethod: any) =>
                            shippingMethod.custom?.fields["shipping-type-list"] !== EShippingType.Pickup
                        )
                        .map((shippingMethod: any, key: number) => {
                          let shippingName: string = shippingMethod?.localizedName?.[PROJECT_LANGUAGE] ? shippingMethod?.localizedName?.[PROJECT_LANGUAGE] : shippingMethod?.name;
                          let description: string = shippingMethod?.localizedDescription?.[PROJECT_LANGUAGE]
                          let shippingMethodPrice: ITotalPrice = shippingMethod?.zoneRates[0]?.shippingRates[0]?.price ? shippingMethod?.zoneRates[0]?.shippingRates[0]?.price : null;
                          let taxedShippingPrice = cart.taxedShippingPrice?.totalGross?.centAmount || 0;
                          if (shippingMethod?.zoneRates[0]?.shippingRates[0]?.freeAbove) {
                            let freeAbove: boolean = (cart.totalPrice.centAmount - taxedShippingPrice) > shippingMethod?.zoneRates[0]?.shippingRates[0]?.freeAbove.centAmount
                            if (freeAbove) shippingMethodPrice.centAmount = 0
                          }
                          return (
                            <li key={key} className="radioButton">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <input
                                    id={shippingMethod.id}
                                    value={shippingMethod.id}
                                    type="radio"
                                    readOnly
                                    checked={shippingMethod?.id === cart.shippingInfo?.shippingMethod?.id}
                                    onClick={() => setShippingMethod(shippingMethod.id)}
                                  />{" "}
                                  <label htmlFor={shippingMethod.id}>{shippingName}</label>
                                </div>
                                {shippingMethodPrice &&
                                  <div className="price pt-1">
                                    {shippingMethodPrice?.centAmount > 0 ?
                                      parsePrice(shippingMethodPrice)
                                      : shippingMethodPrice?.centAmount === 0 ?
                                        t("ecommerce.checkout.free")
                                        : null
                                    }
                                  </div>
                                }
                              </div>
                              {description && <p className="text-sm pl-3">{description}</p>}
                            </li>
                          )
                        })}
                    </ul>
                  </Box>
                </div>
              )
            }

            {/* Inserimento email destinatario per ogni buono regalo */}
            {cart.billingAddress && cart.shippingInfo && cart.lineItems && giftcardInsideCard && IS_GIFT_CARD_ACTIVE &&
            <>
                <Box className={`secondary compact ${activeStep === 3 && "checkout-active-step"} `}>
                    <h3 style={{marginBottom: '0'}}>{t("ecommerce.giftcard.checkout.form-section-title")}</h3>
                    <p style={{marginBottom: '20px'}}>{t("ecommerce.giftcard.checkout.form-section-subtitle")}</p>
                    <form action="" id="giftCardForm" onSubmit={handleSubmit(onSubmit)}>
                      {cart.lineItems.map((giftCard) => {
                        if (giftCard?.productType?.obj?.key === "GIFTCARD") {
                          const {name, quantity} = giftCard;

                          return (
                              <>
                                {[...Array(quantity)].map((giftcardQta, giftCardQtaIndex) => {

                                  return (
                                      <>
                                        <Box className="secondary unbordered unboxed ">
                                          <Property
                                              label={t("ecommerce.giftcard.checkout.giftCard-title")}
                                              item={`${name[Object.keys(name)[0]]} - ${quantity >= 2 ? `${giftCardQtaIndex + 1} / ${quantity.toString()}` : quantity}`}
                                          />
                                          <div className="row">
                                            <div className="col">
                                              <InputElement type='name'
                                                  data-Id={giftCard.id}
                                                  data-quantity={giftCardQtaIndex + 1}
                                                  data-type={"name"}
                                                  label={t("ecommerce.giftcard.name")}
                                                  key={name + giftCard.id + giftCardQtaIndex}
                                                  externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.name`)}
                                                  errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.name?.message}
                                                  disabled={enabledGiftCardEditButton}/>
                                            </div>
                                            <div className="col">
                                              <InputElement type='surname'
                                                  data-Id={giftCard.id}
                                                  data-quantity={giftCardQtaIndex + 1}
                                                  data-type={"surname"}
                                                  label={t("ecommerce.giftcard.surname")}
                                                  key={name + giftCard.id + giftCardQtaIndex}
                                                  externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.surname`)}
                                                  errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.surname?.message}
                                                  disabled={enabledGiftCardEditButton}/>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col">
                                              <InputElement type='email'
                                                  data-Id={giftCard.id}
                                                  data-quantity={giftCardQtaIndex + 1}
                                                  data-type={"email"}
                                                  label={t("ecommerce.giftcard.email")}
                                                  key={name + giftCard.id + giftCardQtaIndex}
                                                  externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.email`)}
                                                  errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.email?.message}
                                                  disabled={enabledGiftCardEditButton}/>
                                            </div>
                                            <div className="col">
                                              <InputElement type='confirmEmail'
                                                  data-Id={giftCard.id}
                                                  data-quantity={giftCardQtaIndex + 1}
                                                  data-type={"confirmEmail"}
                                                  label={t("ecommerce.giftcard.confirmEmail")}
                                                  key={name + giftCard.id + giftCardQtaIndex}
                                                  externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.confirmEmail`)}
                                                  errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.confirmEmail?.message}
                                                  disabled={enabledGiftCardEditButton}/>
                                            </div>
                                          </div>
                                        </Box>
                                      </>)
                                })}
                              </>
                          );
                        }
                      })}
                      <>
                      {!enabledGiftCardEditButton && <Button className="primary mt-4 mr-4" label={t("ecommerce.giftcard.checkout.save")} type="submit"/>}
                      {enabledGiftCardEditButton && <Button className="primary mt-4" label={t("ecommerce.common.edit")} type="button" onClick={(e: any) => {setEnabledGiftCardEditButton(false)}}/>}
                      </>
                    </form>
                </Box>
            </>
            }

            {/* Inserimento nota su ordine */}
            {!["DE", "NL"].includes(PROJECT_COUNTRY) &&
            cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
            cart.billingAddress &&
            cart.shippingInfo && (
                <div className="component">
                    <Box className={`secondary compact ${activeStep === 3 && "checkout-active-step"} `}>
                        <h3>{t("ecommerce.checkout.note-title")}</h3>
                        <div>{t("ecommerce.checkout.note-description")}</div>
                        <textarea
                            style={{width: "100%"}}
                            className=""
                            onBlur={(e) => {
                                setNoteOnCart(e.target.value)
                            }}
                        ></textarea>
                    </Box>
                </div>
            )
            }

            {/* se ci sono giftcard da acquistare e il form dei destinatari é stato compilato o se non ci sono giftcard a carrello */}
            {(enabledGiftCardEditButton || !giftcardInsideCard) &&
            <>
              <Box className="boxed-mobile">
                <span className="text-bold">{t("ecommerce.checkout.deliveryInfo")}</span>
              </Box>
              {/* isCartChange = true se a carrello é presente un prodotto con disponibilitá negativa (caso particolare per POLONIA che puo avere prodotti con disponibilitá negativa*/}
              {isCartChanged &&
                cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
                cart.billingAddress &&
                cart.shippingInfo && (
                  <div className="component">
                    <Box className="secondary compact">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h3 className="focus">{t("ecommerce.checkout.cart-changed-title")}</h3>
                          <p dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.cart-changed-description") }} />
                        </div>
                      </div>
                    </Box>
                  </div>
                )}
              {!isCartChanged &&
                cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
                cart.billingAddress &&
                cart.shippingInfo && (
                  <div className="component">
                    <Box className="secondary compact">
                      {/* Privacy policy per GUEST */}
                      {!userData &&
                        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
                        cart.billingAddress &&
                        cart.shippingInfo && (
                          <div className="component">
                            <ul>
                              {PROJECT_COUNTRY !== "PL" &&
                                <li key="privacy" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                  <div className="d-flex align-items-center mb-1">
                                    <input
                                      type="checkbox"
                                      checked={privacy}
                                      value="1"
                                      name="privacy"
                                      id="privacy"
                                      onClick={(event: any) => {
                                        setPrivacy(event.target?.checked)
                                      }}
                                    />
                                    <label
                                      htmlFor="privacy"
                                      dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.privacy-check-description") }}
                                    ></label>
                                    <span className="pr-1">*</span>
                                  </div>
                                </li>
                              }
                              <li key="commercial" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                <div className="d-flex align-items-center mb-1">
                                  <input
                                    type="checkbox"
                                    checked={commercial}
                                    value="1"
                                    name="commercial"
                                    id="commercial"
                                    onClick={(event: any) => {
                                      setCommercial(event.target?.checked)
                                    }}
                                  />
                                  <label
                                    htmlFor="commercial"
                                    dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.commercial-check-description") }}
                                  ></label>
                                  <span className="pr-1">*</span>
                                </div>
                              </li>
                              {hasExternalWarrantyService &&
                                <li key="external-warranty-service" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                  <div className="d-flex align-items-center mb-1">
                                    <input
                                        type="checkbox"
                                        checked={externalWarrantyCheck}
                                        value="1"
                                        name="external-warranty-service"
                                        id="external-warranty-service"
                                        onClick={(event: any) => {
                                          setEternalWarrantyCheck(event.target?.checked)
                                        }}
                                    />
                                    <label
                                        htmlFor="external-warranty-service"
                                        dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.external-warranty-check-description") }}
                                    ></label>
                                    <span className="pr-1">*</span>
                                  </div>
                                </li>
                              }
                              <li key="nl-consent" className={`checkboxButton`}>
                                <div className="d-flex align-items-center mb-1">
                                  <input
                                    type="checkbox"
                                    checked={nlConsentValue}
                                    value="1"
                                    name="nl-consent"
                                    id="nl-consent"
                                    onClick={(event: any) => { setNlConsent(!nlConsentValue); setNlConsentValue(event.target?.checked) }}
                                  />
                                  <label
                                    htmlFor="nl-consent"
                                    dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.newsletter-flag") }}
                                  ></label>
                                </div>
                              </li>
                              {consent3Party &&
                                <li key="consent-3-party" className={`checkboxButton`}>
                                  <div className="d-flex align-items-center mb-1">
                                    <input
                                      type="checkbox"
                                      checked={consent}
                                      value="1"
                                      name="consent-3-party"
                                      id="consent-3-party"
                                      onClick={(event: any) => {
                                        setConsent(event.target?.checked)
                                        setConsentThrdParty(event.target?.checked)
                                      }}
                                    />
                                    <label
                                      htmlFor="consent-3-party"
                                      dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.consent-3-party-check-description") }}
                                    ></label>
                                  </div>
                                </li>
                              }
                              {/*
                                <li key="subscribe" className="checkboxButton">
                                  <input type="checkbox" value="1" name="subscribe" id="subscribe" onClick={(event: any) => setSubscriptionOnCart(event.target?.checked)} /> <label htmlFor="subscribe" dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.subscribe-description") }}></label>
                                </li>
                              */}
                            </ul>
                          </div>
                        )}

                      {/* Privacy policy per USER */}
                      {userData &&
                        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
                        cart.billingAddress &&
                        cart.shippingInfo && (
                          <div className="component">
                            <ul>
                              <li key="commercial" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                <div className="d-flex align-items-center mb-1">
                                  <input
                                    type="checkbox"
                                    value="1"
                                    checked={commercial}
                                    name="commercial"
                                    id="commercial"
                                    onClick={(event: any) => {
                                      setCommercial(event.target?.checked)
                                    }}
                                  />
                                  <label
                                    htmlFor="commercial"
                                    dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.commercial-check-description") }}
                                  ></label>
                                  <span className="pr-1">*</span>
                                </div>
                              </li>
                              {hasExternalWarrantyService &&
                                <li key="external-warranty-service" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                  <div className="d-flex align-items-center mb-1">
                                    <input
                                        type="checkbox"
                                        checked={externalWarrantyCheck}
                                        value="1"
                                        name="external-warranty-service"
                                        id="external-warranty-service"
                                        onClick={(event: any) => {
                                          setEternalWarrantyCheck(event.target?.checked)
                                        }}
                                    />
                                    <label
                                        htmlFor="external-warranty-service"
                                        dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.external-warranty-check-description") }}
                                    ></label>
                                    <span className="pr-1">*</span>
                                  </div>
                                </li>
                              }
                              {consent3Party &&
                                <li key="consent-3-party" className={`checkboxButton`}>
                                  <div className="d-flex align-items-center mb-1">
                                    <input
                                      type="checkbox"
                                      checked={consent}
                                      value="1"
                                      name="consent-3-party"
                                      id="consent-3-party"
                                      onClick={(event: any) => {
                                        setConsent(event.target?.checked)
                                        setConsentThrdParty(event.target?.checked)
                                      }}
                                    />
                                    <label
                                      htmlFor="consent-3-party"
                                      dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.consent-3-party-check-description") }}
                                    ></label>
                                  </div>
                                </li>
                              }
                            </ul>
                          </div>
                      )}

                      {IS_GIFT_CARD_ACTIVE && hasGiftCards &&
                      <>
                        <div className="checkboxButton">
                          <input
                              type="checkbox"
                              id="toggleDropdown"
                              name="toggleDropdown"
                              checked={showGiftCardSelect}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setShowGiftCardSelect(isChecked);

                                if (!isChecked) {
                                  setRemoveAllGiftCardFromCart(cart);
                                }
                              }}
                          />
                          <label htmlFor="toggleDropdown">{t("ecommerce.giftcard.load")}</label>
                        </div>
                        {showGiftCardSelect &&
                          <SelectMultiOptions onChange={handleSelectChange} isMulti options={options} value={selectedGiftcards} className={"col-12 col-md-6 mb-3"}/>
                        }
                      </>
                      }

                      {cart.totalPrice.centAmount > 0 &&
                      <>
                        <div className="text-center">
                          <div className="row">
                            {PAYMENT_METHODS?.includes("adyen") &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-visa-mastercard"
                                  label={t("ecommerce.checkout.pay-button")}
                                  onClick={loadAdyenForm} />
                              </div>
                            }
                            {PAYMENT_METHODS?.includes("paypal-direct") &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-paypal"
                                  label={t("ecommerce.checkout.paypal.direct")}
                                  onClick={loadPaypalDirectForm} />
                              </div>
                            }
                            {PAYMENT_METHODS?.includes("paypal") &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-paypal"
                                  label={t("ecommerce.checkout.paypal")}
                                  onClick={loadPaypalForm} />
                              </div>
                            }
                            {PAYMENT_METHODS?.includes("payu") && !lineItemWithNoAvailability &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-payU"
                                  label={t("ecommerce.checkout.payu")}
                                  onClick={() => redirectToPayU(false)} />
                              </div>
                            }
                            {PAYMENT_METHODS?.includes("payu") && !lineItemWithNoAvailability 
                            && cart.totalPrice.centAmount >= 30000 && cart.totalPrice.centAmount <= 5000000 &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-payU"
                                  label={t("ecommerce.checkout.ratypayu")}
                                  onClick={() => redirectToPayU(true)} />
                              </div>
                            }
                            {PROJECT_COUNTRY?.includes("PL") && !lineItemWithNoAvailability &&
                              <div className="col-xl-6">
                                <Button
                                    disabled={true}
                                    className="ec_btn_primary icon-cards-bankTrans"
                                    label={t("ecommerce.checkout.banktransfer")}
                                    onClick={payWithBankTransfer} />
                              </div>
                            }
                            {PROJECT_COUNTRY?.includes("PL") && lineItemWithNoAvailability &&
                            <div className="col-xl-6">
                              <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-payByLink"
                                  label={t("ecommerce.checkout.payLaterWithPayByLink")}
                                  onClick={payLaterWithPayByLink} />
                            </div>
                            }
                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("klarna_account") !== -1 && cart.totalPrice.centAmount < 300000 &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-klarna"
                                  label={t("ecommerce.checkout.klarna")}
                                  onClick={loadKlarnaForm} />
                              </div>
                            }
                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("sepadirectdebit") !== -1 &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-sepa"
                                  label={t("ecommerce.checkout.sepa")}
                                  onClick={loadSepaForm} />
                              </div>
                            }
                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("bancontact") !== -1 &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-bancontact"
                                  label={t("ecommerce.checkout.bancontact")}
                                  onClick={loadBancontactForm} />
                              </div>
                            }
                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("ideal") !== -1 &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-ideal"
                                  label={t("ecommerce.checkout.ideal")}
                                  onClick={loadIdealForm} />
                              </div>
                            }
                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("applepay") !== -1 &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-applepay"
                                  label={t("ecommerce.checkout.applepay")}
                                  onClick={loadApplePayForm} />
                                {applepayEnabled &&
                                  <div
                                      dangerouslySetInnerHTML={{ __html: t("ecommerce.error.applePayDisabled") }}
                                      className="w-100 error message--alert small"
                                  />
                                      }
                              </div>
                            }
                            {PAYMENT_METHODS?.includes("buybox") &&
                              <div className="col-xl-6">
                                <Button
                                  disabled={true}
                                  className="ec_btn_primary icon-cards-buybox"
                                  label={t("ecommerce.checkout.buybox")}
                                  onClick={redirectToBuyBox} />
                              </div>
                            }
                            {PAYMENT_METHODS?.includes("younited") && cart.totalPrice.centAmount >= 30000 &&
                              <div className="col-md-12" style={{padding: 0}}>
                                {
                                  <YounitedPay
                                      cartid={cart.id}
                                      amount={(cart.totalPrice.centAmount / 100)}
                                      redirectToYounitedPay={redirectToYounitedPay}
                                      showPayButton={true}
                                      setInModal={false}
                                      checkPrivacy={checkPrivacyFlagged}
                                      setClickedPay={setClickedPay}
                                      unmountAdyenComponents={unmountAdyenComponents}
                                      phone={cart.billingAddress?.mobile?.toString()}
                                  />
                                }
                              </div>
                            }
                            {PAYMENT_METHODS?.includes("payfast") &&
                              <div className="col-xl-6">
                                <Button
                                    btn_disabled={clickedPay}
                                    icon={"fas fa-circle-notch fa-spin"}
                                    className="ec_btn_primary icon-cards-payfast"
                                    label={t("ecommerce.checkout.payfast")}
                                    onClick={redirectToPayFast}
                                    loader={{ startLoader: clickedPay, stopLoader: !clickedPay }}
                                />
                              </div>
                            }
                          </div>
                        </div>

                        <div className="row" id="payments-row">
                          <div className="col-md-8 offset-md-2">
                            {loadingPaypal && showPaypal && <div className="isLoading"></div>}
                            {showPaypal &&
                            <div id="paypal-direct-container" style={{display:'none'}} className="text-center">
                                <div id="card-name-field-container"/>
                                <div id="card-number-field-container"/>
                                <div id="card-expiry-field-container"/>
                                <div id="card-cvv-field-container"/>
                                <p className="w-100 error message--alert">{paypalError}</p>
                                <Button
                                  id="card-field-submit-button"
                                  className="ec_btn_primary"
                                  type="button"
                                  icon={"fas fa-circle-notch fa-spin"}
                                  loader={{ startLoader: clickedPayWithPaypal, stopLoader: !clickedPayWithPaypal }}
                                  disabled={clickedPayWithPaypal}
                                  label={t("ecommerce.checkout.paypal.paynow")}>
                                </Button>
                            </div>}
                            <div id="paypal-container" className="text-center"/>
                            <div id="adyen"/>
                            <div id="klarna-container"/>
                            <div id="action-container"/>
                            <div id="sepadirectdebit"/>
                            <div id="bcmc-container"/>
                            <div id="applepay-container"/>
                            <div id="ideal-container"/>
                          </div>
                        </div>
                      </>
                      }

                      {cart.totalPrice.centAmount === 0 && cart.customLineItems && cart.customLineItems.some(item => item.slug.startsWith('gift-card')) &&
                        <div className="text-center">
                          <div className="row">
                            <div className="col-md-6">
                              <Button
                                  disabled={true}
                                  className="ec_btn_primary"
                                  label={t("ecommerce.checkout.closeOrder")}
                                  onClick={closeOrderWithGiftCard} />
                            </div>
                          </div>
                        </div>
                      }
                    </Box>
                  </div>
                )}
            </>
            }
          </div>
        </div>
        <aside className="checkout-column-aside col-lg-4">
            <CartRecap />
        </aside>
      </div>
    </StyledCheckout>
  )
}

const AddressList = (props: IAddressList) => {
    const {type, shippingType, callbackData, className} = props
    const {userData, cart, shippingMethods, selectedShippingMethodDescription, invoiceValue, setInvoiceValue} = React.useContext(GeneralContext)
    const {setAddress, setSendInvoice, setPickupInStore, processingOrder} = React.useContext(CartContext)
    const componentElement = React.useRef<HTMLDivElement>(null)


    /* Mostra la Form di inserimento indirizzo */
    const [formAddAddressVisible, setFormAddAddressVisible] = React.useState(false)

    const [showListAddress, setShowListAddress] = React.useState(true)
    const [listAddress, setListAddress] = React.useState<any>(null!)
    const [numberAddresAvailable, setNumberAddresAvailable] = React.useState(0)

    const {t} = React.useContext(LabelContext)

    React.useEffect(() => {
        if (BRX_LOCALE === "nl_NL") {
            setInvoiceValue(true)
        }
    }, [])

    React.useEffect(() => {
        if (formAddAddressVisible === false && componentElement) {
            const yOffset = -400

            let bounding = componentElement?.current?.getBoundingClientRect()
            if (bounding && processingOrder == false) {
                let y = bounding?.top + window.pageYOffset + yOffset
                window.scrollTo({top: y, behavior: "smooth"})
            }
        }
    }, [formAddAddressVisible])

    React.useEffect(() => {
        /*
        Indirizzi disponibili per la selezione
        Se utente loggato:
          - Conta gli indirizzi del tipo selezionato
        Se guest:
          - Conta gli indirizzi nel carrello (sempre uno) ma solo con internalType === undefined (per escludere internalType === "placeholder")
        */

        let listAddress = userData
            ? userData?.addresses.filter((address) => userData[EUserDataAddressesIdentifier[type]].indexOf(address.id) !== -1)
            : [cart[ESelectedAddress[type]]].filter(
                (address) => address && address.custom?.fields?.internalType === undefined
            )

        setListAddress(listAddress)

        setNumberAddresAvailable(listAddress.length)
    }, [cart])

    /*
    Indica che è possibile aggiunge un nuovo indirizo:
    - SI: se indirizzo di fatturazione
    - SI: se indirizzo di spedizione con Ritiro a casa
    - NO: se indirizzo di spedizione con Ritiro in STORE
     */
    const canAddNewAddress =
        (type === EAddressType.Shipping && shippingType === EShippingType.Home) || type === EAddressType.Billing

    const useStoresAsAddress = type === EAddressType.Shipping && shippingType === EShippingType.Pickup

    // Titolo della sezione
    let title: string =
        (type === EAddressType.Shipping
            ? shippingType === EShippingType.Home
                ? t("ecommerce.checkout.home-address-title")
                : t("ecommerce.checkout.select-store-title")
            : t("ecommerce.checkout.billing-address-title")) || ""

    // Sottotitolo della sezione
    let subTitle: string =
        (type === EAddressType.Shipping
            ? shippingType === EShippingType.Home
                ? t("ecommerce.checkout.home-address-description")
                : t("ecommerce.checkout.select-store-description")
            : t("ecommerce.checkout.billing-address-description")) || ""

    /*
    Testo del pulsante Aggiungi indirizzo:
    Suddiviso per Loggato | Gest - Spedizione | Fatturazione
    */
    let labelButtonAddNewAddress: string = userData
        ? t("ecommerce.checkout.button-new-billing-address-logged-user")
        : t("ecommerce.checkout.button-new-billing-address-guest")
    if (type === EAddressType.Shipping) {
        labelButtonAddNewAddress = userData
            ? t("ecommerce.checkout.button-new-shipping-address-logged-user")
            : t("ecommerce.checkout.button-new-shipping-address-guest")
    }

    /**
     Costruisce la string dell'indirizzo corrente
     Cerca l'indirizzo impostato nel carrello, e lo costruisce in base al Type
     **/

    let address = cart[ESelectedAddress[type]]
    let selectedAddress = ""
    if (address && address?.custom?.fields?.internalType !== "placeholder") {
        selectedAddress =
            type === "billing"
                ? address.custom?.fields?.fiscalcode
                ? address.custom?.fields?.fiscalcode
                : address.company
                    ? address.company
                    : ""
                : ""
        selectedAddress =
            selectedAddress +
            " " +
            address.firstName +
            " " +
            (address.lastName ? address.lastName : "") +
            ", " +
            address.streetName +
            " " +
            (address.streetNumber !== undefined ? address.streetNumber : "") +
            ", " +
            address.postalCode +
            " " +
            address.city
    }

    React.useEffect(() => {
        // Se non sono presenti indirizzi ed è possile aggiungerne, aprima la form
        setFormAddAddressVisible(numberAddresAvailable === 0 && canAddNewAddress)

        // Se non c'è un indirizzo selezionato mostriamo lista
        setShowListAddress(!selectedAddress)
    }, [numberAddresAvailable, canAddNewAddress, selectedAddress])

    return (
        <div className="component" ref={componentElement}>
            <Box className={`secondary compact ${className}`}>
                <h3>{title}</h3>
                {selectedAddress && (
                    <>
                    <span>
                      <b>{selectedAddress}</b> -{" "}
                      <a href="#" onClick={(e) => {e.preventDefault(); setShowListAddress(true)}}>
                        {type === EAddressType.Shipping && shippingType === EShippingType.Home && t("ecommerce.checkout.change-selected-address")}
                        {type === EAddressType.Billing && t("ecommerce.checkout.change-selected-address")}
                        {type === EAddressType.Shipping && shippingType === EShippingType.Pickup && t("ecommerce.checkout.change-selected-store")}
                      </a>
                    </span>
                    {type === EAddressType.Shipping && shippingType === EShippingType.Pickup &&
                        <>
                          <br/>
                          <span>{selectedShippingMethodDescription}</span>
                        </>
                    }
                    </>
                )}

                {numberAddresAvailable > 0 && userData && showListAddress && <div>{subTitle}</div>}

                {showListAddress && (
                    <ul>
                        {!useStoresAsAddress
                            ? listAddress &&
                            numberAddresAvailable > 0 &&
                            listAddress.map((address: any, i: number) => {
                                return (
                                    <li key={i} className="radioButton">
                                        <input
                                            id={`address-${type}-${i}`}
                                            type="radio"
                                            name={`address-${type}`}
                                            onClick={() => {
                                                setAddress(address, type).subscribe()
                                                setFormAddAddressVisible(false)
                                                setShowListAddress(false)
                                            }}
                                        />
                                        <label htmlFor={`address-${type}-${i}`}>
                                            {type === "billing"
                                                ? address.custom?.fields?.fiscalcode
                                                    ? " " + address.custom?.fields?.fiscalcode
                                                    : " " + (address.company ? address.company : "")
                                                : ""}{" "}
                                            {address.firstName} {address.lastName}, {address.streetName}{" "}
                                            {address.streetNumber !== undefined ? address.streetNumber : ""}, {address.postalCode}{" "}
                                            {address.city}
                                        </label>
                                    </li>
                                )
                            })
                            : (shippingMethods || [])
                                .filter(
                                    (store: any) =>
                                        store.custom?.fields?.["shipping-type-list"] &&
                                        store.custom.fields["shipping-type-list"] === EShippingType.Pickup
                                )
                                .map((address: any, i: number) => (
                                    <>
                                      <li key={i} className="radioButton">
                                          <input
                                              id={"shippingMethod-" + i}
                                              type="radio"
                                              name="shippingMethod"
                                              onClick={() => {
                                                  setPickupInStore(address.id)
                                                  setFormAddAddressVisible(false)
                                                  setShowListAddress(false)
                                              }}
                                          />
                                          <label htmlFor={"shippingMethod-" + i}>
                                            {" "}
                                              <b>{address.name}</b>, {address.custom.fields.streetName} {address.custom.fields.streetNumber},{" "}
                                                {address.custom.fields.city}, {address.custom.fields.postalCode} ({address.custom.fields.region})
                                            {" "}
                                          </label>
                                      </li>
                                      <label style={{display: "block", marginLeft: "20px"}}>{address.localizedDescription?.[PROJECT_LANGUAGE]}</label>
                                    </>
                                ))}
                    </ul>
                )}

                {PROJECT_SETTINGS.showRequestInvoice === true && type === "billing" &&
                  <>
                  {!["DE", "PL"].includes(PROJECT_COUNTRY) &&
                    <div className="checkboxButton">
                      <input
                          type="checkbox"
                          value="1"
                          name="saveInvoice"
                          id="saveInvoice"
                          checked={invoiceValue}
                          onClick={(event: any) => {
                            setSendInvoice(!invoiceValue);
                            setInvoiceValue(event.target?.checked)
                          }}
                      />{" "}
                      <label htmlFor="saveInvoice">{t("ecommerce.checkout.send-me-invoice-flag")}</label>
                    </div>
                  }
                    {["PL"].includes(PROJECT_COUNTRY) &&
                      <div className="checkboxButton" style={{ display: 'none' }}>
                        <input
                            type="checkbox"
                            value="1"
                            name="saveInvoice"
                            id="saveInvoice"
                            checked={invoiceValue}
                        />{" "}
                        <label htmlFor="saveInvoice">{t("ecommerce.checkout.send-me-invoice-flag")}</label>
                      </div>
                    }
                  </>
                }

                {canAddNewAddress && !formAddAddressVisible && showListAddress && (
                    <>
                        {" "}
                        <Button
                          className={`simple`}
                          icon="fas fa-plus"
                          onClick={() => setFormAddAddressVisible(true)}
                          label={labelButtonAddNewAddress}
                        />
                    </>
                )}

                {formAddAddressVisible && (
                    <div id="containerAddAddress">
                        <AddAddress type={type} callback={setFormAddAddressVisible} callbackData={callbackData}/>
                    </div>
                )}
            </Box>
        </div>
    )
}
