import Select, { components } from "react-select";
import styled from "styled-components";
import React from "react"
import {createPrice, parsePrice} from "../../../common/constants"
import {PROJECT_CURRENCY} from "../../../core/APIUtils"
import {GeneralContext} from "../../../core/general.context"
import {LabelContext} from "../../../core/label.context"

// Styled-components for styling a container for the selected values
const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

// Styled-components for styling individual selected values
const Value = styled.div`
  padding: 0.3rem 0.5rem;
  margin: 0 0 0.55rem 0;
  font-size: 0.75rem;
  color: black;
  user-select: none;
  width: 100%;
  position: relative;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;

const LabelWarning = styled.div`
  padding: 0.2rem 0.5rem;
  margin: 0 0 0.55rem 0;
  font-size: 0.75rem;
  color: black;
  user-select: none;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Styled button to allow removal of selected values
const XButton = styled.button`
  all: unset;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
  min-width: 30px;
  text-align: center;
  &:hover {
    color: #bb392d;
  }
  &:focus {
    color: #c82f21;
  }
  /* Aligning the button to the center of the Value component */
  align-self: center;
`;

// Helper function to format the label displayed in dropdown and selected values
const formatLabel = (val: any) => {
    let residualFormatted = '';
    if (val.residual >= 0) {
        residualFormatted = parsePrice(createPrice(val.residual, PROJECT_CURRENCY));
    }
    return `${val.label} (${residualFormatted})`;
};

// Custom Option component to render options in the dropdown list
const CustomOption = (props: any) => {
    return (
        <components.Option {...props} >
            {formatLabel(props.data)}
        </components.Option>
    );
};


const SelectMultiOptions = (props: any) => {
    const { isMulti, value, onChange } = props;
    const { cart } = React.useContext(GeneralContext);
    const {t} = React.useContext(LabelContext)

    const isOptionDisabled = () => cart.totalPrice.centAmount === 0;

    const handleRemoveValue = (e: any) => {
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = value.find((val: any) => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter((val: any) => val.value !== buttonName),
      { action: "remove-value", removedValue }
    );
  };

  return (
    <div>
        <Select
            {...props}
            controlShouldRenderValue={!isMulti}
            isOptionDisabled={isOptionDisabled}
            components={{
                ClearIndicator: () => null,
                Option: CustomOption
            }}
        />
        <ValuesContainer className={props.className}>
            {isMulti && value.map((val: any) =>{
                  let residualFormatted = null
                  if(val.residual  >= 0){
                      residualFormatted = parsePrice(createPrice(val.residual, PROJECT_CURRENCY))
                  }
                  return (
                      <Value key={val.value}>
                          {val.label} {val.id} - {residualFormatted}
                          <XButton name={val.value} onClick={handleRemoveValue}>
                              ✕
                          </XButton>
                      </Value>
                  )
                })
            }
            {value.length > 0 &&
                <LabelWarning>
                    {isOptionDisabled() ? t("ecommerce.giftcard.giftCardAmountCoverOrderTotal") : t("ecommerce.giftcard.giftCardAmountNotCoverOrderTotal")}
                </LabelWarning>
            }
        </ValuesContainer>
    </div>
  );
};

export default SelectMultiOptions;
