import React from "react";
import ReactDOM from "react-dom";
import {calls, PROJECT_SETTINGS} from "../../core/APIUtils";
import {RESTContext} from "../../core/rest.context";
import {SliderItems} from "../sliders/slideritems.component";
import {LabelContext} from "../../core/label.context";
import {
    getBundlePrice,
    getAttributeFromProductProjection,
    isBundleAvailable,
    removeBundleCancelledFromProductProjection
} from "./utils"

export const RelatedBundles = () => {
    const container = document.getElementById("reactPlaceholder_relatedBundles");
    const [items, setItems] = React.useState<unknown>([]);
    const {ajax$} = React.useContext(RESTContext);
    const {t} = React.useContext(LabelContext);

    React.useEffect(() => {
        if (container) {
            const dataId = container?.getAttribute('data-id') || ""
            ajax$(calls.Project.getProductProjection('productType.id:"' + PROJECT_SETTINGS['bundleId'] + '"&expand=masterVariant.attributes[*].value[*]'))
                .subscribe(
                    res => {
                        //rimuovo i bundle che contengono prodotti non piu presenti su elastic e quindi cancellati
                        const filteredBundles = removeBundleCancelledFromProductProjection(res.results)

                        let bundleToShow: any [] = []
                        filteredBundles.forEach((bundle: any) => {

                            let skuInside = getAttributeFromProductProjection(bundle, "bundle")
                            bundle.available = isBundleAvailable(bundle)
                            let prices = getBundlePrice(bundle)
                            bundle.price = prices.productsPriceFormatted
                            bundle.discountPercentValue = prices.discountPercentValue

                            let found: boolean = false
                            skuInside.forEach((_el: any) => {
                                //show bundle on listing page
                                if (dataId === 'listing-data') {
                                    let productList: any [] = []
                                    document.querySelectorAll('.js_product-link').forEach((productLink) => {
                                        productList.push(productLink.getAttribute('data-product-id'));
                                    })
                                    if (productList.indexOf(_el.obj.key) !== -1 && !found) {
                                        bundleToShow.push(bundle);
                                        found = true
                                    }
                                    //show bundle on product detail page
                                } else {
                                    if (dataId !== "" && _el.obj.key === dataId) {
                                        bundleToShow.push(bundle)
                                    }
                                }
                            })
                        })
                        setItems(bundleToShow)
                    }
                )
        }
    }, [container]);

    if (!container || !items)
        return null;
    else {
        return ReactDOM.createPortal(
            <SliderItems items={items} title={t('ecommerce.common.relatedBundles.title')} type={"bundle"}/>,
            container
        )
    }
};