import { StyledProperty } from "./property.style";

/**
 * PROPERTY
 *
 *
 * @param {string} label - property title
 * @param {object} item - property value
 *
 *
 */

export const Property = (props: any) => {
  const { item, label, children } = props;
	
  return <>
    {item && (<StyledProperty>
      <div className="field">
        <label><b>{label}:</b></label> <span>{item}</span>
        {children}
      </div>
    </StyledProperty>)}
  </>;
};
