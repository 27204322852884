import styled from "styled-components";

export const StyledOrder = styled.div`
.order__item {
  margin-bottom: 20px;
}
.order__item .item__body {
  overflow: hidden;
  max-height: 0;
  transition: all linear 0.5s;
}

.order__item.item-active .item__body {
  max-height: none;
  transition: all linear 0.5s 0.3s;
}
.orderState{
    color:var(--color-alert);
    text-transform: uppercase;
    font-weight: 500;
    display: inline;
}

`;