import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { StyledPages } from "./commonPages.style";
import { Box } from "../components/box/box.component";
import { Button } from "../components/button/button.component";
import { InputElement } from "../components/input/input.component";


export const ExamplePage = (props: any) => {
    const container = document.getElementById("example");


    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
                <StyledPages>
                    <HeaderTitle title="Example" message="Pagina esempio per definizione stili" ></HeaderTitle>
                    <div className="container">
                        <div className="row justify-content-center styled">
                            <div className="col-md-6">
                                <h1>H1</h1>
                                <h2>H2</h2>
                                <h3>H3</h3>

                                <h1 className="title">H1.title</h1>
                                <h2 className="title">H2.title</h2>
                                <h2 className="title_1">H2.title_1</h2>
                                <h2 className="title_2">H2.title_2</h2>
                                <h2 className="title_3">H2.title_3</h2>
                                <h1 className="title_sm">H1.title_sm</h1>
                                <h2 className="title_sm">H2.title_sm</h2>
                                <h3 className="title_sm">H3.title_sm</h3>
                                <h2 className="title_md">H2.title_md</h2>
                                <h2 className="title-md">H2.title-md</h2>
                                <h2 className="title_lg">H2.title_lg</h2>
                                <h2 className="subTitle_2">H2.subTitle_2</h2>
                                <div className="subTitle_2 text-center">subTitle_2 text-center</div>
                                <InputElement label="name" errors="messaggio errore"></InputElement>
                                <Button className="primary mt-4" label="Accedi" type="submit" />
                                <Button className="secondary mt-4" label="Accedi" type="submit" />
                                <Button className="simple mt-4" label="simple" type="submit" icon="fas fa-pencil-alt" />

                            </div>
                            <div className="col-md-6">
                                <Box className="secondary">
                                    Box secondary
                                </Box>
                            </div>

                        </div>
                    </div>
                </StyledPages>
            </>
            , container
        )
};