import * as React from "react";
import { GeneralContext } from "../../core/general.context";
import { LabelContext } from "../../core/label.context";
import { Button } from "../button/button.component";
import { InputElement } from "../input/input.component";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { UserContext } from "../../core/user.context";
import { inputMaxLength } from "../../common/constants";
import {BRX_LOCALE, PROJECT_COUNTRY} from "../../core/APIUtils";


export const EditUserData = (props: any) => {
  const { callback } = props;
  const { userData } = React.useContext(GeneralContext);
  const { updateCustomer } = React.useContext(UserContext);
  const { t } = React.useContext(LabelContext);
  let requiredError = t("ecommerce.form-messagge.required")
  let inputMaxLengthError = t("ecommerce.form-messagge.error-input-max-length")
  let [profiling, setProfiling] = React.useState(userData?.custom?.fields?.consentProfiling || false)


  const schema = yup.object().shape({
    name: yup.string().max(inputMaxLength, inputMaxLengthError).required(requiredError),
    surname: yup.string().max(inputMaxLength, inputMaxLengthError).required(requiredError),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).when({
      is: () => (!["NL"].includes(PROJECT_COUNTRY)),
      then: yup.date().required(requiredError)
    }),
    sex: yup.string().max(inputMaxLength, inputMaxLengthError).nullable().required(requiredError),
    profiling: yup.bool().when({
      is: () => ["DE"].includes(PROJECT_COUNTRY),
      then: yup.bool().oneOf([true, false])
    }),
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { sex: userData.salutation, birthday: userData.dateOfBirth, name: userData.firstName, surname: userData.lastName, profiling: userData?.custom?.fields?.consentProfiling },
    mode: "all",
    criteriaMode: "all",
    reValidateMode: "onChange"
  });

  const onSubmit: SubmitHandler<any> = data => {
    updateCustomer(data);
    callback(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <InputElement externalRegister={register("name")} className="col-md-9 col-12" label={t("ecommerce.signup.firsName")} placeholder={t("ecommerce.signup.firsName")} id="name" errors={errors.name?.message}></InputElement>
        <InputElement externalRegister={register("surname")} name="surname" className="col-md-9 col-12" label={t("ecommerce.signup.lastName")} placeholder={t("ecommerce.signup.lastName")} id="surname" errors={errors.surname?.message}></InputElement>
        <InputElement externalRegister={register("birthday")} name="birthday" className="col-md-9 col-12" type="date" label={t("ecommerce.signup.birthday")} id="birthday" errors={errors.birthday?.message} ></InputElement>

        <div className="col-12">

          <label className="label-for-radio">{t("ecommerce.signup.gender")}</label>
          <div className="radioGroup__generic">
            <div className="radioButton">
              <input {...register("sex", { required: true })} type="radio" id="male" name="sex" value="male" />
              <label htmlFor="male">{t("ecommerce.signup.male")}</label>
            </div>
            <div className="radioButton">
              <input {...register("sex", { required: true })} type="radio" id="female" name="sex" value="female" />
              <label htmlFor="female">{t("ecommerce.signup.female")}</label>
            </div>
            {["sv_SE", "fi_FI", "nb_NO", "da_DK", "de_DE", "nl_NL"].includes(BRX_LOCALE) &&
              <div className="radioButton">
                <input {...register("sex", { required: true })} type="radio" id="other" name="sex" value="other" />
                <label htmlFor="other">{t("ecommerce.signup.other")}</label>
              </div>
            }
            {errors.sex?.message && <p className="message--alert small">{errors.sex?.message}</p>}
          </div>

          {["DE"].includes(PROJECT_COUNTRY) && (
              <>
                <div className="checkboxButton">
                  <>
                    <input {...register("profiling", { required: true })} checked={profiling} type="checkbox" id="profiling" name="profiling" onChange={() => {setProfiling(!profiling); setValue("profiling", !profiling)}} />
                    <label htmlFor="profiling">{t("ecommerce.signup.profiling-flag")}</label>
                  </>
                </div>
              </>
          )}


          <div className="row">
            <div className="col-md-3 offset-md-1">
              <Button className="primary actionForm" label={t("ecommerce.common.save")} type="submit"></Button>
            </div>
            <div className="col-md-3 offset-md-1">
              <Button className="secondary actionForm " label={t("ecommerce.common.cancel")} type="button" onClick={() => callback(false)}></Button>
            </div>
          </div>

        </div>
      </div>
    </form>

  )
};