
/**
 * CUSTOMER CARE INFO BOXES
 *
 *
 * @param {string} display - by default the boxes are shown in column
 * @param {string} display=row - add cssclass layout-row
 * 
 * 
 */

import * as React from "react";
import { LabelContext } from "../../core/label.context";
import { BRX_BASE_URL } from "../../core/APIUtils";


export const CustomercareInfoCompressed = (props: any) => {
    const { t } = React.useContext(LabelContext);
    let layoutType = props.display ? ("layout-" + props.display) : "";
    let componentClassName = "customerCareInfo " + layoutType;

    let ecommerce_customerCare_info_faq = t("ecommerce.customerCare.info.faq");
    let ecommerce_customerCare_info_telephone = t("ecommerce.customerCare.info.telephone");
    let ecommerce_customerCare_info_mail = t("ecommerce.customerCare.info.mail");
    let ecommerce_customerCare_url_mail = t("ecommerce.customerCare.url.mail");
    let ecommerce_customerCare_url_faq = t("ecommerce.customerCare.url.faq");

    if (!ecommerce_customerCare_info_faq && !ecommerce_customerCare_info_telephone && !ecommerce_customerCare_info_mail)
        return null;
    else {
        return <>
            <div className={componentClassName}>

                <div className="accordion accordion-Ccare d-block d-sm-block d-md-none" id="accordionCcare">
                    {ecommerce_customerCare_info_faq &&
                    <>
                        <div className="customerCareInfo-box-faq">
                            
                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className="testo" dangerouslySetInnerHTML={{ __html: t("ecommerce.customerCare.title.info.faq") }} />
                                <span className="icon"></span>
                            </button>
                            
                        </div>
                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionCcare">
                            <div className="card-body">
                                <span className="testo" dangerouslySetInnerHTML={{ __html: ecommerce_customerCare_info_faq }} />
                            </div>
                        </div>
                    </>   
                    }


                    {ecommerce_customerCare_info_mail &&
                    <>
                        <div className="customerCareInfo-box-mail">
                            
                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                                <span className="testo" dangerouslySetInnerHTML={{ __html: t("ecommerce.customerCare.title.info.mail") }} />
                                <span className="icon"></span>
                            </button>
                            
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionCcare">
                            <div className="card-body">
                                <span className="testo" dangerouslySetInnerHTML={{  __html: ecommerce_customerCare_info_mail }} />
                            </div>
                        </div>
                    </>   
                    }

                </div>
                <div className="d-none d-sm-none d-md-block customerCareInfo-link-wrap">
                    {ecommerce_customerCare_url_faq &&
                        <a className="customerCareInfo-link-faq" href={BRX_BASE_URL + ecommerce_customerCare_url_faq}>{t("ecommerce.customerCare.link.faq")}</a>
                    }
                    {ecommerce_customerCare_url_mail &&
                        <a className="customerCareInfo-link-mail" href={BRX_BASE_URL + ecommerce_customerCare_url_mail}>{t("ecommerce.customerCare.link.mail")}</a>
                    }
                </div>
            </div>
        </>
    }


};

