import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { LabelContext } from "../core/label.context";
import { StyledPages } from "./commonPages.style";
import {useEffect} from "react"
import {BRX_LOCALE, calls, PROJECT_KEY} from "../core/APIUtils"
import {RESTContext} from "../core/rest.context"

export const PayFastConfirmationPage = (_props: any) => {
    const container = document.getElementById("reactPlaceholder_payfast-confirmation");
    const { ajax$, ajaxMiddleware$, token } = React.useContext(RESTContext);
    const [payFastOrder, setPayFastOrder] = React.useState()

    useEffect(() => {
        let params: URLSearchParams = new URLSearchParams(window.location.search)
        if (params?.get("pfcid")) {
            const pfcid: string = params?.get("pfcid") || ""

            const body = {
                cartId: pfcid,
                projectKey: PROJECT_KEY,
                authToken: token?.access_token
            }
            ajaxMiddleware$(calls.Project.getOrderFromCartId(), undefined, body)
                .subscribe(
                    res => {
                        if(res.code === 200 && res.obj){
                            setPayFastOrder(res.obj)
                        }else {
                            ajax$(calls.Project.getMyCartById(pfcid))
                                .subscribe(
                                    res => {
                                        setPayFastOrder(res);
                                    }
                                );
                        }
                    }
                );


        }
    }, [])

    if (!container)
        return null;
    else {
        return ReactDOM.createPortal(
            <>
                {!payFastOrder && <div className="isLoading" />}
                {payFastOrder && <ThankYouMessage currentOrder={payFastOrder} />}
            </>
            , container
        )
    }
};

const ThankYouMessage = (props: any) => {
    const { t } = React.useContext(LabelContext)
    let order = props.currentOrder
    let messageOrderNumberConfirmed: string = ""

    if (order.type === "Order") {
        messageOrderNumberConfirmed = t("ecommerce.checkout.order-confirmed-message-with-order-number")
        messageOrderNumberConfirmed = messageOrderNumberConfirmed.replace(
            "[ORDER_NUMBER]",
            "<b>" + order.orderNumber + "</b>"
        )
    }

    return (
        <StyledPages>
            <HeaderTitle/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        {order.type === "Order" && <h1>{t("ecommerce.checkout.order-confirmed-page-title")}</h1>}
                        {order.type === "Cart" && <h1>{t("ecommerce.checkout.temporary-order-confirmed-page-title")}</h1>}
                        <br />
                        {order.type === "Order" && <p dangerouslySetInnerHTML={{__html: messageOrderNumberConfirmed}}/>}
                        {order.type === "Order" && <p>{t("ecommerce.checkout.order-confirmed-title-shipping")}</p>}
                        {order.type === "Cart" && <p>{t("ecommerce.checkout.temporary-order-confirmed-title-shipping")}</p>}

                        {BRX_LOCALE === "en_ZA" ?
                            <p>
                                <b>
                                    {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                                    , {order.shippingAddress.streetNumber} {order.shippingAddress.streetName}
                                    {order.shippingAddress?.custom?.fields?.suburb ? `, ${order.shippingAddress.custom.fields.suburb}` : ''}
                                    , {order.shippingAddress.city}, {order.shippingAddress.postalCode}
                                </b>
                            </p>
                            :
                            <p>
                                <b>
                                    {order.shippingAddress.firstName} {order.shippingAddress.lastName}, {order.shippingAddress.streetName} {" "}
                                    {order.shippingAddress.streetNumber}, {order.shippingAddress.postalCode}, {order.shippingAddress.city} {" "}
                                    ({order.shippingAddress.region})
                                </b>
                            </p>
                        }


                        {order.type === "Order" && (
                            <>
                                <p dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.order-confirmed-message-in-page-1")}}/>
                                <p dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.order-confirmed-message-in-page-2")}}/>
                            </>
                        )}
                        {order.type === "Cart" && (
                            <>
                                <p dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.temporary-order-confirmed-message-in-page-1"),}}/>
                                <p dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.temporary-order-confirmed-message-in-page-2"),}}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </StyledPages>
    )
}