import React from "react";
import {ICart} from "../common/cart.models";
import {IGeneralContext} from "../common/common.models";
import {IUserData} from "../common/user.models";


export const GeneralContext = React.createContext<IGeneralContext>(null!);

export const GeneralContextComponent = (props: any) => {

    /* === DATI === */
    const [userData, setUserData] = React.useState<IUserData>(null!);
    const [orders, setOrders] = React.useState(null!);
    const [cart, setCart] = React.useState<ICart>(null!);
    const [currentOrder, setCurrentOrder] = React.useState<any>(null!);
    const [forgotPasswordSent, setForgotPasswordSent] = React.useState(false);
    const [giftcardActivated, setGiftcardActivated] = React.useState("NO");
    const [resetPasswordState, setResetPasswordState] = React.useState(0);
    const [orderCreated, setOrderCreated] = React.useState(null!);
    const [wishlist, setWishlist] = React.useState<ICart>(null!);
    const [shippingMethods, setShippingMethods] = React.useState(null!);
    const [temporaryOrder, setTemporaryOrder] = React.useState(null!);
    const [klarna, setKlarna] = React.useState(null!);
    const [payU, setPayU] = React.useState(null!);
    const [openBackInStock, setOpenBackInStock] = React.useState(null)
    const [younited, setYounited] = React.useState(null!);
    const [younitedUri, setYounitedUri] = React.useState<any>(null!);
    const [giftCards, setGiftCards] = React.useState(null!);
    const [buyBox, setBuyBox] = React.useState(null!);
    const [giftCardsMovements, setGiftcardMovements] = React.useState({});
    const [selectedShippingMethodDescription, setSelectedShippingMethodDescription] = React.useState("");
    const [invoiceValue, setInvoiceValue] = React.useState(false)
    const [payFastSignature, setPayFastSignature] = React.useState<string>("")
    const [symbols, setSymbols] = React.useState(null);


    // ..

    return (
        <GeneralContext.Provider value={{
            userData,
            setUserData,
            orders,
            setOrders,
            cart,
            setCart,
            currentOrder,
            setCurrentOrder,
            forgotPasswordSent,
            setForgotPasswordSent,
            resetPasswordState,
            setResetPasswordState,
            orderCreated,
            setOrderCreated,
            wishlist,
            setWishlist,
            shippingMethods,
            setShippingMethods,
            temporaryOrder,
            setTemporaryOrder,
            klarna,
            setKlarna,
            setOpenBackInStock,
            openBackInStock, 
            payU,
            setPayU,
            giftCards,
            setGiftCards,
            giftcardActivated,
            setGiftcardActivated,
            giftCardsMovements,
            setGiftcardMovements,
            younited,
            setYounited,
            younitedUri,
            setYounitedUri,
            selectedShippingMethodDescription,
            setSelectedShippingMethodDescription,
            buyBox,
            setBuyBox,
            invoiceValue,
            setInvoiceValue,
            payFastSignature,
            setPayFastSignature,
            symbols,
            setSymbols
        }}>
            {props.children}
        </GeneralContext.Provider>
    );
};
