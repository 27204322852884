import Cookies from "universal-cookie"
import { EAjaxMethods, ICredentials } from "../common/common.models"

const root: any = document.getElementById("root")
const dataEnvironment = root.getAttribute("data-environment") || "dev-localhost"
const dataChannel = root.getAttribute("data-channel") || "smeg-italy-it"
const CONFIG = JSON.parse(process.env.REACT_APP_CONFIG || "")

export const PRODUCT_WITH_LIMITED_QTA_ENABLED = root.getAttribute('data-item-limited-qta') || null
export const SHOW_ENERGY_LABEL = root.getAttribute('data-show-energy-label') || null
export const BACK_IN_STOCK_LISTING_ENABLED = root.getAttribute('data-back-in-stock-listing-enabled') || false
export const LABELS = root.getAttribute("data-labels") || null
export const SYMBOLS = root.getAttribute("data-symbols") || null
export const BasicAuthPassword = CONFIG[dataEnvironment][dataChannel].commercetools.authPassword
export const AUTH_URL = CONFIG[dataEnvironment][dataChannel].commercetools.authUrl
export const HOST = CONFIG[dataEnvironment][dataChannel].commercetools.hostUrl
export const MIDDLEWARE_HOST = CONFIG[dataEnvironment][dataChannel].middleware.apiHost || ""
export const PROJECT_KEY = CONFIG[dataEnvironment][dataChannel].commercetools.projectKey
export const PROJECT_CURRENCY = CONFIG[dataEnvironment][dataChannel].commercetools.projectCurrency
export const PROJECT_COUNTRY: string = CONFIG[dataEnvironment][dataChannel].commercetools.projectCountry
export const PROJECT_LANGUAGE = CONFIG[dataEnvironment][dataChannel].commercetools.projectLanguage
export const PROJECT_COUNTRIES = CONFIG["project-settings"][PROJECT_KEY].countries || []
export const MAX_QTY_FOR_PRODUCT = CONFIG["project-settings"][PROJECT_KEY].maxQtyForProduct || 100
export const MAX_GIFTCARDS_IN_CART = CONFIG["project-settings"][PROJECT_KEY].maxGiftCardsInCart || 3
export const IS_BACK_IN_STOCK_ACTIVE = CONFIG["project-settings"][PROJECT_KEY].backInStockActive || false
export const IS_GIFT_CARD_ACTIVE = CONFIG["project-settings"][PROJECT_KEY].isGiftcardActive || false
export const FOOTER_PARTNERSHIP_LOGOS = CONFIG["project-settings"][PROJECT_KEY].footerPartnershipLogos || []
export const FOOTER_PAYMENT_LOGOS = CONFIG["project-settings"][PROJECT_KEY].footerPaymentLogos || []
export const PAYMENT_METHODS = CONFIG["project-settings"][PROJECT_KEY].paymentMethods || []
export const PAYPAL_CLIENT_ID = CONFIG["project-settings"][PROJECT_KEY].paypalClientId || ""

export const MIDDLEWARE_API_HOST = CONFIG[dataEnvironment][dataChannel].middleware.apiHost;
export const AUTH_PASSWORD = CONFIG[dataEnvironment][dataChannel].middleware.authPassword;
export const PROJECT_SETTINGS = CONFIG["project-settings"][PROJECT_KEY]
export const BRX_API_HOST = CONFIG[dataEnvironment][dataChannel].brx.apiHost
export const BRX_LOCALE = CONFIG[dataEnvironment][dataChannel].brx.locale
export const BRX_BASE_URL = CONFIG[dataEnvironment][dataChannel].brx.baseUrl
export const BRX_ASSETS_URL = CONFIG[dataEnvironment][dataChannel].brx.assetsUrl
export const BRX_PRODUCTS_URL = `${BRX_BASE_URL}/${PROJECT_SETTINGS?.productsPath?.[PROJECT_LANGUAGE]}/`
export const BRX_BUNDLE_URL = `${BRX_BASE_URL}/${PROJECT_SETTINGS?.bundlePath}/`
export const COOKIE_PATH = CONFIG[dataEnvironment][dataChannel].brx.cookiePath || "/"
export const COOKIE_PATH_ALTERNATIVE = CONFIG[dataEnvironment][dataChannel].brx.cookiePathAlternative
export const COOKIES = new Cookies()
export const PAYFAST_MERCHANT_KEY = CONFIG[dataEnvironment][dataChannel].payfast?.merchant_key || ""
export const PAYFAST_MERCHANT_ID = CONFIG[dataEnvironment][dataChannel].payfast?.merchant_id || ""
export const PAYFAST_NOTIFY_URL = CONFIG[dataEnvironment][dataChannel].payfast?.notify_url || ""
export const PAYFAST_CANCEL_URL = CONFIG[dataEnvironment][dataChannel].payfast?.cancel_url || ""
export const PAYFAST_RETURN_URL = CONFIG[dataEnvironment][dataChannel].payfast?.return_url || ""
export const PAYFAST_BASE_URL = CONFIG[dataEnvironment][dataChannel].payfast?.pay_fast_base_url || ""
export const ADYEN_CLIENT_KEY = CONFIG[dataEnvironment][dataChannel].adyen.client_key
export const ADYEN_ENVIRONMENT = CONFIG[dataEnvironment][dataChannel].adyen.adyen_environment
export const ADYEN_PAYMENTMETHODS = CONFIG[dataEnvironment][dataChannel].adyen.paymentMethods
export const ADYEN_PAYMENTMETHODSCONFIGURATION = CONFIG[dataEnvironment][dataChannel].adyen.paymentMethodsConfiguration || {}
export const ADYEN_ENABLEDPAYMENTMETHODS = CONFIG[dataEnvironment][dataChannel].adyen.enabledPaymentMethods || []
export const ADYEN_MERCHANTACCOUNT = CONFIG[dataEnvironment][dataChannel].adyen.merchantAccount
export const ADYEN_RETURNURL = CONFIG[dataEnvironment][dataChannel].adyen.returnUrl
export const ADYEN_LOCALE = BRX_LOCALE
export const ENVIRONMENT = dataEnvironment

export const calls = {
  Authorization: {
    getToken: () => ({ url: `${AUTH_URL}/oauth/token?grant_type=client_credentials`, method: EAjaxMethods.POST }),
    getAnonymousToken: () => ({
      url: `${AUTH_URL}/oauth/${PROJECT_KEY}/anonymous/token?grant_type=client_credentials`,
      method: EAjaxMethods.POST,
    }),
    getCustomerToken: (credentials: ICredentials) => ({
      url: `${AUTH_URL}/oauth/${PROJECT_KEY}/customers/token?grant_type=password&username=${credentials.username}&password=${credentials.password}`,
      method: EAjaxMethods.POST,
    }),
    refreshToken: (token: string) => ({
      url: `${AUTH_URL}/oauth/token?grant_type=refresh_token&refresh_token=${token}`,
      method: EAjaxMethods.POST,
    }),
  },
  Project: {
    signup: () => ({ url: `${HOST}/${PROJECT_KEY}/me/signup`, method: EAjaxMethods.POST }),
    login: () => ({ url: `${HOST}/${PROJECT_KEY}/me/login`, method: EAjaxMethods.POST }),
    getCustomerDetails: () => ({ url: `${HOST}/${PROJECT_KEY}/me`, method: EAjaxMethods.GET }),
    updateCustomer: () => ({ url: `${HOST}/${PROJECT_KEY}/me`, method: EAjaxMethods.POST }),
    changePwdCustomer: () => ({ url: `${HOST}/${PROJECT_KEY}/me/password`, method: EAjaxMethods.POST }),
    getActiveCart: () => ({
      url: `${HOST}/${PROJECT_KEY}/me/active-cart?expand=lineItems[*].variant.attributes[*].value[*]&expand=discountCodes[*].discountCode.id&expand=lineItems[*].productType&expand=paymentInfo.payments[*]&expand=lineItems[*].discountedPrice.includedDiscounts[*].discount.id`,
      method: EAjaxMethods.GET,
    }),
    createCart: () => ({ url: `${HOST}/${PROJECT_KEY}/me/carts`, method: EAjaxMethods.POST }),

    getWishlist: () => ({
      url: `${HOST}/${PROJECT_KEY}/me/shopping-lists?expand=lineItems[*].variant`,
      method: EAjaxMethods.GET,
    }),
    createWishlist: () => ({ url: `${HOST}/${PROJECT_KEY}/me/shopping-lists`, method: EAjaxMethods.POST }),
    updateWishlist: (wishlist_id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/me/shopping-lists/${wishlist_id}?expand=lineItems[*].variant`,
      method: EAjaxMethods.POST,
    }),

    updateCart: (cart_id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/me/carts/${cart_id}?expand=lineItems[*].variant.attributes[*].value[*]&expand=discountCodes[*].discountCode.id&expand=lineItems[*].productType&expand=lineItems[*].discountedPrice.includedDiscounts[*].discount.id`,
      method: EAjaxMethods.POST,
    }),

    getMyCartById: (cart_id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/me/carts/${cart_id}?expand=paymentInfo.payments[*]&expand=lineItems[*].variant.attributes[*].value[*]&expand=discountCodes[*].discountCode.id&expand=lineItems[*].productType&expand=lineItems[*].discountedPrice.includedDiscounts[*].discount.id`,
      method: EAjaxMethods.GET,
    }),

    shippingMethods: (cart_id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/shipping-methods/matching-cart?cartId=${cart_id}`,
      method: EAjaxMethods.GET,
    }),
    getShippingMethod: (id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/shipping-methods/${id}`,
      method: EAjaxMethods.GET,
    }),

    loadLabels: () => ({
      url: `${BRX_API_HOST}/v1/label/${BRX_LOCALE}?bundles=ecommerce.common,ecommerce.login,ecommerce.checkout,ecommerce.customerCare,ecommerce.forgotPassword,ecommerce.resetPassword,ecommerce.orders,ecommerce.shippingAddress,ecommerce.myaccount,ecommerce.personalinfo,ecommerce.billingAddress,ecommerce.formAddress,ecommerce.pages,ecommerce.error,ecommerce.signup,ecommerce.form-messagge,ecommerce.alternative_pop_up,ecommerce.listing,essentials.color.filters,ecommerce.younited,essentials.products,ecommerce.giftcard,iconlabel`,
      method: EAjaxMethods.GET,
    }),
    getUserCountry: () => ({
      url: `https://www.smeg.com/restservices/v1/geolocation/check-country`,
      method: EAjaxMethods.GET,
    }),
    createPayment: () => ({ url: `${HOST}/${PROJECT_KEY}/me/payments`, method: EAjaxMethods.POST }),
    updatePayment: (payment_id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/me/payments/${payment_id}`,
      method: EAjaxMethods.POST,
    }),
    createOrder: () => ({
      url: `${HOST}/${PROJECT_KEY}/me/orders?expand=paymentInfo.payments[*]&expand=cart&expand=discountCodes[*].discountCode.id`,
      method: EAjaxMethods.POST,
    }),
    getProduct: (key: string) => ({
      url: `${HOST}/${PROJECT_KEY}/product-projections/key=${key}?expand=masterVariant.attributes[*].value[*]&expand=productType`,
      method: EAjaxMethods.GET,
    }),
    getProductById: (id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/product-projections/${id}`,
      method: EAjaxMethods.GET,
    }),
    getProductProjection: (filter: string) => ({
      url: `${HOST}/${PROJECT_KEY}/product-projections/search?limit=100&filter=${filter}`,
      method: EAjaxMethods.GET,
    }),
    getProductProjectionByQueryString: (query: string) => ({
      url: `${HOST}/${PROJECT_KEY}/product-projections/search?${query}`,
      method: EAjaxMethods.GET,
    }),
    queryProductProjection: (where: string) => ({
      url: `${HOST}/${PROJECT_KEY}/product-projections/?limit=50&where=${where}`,
      method: EAjaxMethods.GET,
    }),
    getOrders: () => ({
      url: `${HOST}/${PROJECT_KEY}/me/orders?expand=lineItems[*].productType&sort=createdAt%20desc`,
      method: EAjaxMethods.GET,
    }),
    getOrder: (order_id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/me/orders/${order_id}?expand=paymentInfo.payments[*]&expand=discountCodes[*].discountCode.id`,
      method: EAjaxMethods.GET,
    }),
    getCategory: (category_id: string) => ({
      url: `${HOST}/${PROJECT_KEY}/categories/${category_id}`,
      method: EAjaxMethods.GET,
    }),
    getCategoryByKey: (category_key: string) => ({
      url: `${HOST}/${PROJECT_KEY}/categories/key=${category_key}`,
      method: EAjaxMethods.GET,
    }),
    queryCategories: (where: string) => ({
      url: `${HOST}/${PROJECT_KEY}/categories?where=${where}`,
      method: EAjaxMethods.GET,
    }),

    //Password
    forgotPassword: () => ({ url: `${BRX_API_HOST}/v1/ct/customer/password-token`, method: EAjaxMethods.POST }),
    resetPassword: () => ({ url: `${BRX_API_HOST}/v1/ct/customer/password-reset`, method: EAjaxMethods.POST }),
    sendVerifyMail: () => ({ url: `${BRX_API_HOST}/v1/ct/customer/verify-email`, method: EAjaxMethods.POST }),
    getElasticSearchInfo: () => ({ url: `${BRX_API_HOST}/v1/beans/es-products-info`, method: EAjaxMethods.POST }),
    verifyMyCostumerEmail: () => ({ url: `${HOST}/${PROJECT_KEY}/me/email/confirm`, method: EAjaxMethods.POST }),

    //Middleware
    backInStock: () => ({ url: `${MIDDLEWARE_HOST}/backinstock`, method: EAjaxMethods.POST }),
    klarnaStart: () => ({ url: `${MIDDLEWARE_HOST}/payment/klarna/start`, method: EAjaxMethods.POST }),
    klarnaCreate: () => ({ url: `${MIDDLEWARE_HOST}/payment/klarna/create`, method: EAjaxMethods.POST }),
    payUStart: () => ({ url: `${MIDDLEWARE_HOST}/payment/payu/start`, method: EAjaxMethods.POST }),
    payUCreate: () => ({ url: `${MIDDLEWARE_HOST}/payment/payu/create`, method: EAjaxMethods.POST }),
    younitedOffers: () => ({ url: `${MIDDLEWARE_HOST}/payment/younited/offers`, method: EAjaxMethods.POST }),
    younitedInit: () => ({ url: `${MIDDLEWARE_HOST}/payment/younited/init`, method: EAjaxMethods.POST }),
    younitedConfirm: () => ({ url: `${MIDDLEWARE_HOST}/payment/younited/confirm`, method: EAjaxMethods.POST }),
    payPalCreate: () => ({ url: `${MIDDLEWARE_HOST}/payment/paypal/create`, method: EAjaxMethods.POST }),
    payPalCapture: () => ({ url: `${MIDDLEWARE_HOST}/payment/paypal/capture`, method: EAjaxMethods.POST }),
    buyBoxInit: () => ({ url: `${MIDDLEWARE_HOST}/payment/buybox/init`, method: EAjaxMethods.POST }),
    buyBoxConfirm: () => ({url: `${MIDDLEWARE_HOST}/payment/buybox/confirm`, method: EAjaxMethods.POST }),
    payFastGenerateSignature: () => ({ url: `${MIDDLEWARE_HOST}/payment/payfast/signature`, method: EAjaxMethods.POST }),
    payFastProcessPayment: () => ({url: `https://sandbox.payfast.co.za/eng/process`, method: EAjaxMethods.POST}),
    //url di prod: scommentare payFastProcessPayment: () => ({url: `${MIDDLEWARE_HOST}/CT/retrieveOrder`, method: EAjaxMethods.POST}),
    getOrderFromCartId: () => ({url: `${MIDDLEWARE_HOST}/CT/retrieveOrder`, method: EAjaxMethods.POST}),



    //Marketing
    awinOrderCreated: (advertiserId: string, totalAmount: string, channel: string, commissionGroup: string, currencyCode: string, voucher_code: string, orderReference: string, isTest: string, awc: string) => ({
      url: `https://www.awin1.com/sread.php?tt=ss&tv=2&merchant=${advertiserId}&amount=${totalAmount}&ch=${channel}&parts=${commissionGroup}:${totalAmount}&vc=${voucher_code}&cr=${currencyCode}&ref=${orderReference}&testmode=${isTest}&cks=${awc}`,
      method: EAjaxMethods.GET,
    }),

    getGiftCards: (authToken: string, onlyActive: boolean) => ({ url: `${MIDDLEWARE_API_HOST}/giftCards?authToken=${authToken}&projectKey=${PROJECT_KEY}&onlyActive=${onlyActive}`, method: EAjaxMethods.GET }),
    applyGiftCardToCart: () => ({ url: `${MIDDLEWARE_API_HOST}/giftCards/applyGiftCardToCart`, method: EAjaxMethods.POST }),
    activateGiftCard: () => ({ url: `${MIDDLEWARE_API_HOST}/giftCards/activateGiftCard`, method: EAjaxMethods.POST }),
    removeGiftCardFromCart: () => ({ url: `${MIDDLEWARE_API_HOST}/giftCards/removeGiftCardFromCart`, method: EAjaxMethods.POST }),
    removeAllGiftCardsFromCart: () => ({ url: `${MIDDLEWARE_API_HOST}/giftCards/removeAllGiftCardsFromCart`, method: EAjaxMethods.POST }),
    getCustomerGiftCardMovements: (giftcardid: string) => ({ url: `${MIDDLEWARE_API_HOST}/giftcards/${giftcardid}/customer/movements`, method: EAjaxMethods.POST }),
  },
}
