import styled from "styled-components";

export const StyledYounited = styled.div`

  .bg-wrap {
    text-align: left;
    background: #f8f8f8;
    padding: 15px;
    margin: 0 15px;
  }
  .ec_btn_primary.cmp_button {
    margin-bottom: 2px;
  }
  h2 {
    font-size: 20px !important;
    padding-bottom: 0;

  }
  p {
    line-height: normal;
    font-size: 14px;
  }
  ul {
    padding: 0;
    list-style: none;
    line-height: 1.3rem;
    font-size: 14px;
  }
  label {
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }

  .btn-wrap {
    display: flex;
    align-items: center;

    svg {
      height: 30px;
      margin-left: -10px;
      width: auto;
      margin-top: -2px;
    }
  }

  .btn-younited-modal {
    border: 0;
    padding: 0;
    background: none;
    margin: 0;
    width: fit-content;
    color: #17120f;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    text-decoration: underline;
    filter: blur(0);
    cursor: pointer;
    &.nopointer{
      text-decoration: none;
      cursor: default;
    }
  }

  .ec_btn_primary.cmp_button {
    span {
      display: block;
    }
    .btn-icon {
      height: 15px;

      svg {
        height: 28px;
        margin-top: -3px;
      }
    }
  }
}
`;

export const StyledYounitedModal = styled.div` {
  h2 {
    font-size: 20px;

  }
  p {
    line-height: normal;
    font-size: 14px;
  }
  ul {
    padding: 0;
    list-style: none;
    line-height: 1.3rem;
  }
  label {
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }
  .bg-wrap {
    text-align: left;
    padding: 10px;

    svg {
      height: 40px;
      width: auto;
    }
  }
  .ec_btn_primary.cmp_button {
    margin-bottom: 2px;
  }

  .btn-wrap {
    display: flex;
    align-items: center;

    svg {
      height: 30px;
      margin-left: -10px;
      width: auto;
      margin-top: -2px;
    }
  }

  .btn-younited-modal {
    border: 0;
    padding: 0;
    background: none;
    margin: 0;
    width: fit-content;
    font-size: 12px;
    cursor: pointer;
  }

  }
}

  
`;