import * as React from "react"
import Autocomplete from "react-google-autocomplete"
import { GeneralContext } from "../../../core/general.context"
import { Property } from "../property/property.component"
import { StyledAddress } from "./address.style"
import { InputElement } from "../../input/input.component"
import { Button } from "../../button/button.component"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm, SubmitHandler } from "react-hook-form"
import { PROJECT_COUNTRY, PROJECT_LANGUAGE, PROJECT_COUNTRIES } from "../../../core/APIUtils"
import { LabelContext } from "../../../core/label.context"
import { StyledInput } from "../../input/input.style"
import { EAddressType } from "../../../common/common.models"
import { CartContext } from "../../../core/cart.context"
import { UserContext } from "../../../core/user.context"
import { inputMaxLength } from "../../../common/constants"
import { useState } from "react"

/**
 * ADDRESS
 *
 *
 * @param {object} item - item can contain firstName/lastName/address and phone info
 *
 *
 */

export const Address = (props: any) => {
  const { item, compact } = props
  const { t } = React.useContext(LabelContext)

  return (
    <>
      {item && !compact && (
        <StyledAddress className="addressItem">
          <div className="addressUser">
            <b>
              {item.firstName} {item.lastName}
            </b>
          </div>
          <Property
            label={t("ecommerce.common.address")}
            item={
              item.streetName +
              " " +
              (item.streetNumber !== undefined ? item.streetNumber : "") +
              ", " +
              item.city +
              " - " +
              item.postalCode +
              (item.region !== undefined ? " - (" + item.region + ")" : "")
            }
          />
          {item.mobile && <Property label={t("ecommerce.common.mobile")} item={item.mobile}/>}
          {item.company && <Property label={t("ecommerce.common.company")} item={item.company}/>}
        </StyledAddress>
      )}

      {item && compact && (
        <StyledAddress className="addressItem">
          <div className="addressUser">
            {item.company} {item.firstName} {item.lastName},
            {item.streetName +
              " " +
              (item.streetNumber !== undefined ? item.streetNumber : "") +
              ", " +
              item.city +
              " - " +
              item.postalCode +
              " - (" +
              item.region +
              ")"}
          </div>
        </StyledAddress>
      )}
    </>
  )
}

export const AddAddress = (props: any) => {
  const { type, callback, callbackData } = props
  const { userData, setInvoiceValue } = React.useContext(GeneralContext)
  const { setAddress, setSendInvoice } = React.useContext(CartContext)
  const { addAddress } = React.useContext(UserContext)
  const [addressData] = React.useState<any>({
    ...props.dataEdit,
  })
  const [formVisibility, setFormVisibility] = React.useState<any>(true)

  let defaultCountryCode = addressData?.country || Object.keys(PROJECT_COUNTRIES)[0]
  const [selectedCountryCode, setSelectedCountryCode] = React.useState(defaultCountryCode)

  const [isCompany, setIsCompany] = React.useState(addressData.custom?.fields.is_company || false)
  const { t } = React.useContext(LabelContext)
  const [useAsBilling, setUseAsBilling] = React.useState(true)
  const api_key = "AIzaSyBT-l_iDGrdKwjbjrWv8wgolEDe7dO5wnE"

  let showCountrySelect = (type === "shipping" && PROJECT_COUNTRY !== "PL")
  let showUseAsBlling = type === "shipping" && !props.dataEdit?.firstName
  let showInfoForBilling = type === "billing" || (useAsBilling && !props.dataEdit?.firstName)
  let isEdit = addressData.id ? true : false

  /* Titolo */

  let title_key = ""
  if (type === "shipping" && !isEdit) title_key = "ecommerce.shippingAddress.new-shipping-address-title"
  if (type === "shipping" && isEdit) title_key = "ecommerce.shippingAddress.edit-shipping-address-title"

  if (type === "billing" && !isEdit) title_key = "ecommerce.billingAddress.new-billing-address-title"
  if (type === "billing" && isEdit) title_key = "ecommerce.billingAddress.edit-billing-address-title"
  let title = t(title_key)

  /* Validazione */
  let inputMaxLengthError = t("ecommerce.form-messagge.error-input-max-length")

  let requiredError = t("ecommerce.form-messagge.required")
  let numberError = t("ecommerce.form-messagge.number")

  let regExpPostalCode = /^([0-9]{5})$/
  let postalCodeError = t("ecommerce.form-messagge.invalid-postal-code")

  let regExpVat = /^([0-9]{11})$/
  let vatError = t("ecommerce.form-messagge.invalid-vat")

  let regExpSDI = /(^[a-zA-Z0-9]{6,7}$|^$)/
  let SDIError = t("ecommerce.form-messagge.invalid-sdi")

  if (PROJECT_COUNTRY === "FR") {
    // Frannce SIRET  Validation
    regExpSDI = /(^[0-9]{14}$|^$)/
    SDIError = t("ecommerce.form-messagge.invalid-sdi")
    regExpVat = /^FR([0-9]{11})$/
  }

  if (PROJECT_COUNTRY === "BE") {
    regExpPostalCode = /^([0-9]{4})$/
    regExpVat = /^BE([0-9]{10})$/
  }

  let regExpFiscalcode = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$/
  if (PROJECT_COUNTRY === "PL"){
    regExpVat = /^([0-9]{10})$/
    regExpFiscalcode = /^.{10}$/
  }

  if (PROJECT_COUNTRY === "NL") {
    regExpPostalCode = /^([0-9]{4})\s([A-Za-z]{2})$/
  }

  // lunghezze campi dipendenti da country
  let inputMaxLengthFirstName = inputMaxLength
  let inputMaxLengthLastName = inputMaxLength
  let inputMaxLengthPostalCode = inputMaxLength
  let inputMaxLengthPostalCodeError = inputMaxLengthError
  let inputMaxLengthCity = inputMaxLength
  let inputMaxLengthStreet = inputMaxLength
  let inputMaxLengthStreetNumber = inputMaxLength
  let inputMaxLengthFloorNumber = inputMaxLength
  let inputMaxLengthCustomError = inputMaxLengthError
  let inputMaxLenghFiscalCodeCompany = inputMaxLength
  let inputMaxLenghtFiscalCodeCompanyError = inputMaxLengthError
  let inputMaxLengthCityError = inputMaxLengthError
  let inputMaxLengthStreetError = inputMaxLengthError
  if (PROJECT_COUNTRY === "FR") {
    inputMaxLengthFirstName = 40
    inputMaxLengthLastName = 40
    inputMaxLengthCity = 35
    inputMaxLengthStreet = 35
    inputMaxLengthCityError = t("ecommerce.form-messagge.error-input-max-length-35-numbers")
    inputMaxLengthStreetError = t("ecommerce.form-messagge.error-input-max-length-35-numbers")
  }
  if (PROJECT_COUNTRY === "DE"){
    inputMaxLengthStreetNumber = 6
    inputMaxLengthFloorNumber = 6
    inputMaxLengthCustomError = t("ecommerce.form-messagge.error-input-max-length-six-numbers")
  }

  if (PROJECT_COUNTRY === "ZA"){
    regExpPostalCode = /^([0-9]{4})$/
    regExpVat = /^([0-9]{10})$/
    inputMaxLengthPostalCode = 4
    inputMaxLengthStreetNumber = 6
    inputMaxLengthPostalCodeError = t("ecommerce.form-messagge.error-input-max-length-custom").replace("[NUMBER]", "4")
    inputMaxLengthCustomError = t("ecommerce.form-messagge.error-input-max-length-six-numbers")
  }

  if (PROJECT_COUNTRY === "PL") {
    regExpPostalCode = /^\d{2}-\d{3}$/
    inputMaxLenghFiscalCodeCompany = 10
    inputMaxLengthStreetNumber = 6
    inputMaxLenghtFiscalCodeCompanyError = t("ecommerce.form-messagge.error-input-max-length-ten-numbers")
    inputMaxLengthCustomError = t("ecommerce.form-messagge.error-input-max-length-six-numbers")
  }

  // eslint-disable-next-line no-useless-escape
  let regPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/
  let phoneError = t("ecommerce.form-messagge.invalid-phone-number")

  let fiscalcodeError = t("ecommerce.form-messagge.invalid-fiscalcode")

  let pecOrSdiError = t("ecommerce.form-messagge.pec-sdi-required")
  let invalidEmail = t("ecommerce.form-messagge.email-invalid")

  let baseSchemaValidation = {
    firstName: yup.string().max(inputMaxLengthFirstName, inputMaxLengthError).required(requiredError),
    lastName: yup.string().max(inputMaxLengthLastName, inputMaxLengthError).required(requiredError),
    streetName: yup.string().max(inputMaxLengthStreet, inputMaxLengthStreetError).required(requiredError),
    streetNumber: yup.string().max(inputMaxLengthStreetNumber, inputMaxLengthCustomError).when({
      is: () => (["IT", "DK", "NO", "SE", "FI", "DE", "BE", "NL","ZA"].includes(PROJECT_COUNTRY)),
      then: yup.string().required(requiredError)
    }),
    postalCode: yup.string().max(inputMaxLengthPostalCode, inputMaxLengthPostalCodeError).required(requiredError).trim().matches(regExpPostalCode, postalCodeError),
    region: yup.string().when({
      is: () => (["IT", "DK", "NO", "SE", "FI", "DE", "FR", "BE", "NL", "PL", "ZA"].includes(PROJECT_COUNTRY)),
      then: yup.string().required(requiredError)
    }),
    city: yup.string().max(inputMaxLengthCity, inputMaxLengthCityError).required(requiredError),
    country: yup.string().max(inputMaxLength, inputMaxLengthError).when(
      {
        is: () => (type === "shipping" && PROJECT_COUNTRY !== "PL"),
        then: yup.string().required(requiredError)
      }

    ),
    mobile: yup.string().max(inputMaxLength, inputMaxLengthError).required(numberError).trim().matches(regPhone, phoneError),
    company: yup.string().max(inputMaxLength, inputMaxLengthError).when({
      is: () => (isCompany && useAsBilling),
      then: yup.string().required(requiredError)
    }
    ),
    custom: yup.object().shape(
      {
        fields: yup.object().shape(
          {
            sdi: yup.string().max(inputMaxLength, inputMaxLengthError).when({
              is: () => (type === "shipping" && !["BE", "DE", "NL", "PL", "ZA"].includes(PROJECT_COUNTRY)),
              then: yup.string().trim().matches(regExpSDI, SDIError)
            }),
            vat: yup.string().max(inputMaxLength, inputMaxLengthError).when({
              is: () => (isCompany && useAsBilling && !["DE", "NL", "PL"].includes(PROJECT_COUNTRY)),
              then: yup.string().required(requiredError).trim().matches(regExpVat, vatError),
            }),
            pec: yup.string()
              .when('custom.fields.sdi', {
                is: (sdi: string) => (((sdi === "") || sdi === undefined) && (isCompany && !["BE", "DE", "NL", "PL", "ZA"].includes(PROJECT_COUNTRY)) && useAsBilling),
                then: yup.string().email(invalidEmail).required(pecOrSdiError)
              }
              ),
            fiscalcode: yup.string().max(inputMaxLength, inputMaxLengthError)
              .when({
                is: () => (!isCompany && useAsBilling && (["IT", "DK", "NO", "SE", "FI"].includes(PROJECT_COUNTRY))),
                then: yup.string().required(requiredError).trim().matches(regExpFiscalcode, fiscalcodeError).required()
              }),
            fiscalcodeCompany: yup.string().max(inputMaxLenghFiscalCodeCompany, inputMaxLenghtFiscalCodeCompanyError)
              .when({
                is: () => (isCompany && useAsBilling && (["IT","PL"].includes(PROJECT_COUNTRY))),
                then: yup.string().required(requiredError).trim()
              }),
            floorNumber: yup.string().max(inputMaxLengthFloorNumber, inputMaxLengthCustomError)
                .when({
                  is: () => (type === "shipping" && (["DE"].includes(PROJECT_COUNTRY))),
                  then: yup.string()
                }),
            hasElevator: yup.string().max(inputMaxLength, inputMaxLengthError).when(
                {
                  is: () => (type === "shipping" && ["DE"].includes(PROJECT_COUNTRY)),
                  then: yup.string()
                }

            ),
            suburb: yup.string().max(inputMaxLength, inputMaxLengthError).when(
                {
                  is: () => (type === "shipping" && ["ZA"].includes(PROJECT_COUNTRY)),
                  then: yup.string()
                }

            ),
          }
        )
      }
    ),
  }


  const schemaValitation = yup.object().shape(baseSchemaValidation)

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValitation),
    defaultValues: { ...addressData },
    mode: "all",
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  type AddressValue = {
    id: string
    firstName: string
    lastName: string
    country: string
    streetName: string
    streetNumber: string
    additionalStreetInfo: string
    postalCode: string
    city: string
    region: string
    mobile: string
    company: string
    useAsBilling: boolean
    custom: {
      fields: {
        fiscalcode: string
        vat: string
        pec: string
        sdi: string
        is_company: string
        fiscalcodeCompany: string
        floorNumber: string
        hasElevator: string
        suburb: string
      }
    }
  }

  const setAddress_ = (data: any, addressData_: any) => {
    setAddress(addressData_, type).subscribe((res) => {
      if (useAsBilling && type === EAddressType.Shipping && !addressData_.id) {
        setAddress(addressData_, EAddressType.Billing, res).subscribe()
      }
    })
    callback(false)
    callbackData(addressData_)
  }

  const addAddress_ = (data: any, addressData_: any) => {
    addAddress(addressData_, type).subscribe((res) => {
      if (useAsBilling && type === EAddressType.Shipping && !addressData_.id) {
        addAddress(addressData_, EAddressType.Billing, res[0], res[1]).subscribe()
      }
    })
    callback(false)
  }

  const onSubmit: SubmitHandler<AddressValue> = (data) => {
    data["country"] = selectedCountryCode
    let _hasElevator = false
    if(data.custom?.fields?.hasElevator === "YES"){
      _hasElevator = true
    }
    let addressData_: any = {
      country: selectedCountryCode,
      id: data["id"],
      firstName: data["firstName"],
      lastName: data["lastName"],
      streetName: data["streetName"],
      streetNumber: data["streetNumber"],
      additionalStreetInfo: data["additionalStreetInfo"],
      postalCode: data["postalCode"],
      city: data["city"],
      region: data["region"],
      mobile: data["mobile"].toString(),
      company: data["company"],
      custom: {
        type: {
          typeId: "type",
          key: "address-billing-info",
        },
        fields: {
          is_company: data.custom?.fields?.is_company === "1",
          vat: data.custom?.fields?.vat,
          pec: data.custom?.fields?.pec,
          sdi: data.custom?.fields?.sdi,
          fiscalcode: data.custom?.fields?.fiscalcode,
          fiscalcodeCompany: data.custom?.fields?.fiscalcodeCompany,
          floorNumber: data.custom?.fields?.floorNumber,
          suburb: data.custom?.fields?.suburb,
        },
      },
    }

    if(data.custom?.fields?.hasElevator){
      addressData_.custom.fields["hasElevator"] = _hasElevator
    }

    if (isCompany) {
      delete addressData_.custom.fields.fiscalcode
    } else {
      delete addressData_.company
      delete addressData_.custom.fields.vat
      delete addressData_.custom.fields.pec
      delete addressData_.custom.fields.sdi
      delete addressData_.custom.fields.fiscalcodeCompany
    }

    if (userData) {
      addAddress_(data, addressData_)
    } else {
      setAddress_(data, addressData_)
    }
  }

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    showInfoForBilling = type === "billing" || (useAsBilling && !props.dataEdit?.firstName)
  }, [useAsBilling])

  let isAutocomplete = true
  let classHeight = ""
  let classAutomplete = ""
  let autocompleteZone = PROJECT_COUNTRY

  if (isAutocomplete) {
    classHeight = "height-0"
    classAutomplete = "isAutocomplete"
  }

  if (isEdit) {
    classHeight = ""
    classAutomplete = ""
  }

  const [state, setState] = useState({
    styleAutocomplete: classHeight,
    classAutomplete: classAutomplete,
  })

  const handleChangeCountry = (country: any) => {
    if (country === "LU") {
      setValue("custom.fields.vat", "")
      setValue("company", "")
      setIsCompany(false)
    }

    if (country) {
      setSelectedCountryCode(country);
      if (PROJECT_COUNTRIES[country]["regionsList"][PROJECT_LANGUAGE].length > 0) {
        setValue("region", "")
      } else {
        setValue("region", PROJECT_COUNTRIES[country]["name"][PROJECT_LANGUAGE]);
      }
    }


  }

  let toggleForm = () => {
    setState({
      styleAutocomplete: "",
      classAutomplete: classAutomplete,
    })
    setFormVisibility(false)
  }

  React.useEffect(() => {
    if (state.styleAutocomplete === "") {
      setFormVisibility(false)
    }
  }, [state])

  return (
    <StyledAddress>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`secondary row  ${state.classAutomplete}`} style={{ alignItems: "baseline" }}>
          <h4 className="col-12">{title}</h4>
          <input type="hidden" {...register("id")} name="id" />
          <input type="hidden" {...register("type")} value={type} name="type" />

          <InputElement
            externalRegister={register("firstName")}
            className="col-md-6"
            errors={errors.firstName?.message}
            label={t("ecommerce.formAddress.name")}
            id="firstNameEdit"
          />
          <InputElement
            externalRegister={register("lastName")}
            className="col-md-6"
            errors={errors.lastName?.message}
            label={t("ecommerce.formAddress.lastname")}
            id="lastNameEdit"
          />
          {!isEdit && (
            <div className={`col-12 address-autocomplete ${formVisibility ? "d-block" : "d-none"}`}>
              <label
                className="text-left"
                onClick={() => {
                  toggleForm()
                }}
                style={{ marginTop: "20px", cursor: "pointer" }}
              >
                <span>{t("ecommerce.formAddress.streetname")}</span>
                <span>
                  {t("ecommerce.formAddress.adressNotFound")}
                  <i className="fas fa-angle-down" style={{marginLeft: "5px", fontSize: "14px"}}/>
                </span>
              </label>

              <Autocomplete
                style={{ width: "100%", padding: "10px" }}
                apiKey={api_key}
                language={PROJECT_LANGUAGE}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: autocompleteZone },
                }}
                className={formVisibility ? "d-block" : "d-none"}
                onPlaceSelected={(place) => {
                  const address_components = place["address_components"] || []

                  function extractFromAdress(components: any, type: any) {
                    return (
                      components
                        .filter((component: any) => component.types.indexOf(type) === 0)
                        .map((item: any) => item.short_name)
                        .pop() || ""
                    )
                  }

                  let postCode = extractFromAdress(address_components, "postal_code")
                  let street = extractFromAdress(address_components, "route")
                  let streetNum = extractFromAdress(address_components, "street_number")
                  let additionalStreetInfo = extractFromAdress(address_components, "additional_street_info")
                  let town = extractFromAdress(address_components, "locality")
                  let country = extractFromAdress(address_components, "country")
                  let city = extractFromAdress(address_components, "administrative_area_level_3")
                  let region = extractFromAdress(address_components, "administrative_area_level_2")
                  //let regionFr = region + "-" + postCode.slice(0, 2)
                  let regionFr = "FR-" + postCode.slice(0, 2)
                  if (town === "Monaco")
                    regionFr = "MC-" + postCode.slice(0, 2)

                  if (PROJECT_COUNTRY === "NL") {
                    setValue("additionalStreetInfo", additionalStreetInfo, { shouldValidate: true })
                  }

                  if (["FR"].includes(PROJECT_COUNTRY)) {
                    setValue("streetName", street + "," + streetNum, { shouldValidate: true })
                  } else {
                    setValue("streetName", street, { shouldValidate: true })
                    setValue("streetNumber", streetNum, { shouldValidate: true })
                  }
                  setValue("postalCode", postCode, { shouldValidate: true })
                  setValue("city", city, { shouldValidate: true })
                  // La germania NON ha le region
                  if (!["DE", "PL"].includes(PROJECT_COUNTRY)) {
                    setValue("region", region, { shouldValidate: true })
                  }
                  if (!["PL"].includes(PROJECT_COUNTRY)) {
                    setValue("country", country, {shouldValidate: true})
                  }
                  setValue("city", town, { shouldValidate: true })
                  if (PROJECT_COUNTRY === "FR") {
                    setValue("region", regionFr, { shouldValidate: true })
                  }

                  setState({
                    styleAutocomplete: "",
                    classAutomplete: classAutomplete,
                  })
                }}
              />
            </div>
          )}

          {["IT", "DE", "BE", "NL", "PL", "FR", "ZA"].includes(PROJECT_COUNTRY) && (
            <>
              <InputElement
                externalRegister={register("streetName")}
                className={`col-md-${PROJECT_COUNTRY === 'FR' ? '9' : '10'}  ${state.styleAutocomplete}`}
                errors={errors.streetName?.message}
                label={t("ecommerce.formAddress.streetname")}
                placeholder={t("ecommerce.formAddress.streetname")}
                id="streetName"
              />
              {PROJECT_COUNTRY !== "FR" && (
                <InputElement
                  externalRegister={register("streetNumber")}
                  className={`col-md-2  ${state.styleAutocomplete}`}
                  errors={errors.streetNumber?.message}
                  label={t("ecommerce.formAddress.streetnumber")}
                  id="streetNumber"
                />
              )}
              {PROJECT_COUNTRY === "NL" && (
                <InputElement
                  externalRegister={register("additionalStreetInfo")}
                  className={`col-md-4  ${state.styleAutocomplete}`}
                  errors={errors.additionalStreetInfo?.message}
                  label={t("ecommerce.formAddress.additionalStreetNumber")}
                  id="additionalStreetInfo"
                />
              )}
              <InputElement
                externalRegister={register("postalCode")}
                className={`col-md-3 ${state.styleAutocomplete}`}
                errors={errors.postalCode?.message}
                label={t("ecommerce.formAddress.postalcode")}
                placeholder={t("ecommerce.formAddress.postalcode")}
                id="postalCode"
              />
            </>
          )}

          {PROJECT_COUNTRY === "ZA" && (
              <InputElement
                  externalRegister={register("custom.fields.suburb")}
                  className={`col-md-4 ${state.styleAutocomplete}`}
                  errors={errors.custom?.fields?.suburb?.message}
                  label={t("ecommerce.formAddress.suburb")}
                  id="custom.fields.city"
              />
          )}

          <InputElement
            externalRegister={register("city")}
            className={`col-md-5 ${state.styleAutocomplete}`}
            errors={errors.city?.message}
            label={t("ecommerce.formAddress.city")}
            id="city"
          />

          {["IT", "FR", "BE", "NL", "DE", "PL", "ZA"].includes(PROJECT_COUNTRY) && (

            (PROJECT_COUNTRIES[selectedCountryCode]["regionsList"][PROJECT_LANGUAGE].length > 0 &&
              <StyledInput className={`cmp_input col-md-4 ${state.styleAutocomplete}`}>
                <label className="text-left">{t("ecommerce.formAddress.region")}</label>
                <select {...register("region")}>
                  <option value="">{t("ecommerce.formAddress.select")}</option>
                  {PROJECT_COUNTRIES[selectedCountryCode]["regionsList"][PROJECT_LANGUAGE].map((region: any, i: number) => {
                    let value = Object.keys(region)[0]
                    let text = String(Object.values(region)[0])
                    return (
                      <option key={i} value={value}>
                        {text}
                      </option>
                    )
                  })}
                </select>
                <p className="message--alert small">{errors.region?.message}</p>
              </StyledInput>
            )
            ||
            (
              PROJECT_COUNTRIES[selectedCountryCode]["regionsList"][PROJECT_LANGUAGE].length === 0 &&
              <>
                <input type="hidden" {...register("region")} defaultValue={PROJECT_COUNTRIES[selectedCountryCode]["name"][PROJECT_LANGUAGE]} value={PROJECT_COUNTRIES[selectedCountryCode]["name"][PROJECT_LANGUAGE]} />
              </>
            )

          )}

          {showCountrySelect && (
            <StyledInput className={`cmp_input col-md-3 ${state.styleAutocomplete}`}>
              <label className="text-left">{t("ecommerce.formAddress.country")}</label>
              <select {...register("country")} name="country" onChange={(e) => handleChangeCountry(e.target.value)} value={selectedCountryCode} >
                <option key={1} defaultValue="" value="">
                  {t("ecommerce.formAddress.country")}
                </option>
                {Object.keys(PROJECT_COUNTRIES).map((country_key: any, i: number) => {
                  let current_country = PROJECT_COUNTRIES[country_key];
                  return (
                    <option key={2} value={country_key}>
                      {current_country["name"][PROJECT_LANGUAGE]}
                    </option>
                  )
                })}
              </select>
              <p className="message--alert small">{errors.country?.message}</p>
            </StyledInput>
          )}

          <InputElement
            externalRegister={register("mobile")}
            className={`col-md-${PROJECT_COUNTRY === 'PL' ? '4' : '5'} ${state.styleAutocomplete}`}
            errors={errors.mobile?.message}
            label={t("ecommerce.formAddress.phone")}
            type="tel"
            id="mobile"
          />

          {PROJECT_COUNTRY === "DE" && type === "shipping" && (
              <>
                <InputElement
                  externalRegister={register("custom.fields.floorNumber")}
                  className={`col-md-3 ${state.styleAutocomplete}`}
                  errors={errors.custom?.fields?.floorNumber?.message}
                  label={t("ecommerce.formAddress.floorNumber")}
                  placeholder={t("ecommerce.formAddress.floorNumber")}
                  id="custom.fields.floorNumber"
                />
                <StyledInput className={`cmp_input col-md-3 ${state.styleAutocomplete}`}>
                  <label className="text-left">{t("ecommerce.formAddress.hasElevator")}</label>
                  <select {...register("custom.fields.hasElevator")}>
                    <option value="" >{t("ecommerce.formAddress.hasElevatorSelect")}</option>
                    <option key={1} value={"YES"}>{"YES"}</option>
                    <option key={2} value={"NO"}>{"NO"}</option>
                  </select>
                  <p className="message--alert small">{errors.hasElevator?.message}</p>
                </StyledInput>
              </>
          )}

          {showUseAsBlling && (
            <>
              <div className="col-12" id="row_show_billing_info">
                <div className="checkboxButton">
                  <input
                    type="checkbox"
                    checked={useAsBilling}
                    {...register("useAsBilling")}
                    name="useAsBilling"
                    id="useAsBilling"
                    onChange={(event) => { setUseAsBilling(event.target.checked); setIsCompany(false) }}
                  />
                  {" "}
                  <label htmlFor="useAsBilling" className="text-left generic-label">
                    {t("ecommerce.formAddress.useAsBilling")}
                  </label>
                </div>
              </div>
            </>
          )}

          {showInfoForBilling && selectedCountryCode !== "LU" && (
            <div className="col-12" id="row_select_private_company">
              <div className="row">
                {["FR", "DE", "NL", "PL", "IT", "BE", "ZA"].includes(PROJECT_COUNTRY) && (
                  <>
                    <div className="col-12 ">
                      <br />
                      <div className="checkboxButton">
                        <input
                          type="checkbox"
                          id="isFrenchCompany"
                          checked={isCompany === true}
                          {...register("custom.fields.is_company")}
                          name="custom.fields.is_company"
                          onChange={(event) => {
                            setIsCompany(!isCompany)
                            if(PROJECT_COUNTRY === 'PL'){
                              setSendInvoice(!isCompany);
                              setInvoiceValue(!isCompany)
                            }
                            setValue("custom.fields.is_company", (!isCompany ? "1" : "0"))
                          }}
                        />
                        <label htmlFor="isFrenchCompany">{t("ecommerce.formAddress.company_title")}</label>
                      </div>
                    </div>
                  </>
                )}

                {/* COMPANY */}
                {isCompany && (
                  <>
                    <InputElement
                      externalRegister={register("company")}
                      className="col-md-7"
                      label={t("ecommerce.formAddress.company")}
                      id="company"
                      errors={errors.company?.message}
                    />
                    {!["DE", "NL","PL"].includes(PROJECT_COUNTRY) && (
                      <>
                        <InputElement
                          externalRegister={register("custom.fields.vat")}
                          placeholder={t("ecommerce.form-message.vat-placeholder")}
                          className="col-md-7"
                          label={t("ecommerce.formAddress.pIva")}
                          id="custom.fields.vat"
                          errors={errors.custom?.fields?.vat?.message}
                        />
                      </>
                    )}
                    {!["BE", "DE", "NL", "PL", "ZA"].includes(PROJECT_COUNTRY) && (
                      <>
                        <InputElement
                          externalRegister={register("custom.fields.pec")}
                          className="col-md-6"
                          label={t("ecommerce.formAddress.pec")}
                          id="custom.fields.pec"
                          errors={errors.custom?.fields?.pec?.message}
                        />
                        <InputElement
                          externalRegister={register("custom.fields.sdi")}
                          placeholder={t("ecommerce.form-message.sdi-placeholder")}
                          className="col-md-6"
                          label={t("ecommerce.formAddress.sdi")}
                          id="custom.fields.sdi"
                          errors={errors.custom?.fields?.sdi?.message}
                        />
                      </>
                    )}
                    {["IT", "PL"].includes(PROJECT_COUNTRY) && (
                      <>
                        <InputElement
                          externalRegister={register("custom.fields.fiscalcodeCompany")}
                          className="col-md-6"
                          label={t("ecommerce.formAddress.fiscalcodeCompany")}
                          id="custom.fields.fiscalcodeCompany"
                          errors={errors.custom?.fields?.fiscalcodeCompany?.message}
                        />
                      </>
                    )}
                  </>
                )}
                {/* NOT COMPANY */}
                {!isCompany && ["IT", "DK", "NO", "SE", "FI"].includes(PROJECT_COUNTRY) && (
                  <>
                    <InputElement
                      externalRegister={register("custom.fields.fiscalcode")}
                      className="col-md-7"
                      label={t("ecommerce.formAddress.fiscalcode")}
                      placeholder={t("ecommerce.formAddress.fiscalcode")}
                      hint={t("ecommerce.formAddress.fiscalcode.info")}
                      errors={errors.custom?.fields?.fiscalcode?.message}
                      id="custom.fields.fiscalcode"
                    />
                  </>
                )}
              </div>
            </div>
          )}

          <div className="col-12">
            <div className="row">
              <div className="col-md-4 offset-md-1">
                <Button className="primary actionForm" label={t("ecommerce.common.save")} type="submit"/>
              </div>
              <div className="col-md-4 offset-md-2">
                <Button
                  className="secondary actionForm "
                  label={t("ecommerce.common.cancel")}
                  type="button"
                  onClick={() => callback(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </StyledAddress>
  )
}
