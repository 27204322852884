import * as React from "react";
import { StyledBox } from "./box.style";


/**
 * BOX COMPONENT
 *
 *
 * @param {string} className   = classe css
 *
 *
 */

export const Box = (props: any) => {

  const { className, children } = props;

  return <>
    <StyledBox className={className}>
      {children}
    </StyledBox>
  </>
};
