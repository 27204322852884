import * as React from "react";
import { GeneralContext } from "../../core/general.context";
import { LabelContext } from "../../core/label.context";
import { Box } from "../box/box.component";
import { Button } from "../button/button.component";
import { Property } from "../elements/property/property.component";
import { StyledAccount } from "./account.style";
import { InputElement } from "../input/input.component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { inputMaxLength, regexPwdValidation } from "../../common/constants";
import { UserContext } from "../../core/user.context";
import { PROJECT_SETTINGS } from "../../core/APIUtils";

export const Account = (props: any) => {
  const { userData } = React.useContext(GeneralContext);
  const [formEditPwdOpen, setFormEditPwdOpen] = React.useState(false);
  const { t } = React.useContext(LabelContext);

  return (
    <StyledAccount>
      <Box className="unboxed unbordered secondary">
        <h2>{t("ecommerce.myaccount.access-data-title")}</h2>
        {!formEditPwdOpen && (
          <Box className="secondary">
            <div className="row">
              <div className="col-md-8">
                <Property
                  label={t("ecommerce.login.email")}
                  item={userData.email}
                ></Property>
                <Property
                  label={t("ecommerce.login.password")}
                  item="*********"
                ></Property>
              </div>
              <div className="col-md-2 ">
                <Button
                  label={t("ecommerce.myaccount.change-password-button")}
                  className="secondary"
                  onClick={() => setFormEditPwdOpen(true)}
                ></Button>
              </div>
            </div>
          </Box>
        )}
        {formEditPwdOpen && (
          <div className="row">
            <div className="col-md-8">
              <Box className="secondary">
                <EditPwd callback={() => setFormEditPwdOpen(false)} />
              </Box>
            </div>
          </div>
        )}
        {t("ecommerce.myaccount.delete-account-title") &&
          <>
            <h2 data-translate="ecommerce.myaccount.delete-account-title">
              {t("ecommerce.myaccount.delete-account-title")}
            </h2>
            <Box className="secondary">
              <div dangerouslySetInnerHTML={{ __html: t("ecommerce.myaccount.delete-account-text") }}
                data-translate="ecommerce.myaccount.delete-account-text"></div>
            </Box>
          </>
        }
      </Box>



    </StyledAccount>
  );
};

export const EditPwd = (props: any) => {
  const { callback } = props;
  const { changePwdCustomer } = React.useContext(UserContext);
  const { t } = React.useContext(LabelContext);

  let requiredError = t("ecommerce.form-messagge.required");
  let passwordNotMatchError = t("ecommerce.form-messagge.password-must-match");
  let passwordInvalidError = t("ecommerce.form-messagge.password-invalid");

  const schema = yup.object().shape({
    oldpassword: yup.string().max(inputMaxLength).required(requiredError),
    newpassword: yup
      .string()
      .max(inputMaxLength)
      .required(requiredError)
      .trim()
      .matches(regexPwdValidation, passwordInvalidError),
    newpassword_check: yup
      .string()
      .max(inputMaxLength)
      .oneOf([yup.ref("newpassword")], passwordNotMatchError)
      .required(requiredError),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    changePwdCustomer(data);
    callback(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3>{t("ecommerce.myaccount.changepassword-form-title")}</h3>
      <InputElement
        externalRegister={register("oldpassword")}
        id="oldpassword"
        type="password"
        className="col-9"
        label={t("ecommerce.myaccount.changepassword-old-password-field")}
        errors={errors.oldpassword?.message}
      ></InputElement>
      <InputElement
        externalRegister={register("newpassword")}
        id="newpassword"
        type="password"
        className="col-9"
        label={t("ecommerce.myaccount.changepassword-new-password-field")}
        errors={errors.newpassword?.message}
      ></InputElement>
      <InputElement
        externalRegister={register("newpassword_check")}
        id="newpassword_check"
        className="col-9"
        type="password"
        label={t(
          "ecommerce.myaccount.changepassword-old-password-confirm-field"
        )}
        errors={errors.newpassword_check?.message}
      ></InputElement>
      <div className="row">
        <div className="col-md-3 offset-md-1">
          <Button
            className="primary actionForm "
            label={t("ecommerce.common.save")}
            type="submit"
          ></Button>
        </div>
        <div className="col-md-3 offset-md-1">
          <Button
            className="secondary actionForm "
            label={t("ecommerce.common.cancel")}
            type="button"
            onClick={() => callback(false)}
          ></Button>
        </div>
      </div>
    </form>
  );
};
