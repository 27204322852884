import styled from "styled-components"

export const StyledAddress = styled.div`
  .address-autocomplete {
    label {
      font-size: 0.65rem;
      font-weight: bold;
      text-align: center;
      color: var(--color-primary);
      font-family: "Raleway", sans-serif;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    input {
      padding: 0 !important;
      text-align: center;
      border: none;
      border-bottom: 1px solid var(--color-primary);
      &::-webkit-input-placeholder {
        font-family: "Raleway", sans-serif;
      }
    }
  }
`
