import styled from "styled-components";

export const StyledPages = styled.div`
color:var(--color-primary);
padding-bottom: 100px;

h1 { 
	font-size:2rem;

}

h2 { 
	font-size:30px;
	margin-bottom:30px;
}

h3 { 
	font-size:20px;
	margin-bottom:20px;
}


h4 { 
	font-size:16px;
	margin-bottom:16px;
	font-weight:bold;
}

@media (max-width: 768px) {
    min-height:auto;
	h1 { 
		margin:30px 0 10px 0;
		font-size:24px;

	}

	h2 { 
		font-size:22px;
		margin-bottom:20px;
	}

	h3 { 
		font-size:20px;
		margin-bottom:16px;
		margin-top:20px;
	}


	h4 { 
		font-size:16px;
		margin-bottom:10px;
		font-weight:bold;
	}

}


`;