import React, { useEffect } from "react"
import { GeneralContext } from "../../core/general.context"
import { CartContext } from "../../core/cart.context"
import { LabelContext } from "../../core/label.context"
import { Box } from "../box/box.component"
import { Button } from "../button/button.component"
import {IS_GIFT_CARD_ACTIVE, PROJECT_COUNTRY, PROJECT_KEY, PROJECT_SETTINGS} from "../../core/APIUtils"
import { nordics } from "../../common/constants"
import {SubmitHandler, useForm} from "react-hook-form"
import {Property} from "../elements/property/property.component"
import {InputElement} from "../input/input.component"
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import SelectMultiOptions from "../elements/select/SelectMultiOptions"

export const KlarnaCheckout = (props: any) => {
  const { t } = React.useContext(LabelContext)
  const { cart, klarna, giftCards } = React.useContext(GeneralContext)
  const {
    populateGiftCardRecipientInfo,
    setApplyGiftCardToCart,
    setRemoveGiftCardFromCart,
    selectedGiftcards,
    setSelectedGiftcards,
    setRemoveAllGiftCardFromCart,
    loadGiftCards,
    differenceBy,
  } = React.useContext(CartContext)
  const { getKlarna, setNoteOnCart, checkIsCartChange, isCartChanged, additionalsDetailsPayment } = React.useContext(CartContext)

  const { redirectResult } = props
  const { userData } = React.useContext(GeneralContext)

  const [privacy, setPrivacy] = React.useState(false)
  const [commercial, setCommercial] = React.useState(null!)
  
  const [focusPrivacy, setFocusPrivacy] = React.useState(false)
  const [clickedPay, setClickedPay] = React.useState(false)

  //GIFTCARD STATES
  const [enabledGiftCardEditButton, setEnabledGiftCardEditButton] = React.useState(false)
  const [giftcardInsideCard, setGiftcardInsideCart] = React.useState(false)
  const [hasGiftCards, setHasGiftCards] = React.useState(false);
  const [showGiftCardSelect, setShowGiftCardSelect] = React.useState(false);

  let requiredError = t("ecommerce.form-messagge.required");
  let invalidEmail = t("ecommerce.form-messagge.email-invalid")

  let options: any[] = []
  if (giftCards) {
    giftCards.forEach((giftCard: any, i: number) => {
      options.push({ value: giftCard?.serialNumber.toString(), label: giftCard?.giftCardName, residual: giftCard?.residualValue })
    })
  }

  const giftCardSchema = yup.object().shape({
    "gift-card": yup.lazy(objectShape => {
      const obj: any = {};
      Object.keys(objectShape).forEach(key => {
        obj[key] = yup.array().of(
            yup.object().shape({
              value: yup.object().shape({
                name: yup.string().required(requiredError),
                surname: yup.string().required(requiredError),
                email: yup.string().email(invalidEmail).required(requiredError)
              })
            })
        );
      });
      return yup.object().shape(obj);
    })
  });

  const {register, formState: {errors}, handleSubmit, reset} = useForm<any>({
    resolver: yupResolver(giftCardSchema),
    mode: "onSubmit",
    defaultValues: {
      "gift-card": {}
    }
  });

  React.useEffect(() => {
    const data: any = { "gift-card": {}}
    checkIsCartChange(cart)

    //carica le giftcard
    loadGiftCards(true);

    //controlla se ci sono giftcard aggiunte a carrello
    const hasActiveGiftCardsInCart = cart.customLineItems.length > 0
    //mostra la select se ci sono giftcard attive
    if(hasActiveGiftCardsInCart){
      setShowGiftCardSelect(true)
    }

    const selectedGiftCard: any = [];
    cart.customLineItems.map((item: any) => {
      selectedGiftCard.push({ value: item.slug.split('gift-card-')[1], label: item.name[Object.keys(item.name)[0]], residual: Math.abs(item.money.centAmount) })
    });
    setSelectedGiftcards(selectedGiftCard)

    const selectedGiftCards = cart.customLineItems
        .filter((item: any) => item.slug && item.slug.includes('gift-card-')) // Filtra solo le customLineItems di tipo giftcard
        .map((item: any) => ({
          value: item.slug.split('gift-card-')[1],
          label: item.name[Object.keys(item.name)[0]],
          residual: Math.abs(item.money.centAmount)
        }));
    setSelectedGiftcards(selectedGiftCards);

    cart.lineItems.map((item: any, i) => {
      if (item?.productType?.obj?.key === "GIFTCARD") {
        setGiftcardInsideCart(true)

        if (item?.productType?.obj?.key === "GIFTCARD") {
          let dataRecipient: any;
          if (item?.custom?.fields['recipients-giftcard']) {
            try {
              dataRecipient = JSON.parse(item?.custom?.fields['recipients-giftcard']);
              data["gift-card"][item.id] = dataRecipient
            } catch (e) {
              dataRecipient = [];
            }
          }
        }
      }
    })
    reset(data)

  }, [])

  React.useEffect(() => {
    checkPrivacyFlagged()
  }, [privacy, commercial, clickedPay])

  React.useEffect(() => {
    if (cart.customLineItems.length <= 0) {
      setSelectedGiftcards([])
    }

    if(giftCards != null && giftCards.length > 0){
      setHasGiftCards(true)
    }

    const allRecipientsFilled = cart.lineItems.every((giftCard) => {
      if (!giftCard?.productType?.obj?.key || giftCard?.productType?.obj?.key !== "GIFTCARD") {
        return true;
      }

      const { quantity } = giftCard;

      if (giftCard?.custom?.fields['recipients-giftcard']) {
        let dataRecipient: any;
        try {
          dataRecipient = JSON.parse(giftCard?.custom?.fields['recipients-giftcard']);
        } catch (e) {
          return false;
        }

        return [...Array(quantity)].every((_, i) => {
          return dataRecipient[i]?.value.name &&
              dataRecipient[i]?.value.surname &&
              dataRecipient[i]?.value.email;
        });
      }
    });
    setEnabledGiftCardEditButton(allRecipientsFilled)
  }, [cart, giftCards])

  React.useEffect(() => {
    if (redirectResult) {
      let details = {
        details: {
          redirectResult: redirectResult,
        },
      }
      additionalsDetailsPayment(details, adyenComponent)
    }
  }, [redirectResult])

  const handleSelectChange = (values: any) => {
    const diffValue = differenceBy(values, selectedGiftcards)
    const wasThereBefore = selectedGiftcards.some((x: any) => x?.value === diffValue?.value)
    //capisco se sto rimuovendo o aggiungeno una giftcard a carrello
    if (wasThereBefore) {
      const removeGiftcard = cart.customLineItems.find((item: any) => (item.slug.split('gift-card-')[1] === diffValue?.value));
      setRemoveGiftCardFromCart(removeGiftcard)
    } else {
      setApplyGiftCardToCart(diffValue)
    }
  };

  const checkPrivacyFlagged = () => {
    setFocusPrivacy(clickedPay && !(commercial && (privacy || userData)))
    return commercial && (privacy || userData)
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    populateGiftCardRecipientInfo(data)
  };

  let adyenComponent: any

  const loadKlarnaForm = () => {
    setClickedPay(true)
    if (!checkPrivacyFlagged()) {
      return
    }
    getKlarna(cart.id)
  }

  useEffect(() => {
    if (klarna) {
      const script = document.querySelectorAll("#klarna-container script")[0]?.innerHTML
      script && window.eval(script)
    }
  }, [klarna])

  return (
    <>
      <div className="row no-gutters">
        <div className="checkout-column-main col-lg-8">
          <div className="wrapper">
            {/* Inserimento email destinatario per ogni buono regalo */}
            {cart.lineItems && giftcardInsideCard && IS_GIFT_CARD_ACTIVE &&
            <>

              <Box className={`secondary compact`}>
                <h3>{t("ecommerce.checkout.giftCards-section-title")}</h3>
                <form action="" id="giftCardForm" onSubmit={handleSubmit(onSubmit)}>
                  {cart.lineItems.map((giftCard, giftcCardIndex) => {
                    if (giftCard?.productType?.obj?.key === "GIFTCARD") {
                      const {name, quantity} = giftCard;

                      return (
                          <>
                            {[...Array(quantity)].map((giftcardQta, giftCardQtaIndex) => {

                              return (
                                  <>
                                    <Box className="secondary unbordered unboxed ">
                                      <Property
                                          label={t("ecommerce.giftcard.checkout.giftCard-title")}
                                          item={`${name[Object.keys(name)[0]]} - ${quantity >= 2 ? `${giftCardQtaIndex + 1} / ${quantity.toString()}` : quantity}`}
                                      />
                                      <div className="row">
                                        <div className="col">
                                          <InputElement type='name'
                                                        data-Id={giftCard.id}
                                                        data-quantity={giftCardQtaIndex + 1}
                                                        data-type={"name"}
                                                        label={t("ecommerce.giftcard.name")}
                                                        key={name + giftCard.id + giftCardQtaIndex}
                                                        externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.name`)}
                                                        errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.name?.message}
                                                        disabled={enabledGiftCardEditButton}/>
                                        </div>
                                        <div className="col">
                                          <InputElement type='surname'
                                                        data-Id={giftCard.id}
                                                        data-quantity={giftCardQtaIndex + 1}
                                                        data-type={"surname"}
                                                        label={t("ecommerce.giftcard.surname")}
                                                        key={name + giftCard.id + giftCardQtaIndex}
                                                        externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.surname`)}
                                                        errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.surname?.message}
                                                        disabled={enabledGiftCardEditButton}/>
                                        </div>
                                        <div className="col">
                                          <InputElement type='email'
                                                        data-Id={giftCard.id}
                                                        data-quantity={giftCardQtaIndex + 1}
                                                        data-type={"email"}
                                                        label={t("ecommerce.giftcard.email")}
                                                        key={name + giftCard.id + giftCardQtaIndex}
                                                        externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.email`)}
                                                        errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.email?.message}
                                                        disabled={enabledGiftCardEditButton}/>
                                        </div>
                                      </div>
                                    </Box>
                                  </>)
                            })}
                          </>
                      );
                    }
                  })}
                  {!enabledGiftCardEditButton && <Button className="primary mt-4 mr-4" label={t("ecommerce.giftcard.checkout.save")} type="submit"/>}
                  {enabledGiftCardEditButton && <Button className="primary mt-4" label={t("ecommerce.common.edit")} type="button" onClick={(e: any) => {setEnabledGiftCardEditButton(false)}}/>}
                </form>
              </Box>
            </>
            }

            {/* Inserimento nota su ordine */}
            {PROJECT_COUNTRY !== "DE" &&
              <div className="component">
                <Box className={`secondary compact`} >
                  <h3>{t("ecommerce.checkout.note-title")}</h3>
                  <div>{t("ecommerce.checkout.note-description")}</div>
                  <textarea style={{ width: '100%' }} maxLength={70} placeholder={t("ecommerce.checkout.note-text-limit")} className="" onBlur={(e) => { setNoteOnCart(e.target.value); }}></textarea>
                </Box>
              </div>
            }

            {(enabledGiftCardEditButton || !giftcardInsideCard) &&
            <>
              <Box className="compact">
                <span className="text-bold">{t("ecommerce.checkout.deliveryInfo")}</span>
              </Box>
              {/* messaggio per prodotti terminati */}
              {isCartChanged && (
                <div className="component">
                  <Box className="secondary compact">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <h3 className="focus">{t("ecommerce.checkout.cart-changed-title")}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t("ecommerce.checkout.cart-changed-description"),
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                </div>
              )}
              {!isCartChanged && cart.totalPrice.centAmount > 0 && PROJECT_SETTINGS.inventoryMode !== "ReserveOnOrder" && (
                <div className="component">
                  <Box className="secondary compact">
                    {/* Privacy policy */}
                    {nordics.includes(PROJECT_KEY) && (
                      <div className="component">
                        <ul>
                          <li key="privacy" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                            <div className="d-flex align-items-center mb-1">
                              <span className="pr-1">*</span>
                              <input
                                type="checkbox"
                                defaultChecked={privacy}
                                value="1"
                                name="privacy"
                                id="privacy"
                                onClick={(event: any) => {
                                  setPrivacy(event.target?.checked)
                                  setClickedPay(false)
                                }}
                              />
                              <label
                                htmlFor="privacy"
                                dangerouslySetInnerHTML={{
                                  __html: t("ecommerce.checkout.privacy-check-description"),
                                }}
                              ></label>
                            </div>
                          </li>

                          <li key="commercial" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                              <div className="d-flex align-items-center mb-1">
                                <span className="pr-1">*</span>
                                <input
                                  type="checkbox"
                                  checked={commercial}
                                  value="1"
                                  name="commercial"
                                  id="commercial"
                                  onClick={(event: any) => {
                                    setCommercial(event.target?.checked)
                                    setClickedPay(false)
                                  }}
                                />
                                <label
                                  htmlFor="commercial"
                                  dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.commercial-check-description") }}
                                ></label>
                              </div>
                            </li>

                        </ul>
                      </div>
                    )}

                    {IS_GIFT_CARD_ACTIVE && hasGiftCards &&
                    <>
                      <div className="checkboxButton">
                        <span className="pr-1" style={{ visibility: 'hidden' }}>*</span>
                        <input
                            type="checkbox"
                            id="toggleDropdown"
                            name="toggleDropdown"
                            checked={showGiftCardSelect}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowGiftCardSelect(isChecked);

                              if (!isChecked) {
                                setRemoveAllGiftCardFromCart(cart);
                              }
                            }}
                        />
                        <label htmlFor="toggleDropdown">{t("ecommerce.giftcard.load")}</label>
                      </div>
                      {!Boolean(cart.discountCodes.length) && showGiftCardSelect &&
                        <SelectMultiOptions onChange={handleSelectChange} isMulti options={options} value={selectedGiftcards} className={"col-12 col-md-6"}/>
                      }
                    </>
                    }

                    <div className="text-center mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Button
                            className="ec_btn_primary"
                            icon={"fas fa-circle-notch fa-spin"}
                            loader={{ startLoader: (privacy && commercial ) && !focusPrivacy && clickedPay, stopLoader: klarna }}
                            label={t("ecommerce.checkout.klarna-button")}
                            onClick={loadKlarnaForm}
                          ></Button>
                        </div>
                      </div>
                    </div>
                    <div
                      id="klarna-container"
                      dangerouslySetInnerHTML={{
                        __html: klarna?.obj?.html_snippet,
                      }}
                    />
                  </Box>
                </div>
              )}
            </>
            }
          </div>
        </div>
        {/*TODO: commented for SSD-2457*/}
        {/*<aside className="checkout-column-aside col-lg-4">
          <CartRecap />
        </aside>*/}
      </div>
    </>
  )
}
