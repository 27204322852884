import * as React from "react";
import ReactDOM from "react-dom";
import {GeneralContext} from "../../core/general.context";
import {Box} from "../box/box.component";
import {StyledWishlist} from "./wishlist.style";
import {Button} from "../button/button.component";
import {ProductInfo} from "../productInfo/productInfo.component";
import {LabelContext} from "../../core/label.context";
import {BRX_BASE_URL, PROJECT_SETTINGS} from "../../core/APIUtils";
import {CartContext} from "../../core/cart.context";

export const Wishlist = (props: any) => {
    const {inPage} = props;
    const {wishlist} = React.useContext(GeneralContext);
    const {addRemoveWishlistLineItem, addLineItem} = React.useContext(CartContext);
    const {t} = React.useContext(LabelContext);

    const filteredLineItems = wishlist.lineItems.filter((wishItem: any) =>
        wishItem.variant && !wishItem.deactivatedAt
    );

    let productInPreorder: string[] = []
    filteredLineItems.forEach((wishItem: any) => {
        if(wishItem.variant.attributes){
            wishItem.variant.attributes.find((attribute: any) => {
                if (attribute.name === 'pre-order' && attribute.value === true) {
                    productInPreorder.push(wishItem.variant.sku)
                }
            })
        }
    })


    return (
        <StyledWishlist>

            <Box className={props.className + " unbordered"}>
                {!inPage && <h2>{t("ecommerce.pages.wishlist-title")} </h2>}
                {filteredLineItems.map((wishItem: any) => (
                    <Box className="secondary" key={wishItem.id}>
                        <div className="row">
                            <div className="col-md-4 col-lg-6 col-xl-8">
                                <ProductInfo item={wishItem} callerComponent={"wishlist"}/>
                            </div>
                            <div className="col-6 col-lg-3 col-xl-2">
                                <>
                                    {wishItem.variant.availability?.availableQuantity > 0 && !productInPreorder.includes(wishItem.variant.sku) &&
                                        <Button className="simple" label={t("ecommerce.common.product.addToCart")} icon="fas fa-shopping-cart" onClick={() => {
                                            addLineItem(wishItem.variant.sku, false);/*addRemoveWishlistLineItem(wishItem.variant.sku);*/
                                        }}/>
                                    }
                                    {wishItem.variant.availability?.availableQuantity > 0 && productInPreorder.includes(wishItem.variant.sku) &&
                                        <Button className="simple" label={t("ecommerce.common.product.pre-order-cta")} icon="fas fa-shopping-cart" onClick={() => {
                                            addLineItem(wishItem.variant.sku, false);/*addRemoveWishlistLineItem(wishItem.variant.sku);*/
                                        }}/>
                                    }
                                    {wishItem.variant.availability?.availableQuantity === 0 && PROJECT_SETTINGS.inventoryMode === "ReserveOnOrder" &&
                                        <Button className="simple text-decoration-none cursor-default" label={t("ecommerce.myaccount.wishlist-prouct-not-available")} icon="fas fa-shopping-cart"></Button>
                                    }
                                    {wishItem.variant.availability?.availableQuantity <= 0 && PROJECT_SETTINGS.inventoryMode !== "ReserveOnOrder" &&
                                        <Button className="simple" label={t("ecommerce.common.product.addToCart")} icon="fas fa-shopping-cart" onClick={() => {addLineItem(wishItem.variant.sku, false);}}></Button>
                                    }
                                    {!wishItem.variant.availability &&
                                        <Button className="simple text-decoration-none cursor-default" disable={true} label={t("ecommerce.myaccount.wishlist-prouct-not-sellable")} icon="fas fa-shopping-cart"/>
                                    }
                                </>


                            </div>
                            <div className="col-6 col-lg-3 col-xl-2">
                                <Button className="simple" label={t("ecommerce.common.delete")} icon="fas fa-trash" onClick={() => {
                                    addRemoveWishlistLineItem(wishItem.variant.sku)
                                }}/>
                            </div>
                        </div>
                    </Box>
                ))}

                {filteredLineItems.length === 0 &&
                    <div className="text-center" dangerouslySetInnerHTML={{__html: t("ecommerce.myaccount.wishlist-empty")}}/>
                }
            </Box>
        </StyledWishlist>
    );
};


export const WishlistIcon = () => {

    const {numberOfProductsWish, isEmptyWish} = React.useContext(CartContext);
    const container_btn = document.getElementsByClassName("reactPlaceholder_wishlist_btn");

    if (!container_btn)
        return null;

    else {
        return <>
            {Array.from(container_btn).map((link, i) =>
                ReactDOM.createPortal(
                    <a className="ec_btn ec_link-wishlistPage" href={BRX_BASE_URL + "/wishlist"}>
                        <span className="icon"/>
                        <span className="testo">Wishlist</span>
                        {!isEmptyWish && <span className="count">{numberOfProductsWish}</span>}
                    </a>,
                    link
                )
            )
            }
        </>
    }
};