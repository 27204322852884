import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { Signup } from "../components/signup/signup.component";
import { StyledPages } from "./commonPages.style";
import { GeneralContext } from "../core/general.context";
import { LabelContext } from "../core/label.context";
import { Box } from "../components/box/box.component";

export const SignupPage = (props: any) => {
    const container = document.getElementById("register");
    const { userData } = React.useContext(GeneralContext);
    const { t } = React.useContext(LabelContext);

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
                <StyledPages>
                    <HeaderTitle title={t("ecommerce.signup.title")} message={t("ecommerce.signup.description")}></HeaderTitle>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12" style={{maxWidth: '750px'}}>
                            {!userData
                            ?
                                <Signup></Signup>
                            : 
                                userData.isEmailVerified
                                ?
                                <Box className="secondary">
                                    <h2>{t("ecommerce.signup.registered-title-email-confirmed")}</h2>
                                    <p dangerouslySetInnerHTML={{__html: t('ecommerce.signup.registered-description-email-confirmed')}}></p>
                                </Box>
                                :
                                <Box className="secondary">
                                    <h2>{t("ecommerce.signup.registered-title")}</h2>
                                    <p dangerouslySetInnerHTML={{__html: t('ecommerce.signup.registered-description')}}></p>
                                </Box>
                                
                            }

                            </div>
                        </div>
                    </div>
                </StyledPages>
            </>
            , container
        )
};