import styled from "styled-components";

export const StyledAuthentication = styled.div`

  .boxed-mobile {
    padding: 15% 30px;
  }
  input {
    text-align: center;
    border: unset;
    border-bottom: 1px solid var(--color-primary);
    /* &:focus::placeholder {
      color: transparent;
    } */
  }
  .border {
    border: 1px solid #b4b4b4;
  }
  .head {
    background-color: var(--color-white);
  }
  button {
    border-radius: none;
    color: var(--color-primary);
  }
  h3 {
    font-family: Raleway, sans-serif;
    &:hover {
      color: var(--color-primary);
    }
  }
  .card {
    margin-bottom: 2.5rem;
    border-radius: 0;
    border: 1px solid transparent;
    .card-body {
      padding: 0;
      .fixed-height{
        min-height: unset;
        padding-bottom: unset;
      }
      .passwordRecovery{
        text-align: center;
      }
    }
  }

  .card .card-header {
    border: 0;
    border-radius: 0;
    padding: 0;
    .btn-header-link {
      padding-left: unset;
      padding-top: unset;
      padding-bottom: unset;
      border-radius: 0;
      border-bottom: 1px solid var(--color-primary);
      transition: border-bottom 0s ease;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      .header-link-title{
        font-family: 'Mulish', sans-serif;
        font-weight: 300;
        font-size: 1.2rem;
      }
    }
  }

  .card .card-header .btn-header-link {
    display: flex;
    justify-content: space-between;
    place-items: center;
    &:after {
      content: "";
      float: right;
      background-image: url(../webfiles/latest/images/icons/arrow-accordion.svg);
      background-size: contain;
      display: block;
      width: 12px;
      height: 12px;
      transform: rotate(0deg);
    }
    &.collapsed {
      &:after {
        transform: rotate(180deg);
        
      }
    }
  }

  .card .collapsing {
    line-height: 30px;
  }

  .card .collapse {
    border: 0;
  }

  .card .collapse.show {
    line-height: 30px;
    color: #222;
  }

  .hideBackground {
    > div {
      background-color: unset !important;
    }
  }
`;
