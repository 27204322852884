import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { User } from "../components/user/user.component";
import { GiftCard } from "../components/giftcard/giftcard.component";
import { CustomercareInfoBoxes } from "../components/customercare/customercareInfo.component";
import { StyledTabs } from "./myAccountPage.style";
import { StyledPages } from "./commonPages.style";
import { Orders } from "../components/orders/orders.component";
import { Account } from "../components/account/account.component";
import { Wishlist } from "../components/wishlist/wishlist.component";
import { Login } from "../components/login/login.component";
import { LabelContext } from "../core/label.context";
import { GeneralContext } from "../core/general.context";
import { Box } from "../components/box/box.component";
import { automaticCloseAccountLayer } from "../common/constants";
import {IS_GIFT_CARD_ACTIVE} from "../core/APIUtils"

export const MyAccountPage = (props: any) => {
    const container = document.getElementById("myAccount");
    const { t } = React.useContext(LabelContext);
    const { userData } = React.useContext(GeneralContext);
    const [selectedTab, setSelectedTab] = React.useState(window.location.hash);
    const setSelectedTab_ = React.useRef(() => { setSelectedTab(window.location.hash); window.scrollTo(0, 0); automaticCloseAccountLayer(); });

    React.useEffect(() => {
        let currentTab = setSelectedTab_.current;
        window.addEventListener("hashchange", currentTab);
        return () => window.removeEventListener("hashchange", currentTab);
    }, []);


    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
                {userData &&
                    <StyledPages>
                        <HeaderTitle title={t("ecommerce.myaccount.my-profile")}></HeaderTitle>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <StyledTabs>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className={`tab-pane fade` + ((selectedTab === '#personalInfomations' || selectedTab === '') ? ' show active' : '')} id="personalInfomations" role="tabpanel" aria-labelledby="personalInfomations-tab"><User></User></div>
                                            <div className={`tab-pane fade` + (selectedTab === '#my-accessdata' ? ' show active' : '')} id="my-accessdata" role="tabpanel" aria-labelledby="accessdata-tab"><Account></Account></div>
                                            <div className={`tab-pane fade` + (selectedTab === '#my-orders' ? ' show active' : '')} id="my-orders" role="tabpanel" aria-labelledby="orders-tab"><Orders></Orders></div>
                                            <div className={`tab-pane fade` + (selectedTab === '#my-wishlist' ? ' show active' : '')} id="my-wishlist" role="tabpanel" aria-labelledby="wishlist-tab"><Wishlist className="unboxed secondary" /></div>
                                            <div className={`tab-pane fade` + (selectedTab === '#my-returns' ? ' show active' : '')} id="my-returns" role="tabpanel" aria-labelledby="returns-tab">
                                                <Box className="unboxed secondary unbordered">
                                                    <h2>{t("ecommerce.myaccount.returns-title")}</h2>
                                                    <Box className="secondary unbordered unboxed">
                                                        {t("ecommerce.myaccount.returns-description") && <p dangerouslySetInnerHTML={{ __html: t("ecommerce.myaccount.returns-description") }} data-translate="ecommerce.myaccount.returns-description" />}
                                                        <span dangerouslySetInnerHTML={{ __html: t("ecommerce.myaccount.returns-message") }} data-translate="ecommerce.myaccount.returns-message" />
                                                    </Box>
                                                </Box>
                                            </div>
                                            {IS_GIFT_CARD_ACTIVE && <div className={`tab-pane fade` + ((selectedTab === '#my-giftcard' || selectedTab === '') ? ' show active' : '')} id="my-giftcard" role="tabpanel" aria-labelledby="giftcard-tab"><GiftCard></GiftCard></div>}
                                            {/* <div className="tab-pane fade" id="newsLetter" role="tabpanel" aria-labelledby="newsLetter-tab">e</div> */}
                                        </div>
                                    </StyledTabs>

                                </div>
                            </div>
                            <CustomercareInfoBoxes display="row"></CustomercareInfoBoxes>
                        </div>
                    </StyledPages>
                }
                {!userData &&
                    <StyledPages>
                        <HeaderTitle title={t("ecommerce.login.page.title")}></HeaderTitle>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <Login></Login>
                                </div>
                            </div>
                        </div>

                    </StyledPages>
                }
            </>
            , container
        )
};
