import React from "react"
import {flatMap, tap} from "rxjs/operators"
import {forkJoin, of} from "rxjs"
import {EAddressType, IUserContext} from "../common/common.models"
import {IUserData} from "../common/user.models"
import {EAddAddressId, ESetAddress} from "../components/checkout/checkout.models"
import {LabelContext} from "../core/label.context"
import {AlertContext} from "./alert.context"
import {calls, PROJECT_KEY, BRX_BASE_URL, PROJECT_LANGUAGE, AUTH_PASSWORD} from "./APIUtils"
import {BannerContext} from "./banner.context"
import {RESTContext} from "./rest.context"
import {GeneralContext} from "./general.context"
import {CartContext} from "./cart.context"
import {ICart} from "../common/cart.models"
import {getBrowserInfo} from "../common/constants"

export const UserContext = React.createContext<IUserContext>(null!)

export const UserContextComponent = (props: any) => {
    const [messageActivationMail] = React.useState("")
    const alertConsumer = React.useContext(AlertContext)
    const bannerConsumer = React.useContext(BannerContext)
    const {token, setToken, ajax$, ajaxMiddleware$, AWSlogger} = React.useContext(RESTContext)
    const {getOrders, getActiveCart, setAddress} = React.useContext(CartContext)
    const {
        userData,
        setUserData,
        forgotPasswordSent,
        setForgotPasswordSent,
        giftcardActivated,
        setGiftcardActivated,
        resetPasswordState,
        setResetPasswordState,
        cart,
        setCart,
    } = React.useContext(GeneralContext)
    const {t} = React.useContext(LabelContext)

    const verifyCostumerEmail = (token: string) => {
        const body = {
            tokenValue: token,
        }

        ajax$(calls.Project.verifyMyCostumerEmail(), undefined, body).subscribe(
            (res) => {
                // Messaggio di attivazione avvenuta
                //setMessageActivationMail(t("ecommerce.pages.mail-activated-message"));
                setUserData(res)

                //bannerConsumer.show({ type: t("ecommerce.pages.login-for-activate-mail-message") })
            },
            // Token di attivazione non valido
            (err) => {
                //setMessageActivationMail(t("ecommerce.pages.mail-activation-error"));
                //alertConsumer.show({ type: err.response })
            }
        )
    }

    /* TODO handle error */
    const changePwdCustomer = (data: any) => {
        const body = {
            version: userData.version,
            currentPassword: data.oldpassword,
            newPassword: data.newpassword,
        }

        ajax$(calls.Project.changePwdCustomer(), undefined, body).subscribe(
            (res) => {
                ajax$(calls.Authorization.getAnonymousToken(), {
                    access_token: null,
                    token_type: null,
                }).subscribe((res_: any) =>
                    login(userData.email, data.newpassword, res_, () => {
                        bannerConsumer.show({
                            type: t("ecommerce.myaccount.pwd-modified"),
                        })
                        // window.location.reload();
                    })
                )
            },
            (err) => {
                bannerConsumer.show({
                    type: t("ecommerce.myaccount.change-pwd-original-pwd-wrong"),
                })
            }
        )
    }

    const updateCustomer = (data: any) => {
        const body: any = {
            version: userData.version,
            actions: [
                {
                    action: "setFirstName",
                    firstName: data.name,
                },
                {
                    action: "setLastName",
                    lastName: data.surname,
                },
                {
                    action: "setSalutation",
                    salutation: data.sex,
                }
            ],
        }

        if(data && data.birthday){
            body.actions.push({
                action: "setDateOfBirth",
                dateOfBirth:
                    data.birthday.getFullYear() +
                    "-" +
                    (parseInt(data.birthday.getMonth()) + 1) +
                    "-" +
                    data.birthday.getDate()
            })
        }else {
            body.actions.push({
                action: "setDateOfBirth",
                dateOfBirth: null
            })
        }

        if (data && data.profiling !== undefined && body && body.actions) {
            let act = {
                action: "setCustomField",
                name: "consentProfiling",
                value: data.profiling
            }
            body.actions.push(act)
        }

        ajax$(calls.Project.updateCustomer(), undefined, body).subscribe((res) => {
            setUserData(res)
        })
    }

    const signup = (data: any) => {
        const body_: any = {
            locale: PROJECT_LANGUAGE,
            email: data.email,
            password: data.password,
            firstName: data.name,
            lastName: data.surname,
            salutation: data.sex,
            anonymousCart: {
                id: cart.id,
                typeId: "cart",
            },
            custom: {
                type: {
                    typeId: "type",
                    key: "newsletter-preferences",
                },
                fields: {
                    subscribed: data.newsletter,
                    consentProfiling: data.profiling
                }
            }
        }

        if(data.birthday){
            body_["dateOfBirth"] = data.birthday?.getFullYear() + "-" + (parseInt(data.birthday.getMonth()) + 1) + "-" + data.birthday.getDate()
        }

        ajax$(calls.Project.signup(), undefined, body_)
            .subscribe(
                res => {


                    let body_verify = {
                        "ttlMinutes": 720,
                        "id": res.customer.id,
                        "projectKey": PROJECT_KEY,
                        "baseUrl": BRX_BASE_URL
                    }

                    dataLayer.push({
                        event: 'submit-form-registrazione-ok'
                    });

                    ajax$(calls.Authorization.getAnonymousToken(), {
                        access_token: null,
                        token_type: null,
                    }).subscribe((res: any) => {
                        login(data.email, data.password, res, null, false)
                    })

                    // Non occorre aspettare la risposta da BRX per invio mail
                    ajax$(calls.Project.sendVerifyMail(), undefined, body_verify).subscribe()
                },
                (err) => {
                    alertConsumer.show({type: err.response})
                }
            )
    }

    const setCustomerEmail = (email: string) => {
        AWSlogger({
            "type": "log",
            "action": "setCustomerEmail",
            "email": email,
            "browserInfo": getBrowserInfo()
        })
        const body = {
            version: cart.version,
            actions: [
                {
                    action: "setCustomerEmail",
                    email: email,
                },
                {
                    action: "setLocale",
                    locale: PROJECT_LANGUAGE
                }
            ],
        }

        ajax$(calls.Project.updateCart(cart.id), undefined, body).subscribe((res) => setCart(res))
    }

    const login = (username: string, password: string, pToken?: any, callback?: any, isLogin = true) => {
        AWSlogger({
            "type": "log",
            "action": "BeforeLogin",
            "email": username,
            "browserInfo": getBrowserInfo(),
            "cartId": cart?.id,
            "anonymousCartId": cart?.anonymousId
        })
        const body = {
            email: username,
            password,
            anonymousId: cart.anonymousId,
            anonymousCartId: cart.id,
            anonymousCartSignInMode: "MergeWithExistingCustomerCart",
        }
        ajax$(calls.Project.login(), pToken ? pToken : token, body)
            .subscribe(res_login => {
                    AWSlogger({
                        "type": "log",
                        "action": "AfterLogin",
                        "email": username,
                        "browserInfo": getBrowserInfo(),
                        "cartId": res_login?.cart?.id
                    })

                    if (isLogin) {
                        dataLayer.push({
                            'event': 'submit-form-login-ok'
                        });
                    }

                    username = encodeURIComponent(username);
                    password = encodeURIComponent(password);

                    ajax$(calls.Authorization.getCustomerToken({username, password}), null)
                        .subscribe(
                            res_token => {
                                setToken(res_token);
                                setUserData(res_login.customer);
                                //setCart(res_login.cart);

                                // Setto customerEmail nel cart
                                const body = {
                                    version: res_login.cart.version,
                                    actions: [
                                        {
                                            action: "setCustomerEmail",
                                            email: res_login.customer.email
                                        }
                                    ]

                                }
                                ajax$(calls.Project.updateCart(res_login.cart.id), res_token, body).subscribe(
                                    res_cart => {
                                        setCart(res_cart);
                                        if (callback)
                                            callback()
                                    }
                                    ,
                                    // type?.errors[0]?.code
                                    err => {
                                        if (err.response.statusCode !== 401) {
                                            alertConsumer.show({type: err.response})
                                        }
                                    }
                                )
                            },
                            (err) => {
                                if (err.response.statusCode !== 401) {
                                    alertConsumer.show({type: err.response})
                                }
                            }
                        )
                },
                (err) => {
                    // Credenziali
                    if (err.response.statusCode === 400) {
                        alertConsumer.show({type: err.response})
                    }
                }
            )
    }

    const logout = () => {
        setCart(null!)
        setUserData(null!)
        setToken(null!)
        AWSlogger({
            "type": "log",
            "action": "Logout",
            "browserInfo": getBrowserInfo(),
            "email": cart?.customerEmail,
            "cartId": cart?.id,
            "anonymousCartId": cart?.anonymousId
        })
    }

    const forgotPassword = (email: string) => {
        const body = {
            email: email,
            ttlMinutes: 720,
            projectkey: PROJECT_KEY,
            baseUrl: BRX_BASE_URL,
        }

        ajax$(calls.Project.forgotPassword(), undefined, body)
            .pipe(
                tap(
                    (res) => {
                        //TBD non è ancora pronta la risposta di successo
                        setForgotPasswordSent(true)
                    },
                    (err) => {
                        AWSlogger({
                            "type": "error",
                            "action": "forgotPassword",
                            "response": err,
                            "showPopup": "yes",
                            "browserInfo": getBrowserInfo()
                        })
                        if (err.status === 404) {
                            alertConsumer.show({
                                type: {errors: [{code: "email-not-found-in-recover-pwd"}]},
                            })
                        } else {
                            alertConsumer.show({type: err.response})
                        }
                    }
                )
            )
            .subscribe()
    }

    const activateGiftcard = (serial: string, cvc: string) => {
        const body = {
            serialNumber: serial,
            cvc: cvc,
            projectKey: PROJECT_KEY,
            password: AUTH_PASSWORD,
            authToken: token?.access_token || ""
        }

        ajaxMiddleware$(calls.Project.activateGiftCard(), undefined, body)
            .subscribe(
                res => {
                    if(res?.code === 200){
                        setGiftcardActivated("YES")
                    }else {
                        setGiftcardActivated("ERROR")
                    }
                },
                err => {
                    AWSlogger({
                        "type": "error",
                        "action": "activateGiftcard",
                        "response": err,
                        "showPopup": "yes",
                        "browserInfo": getBrowserInfo()
                    })
                    setGiftcardActivated("ERROR")
                }
            )
    }

    const resetPassword = (token: string, newPassword: string) => {
        const body = {
            token: token || null,
            newPassword: newPassword,
            projectkey: PROJECT_KEY,
            baseUrl: BRX_BASE_URL,
        }

        ajax$(calls.Project.resetPassword(), undefined, body)
            .pipe(
                tap(
                    (res) => {
                        //TBD non è ancora pronta la risposta di successo
                        setResetPasswordState(1)
                    },
                    (err) => {
                        AWSlogger({
                            "type": "error",
                            "action": "resetPassword",
                            "response": err,
                            "showPopup": "yes",
                            "browserInfo": getBrowserInfo()
                        })
                        alertConsumer.show({type: err.response})
                    }
                )
            )
            .subscribe()
    }

    const getUserData = () => {
        ajax$(calls.Project.getCustomerDetails())
            .pipe(
                tap(
                    (res) => {
                        setUserData(res)
                        getActiveCart()
                        getOrders()
                        //getWishlist();
                    },
                    (err) => {
                        getActiveCart()
                    }
                )
            )
            .subscribe()
    }

    const removeAddress = (address: any) => {
        let body = {
            version: userData.version,
            actions: [
                {
                    action: "removeAddress",
                    addressId: address.id,
                },
            ],
        }

        ajax$(calls.Project.updateCustomer(), undefined, body).subscribe((res) => {
            setUserData(res)
            getActiveCart()
        })
    }

    const addAddress = (address: any, type: EAddressType, userData_: IUserData = userData, cart_: ICart = cart) => {
        const body = {
            version: userData_.version,
            actions: [
                {
                    action: address.id ? "changeAddress" : "addAddress",
                    addressId: address?.id,
                    address: address,
                },
            ],
        }
        return ajax$(calls.Project.updateCustomer(), undefined, body).pipe(
            flatMap((res) => {
                const body_ = {
                    version: res.version,
                    actions: [
                        {
                            action: EAddAddressId[type],
                            addressId: address.id ? address.id : res.addresses[res.addresses.length - 1].id,
                        },
                    ],
                }
                return ajax$(calls.Project.updateCustomer(), undefined, body_).pipe(
                    flatMap((res_) => {
                        setUserData(res_)
                        return forkJoin(of(res_), setAddress({...address, id: body_.actions[0].addressId}, type, cart_))
                    })
                )
            })
        )
    }

    return (
        <UserContext.Provider
            value={{
                signup,
                logout,
                login,
                forgotPassword,
                resetPassword,
                verifyCostumerEmail,
                getUserData,
                addAddress,
                removeAddress,
                updateCustomer,
                changePwdCustomer,
                forgotPasswordSent,
                resetPasswordState,
                setCustomerEmail,
                messageActivationMail,
                activateGiftcard,
                giftcardActivated,
                setGiftcardActivated
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}
