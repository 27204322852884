import React from "react";
import { BannerContext } from "../../core/banner.context";
import { LabelContext } from "../../core/label.context";

export const Banner = (props: any) => {
  const { state, hide } = React.useContext(BannerContext);
  const { t } = React.useContext(LabelContext);
  return <div id="banner" className="ecommerce_alert">
    <div className="inner">

      <div className="testo">
        <div>{state.options?.type}</div>
        <div>{state.options?.description}</div>
      </div>
      

      {props.type === "confirm"
      ?
      <>
      <div className="buttons">
        <button onClick={hide}>{t("ecommerce.common.popup.cancel")}</button>
        <button onClick={ () => {state.options?.onConfirm?.(); hide(); }}>{t("ecommerce.common.popup.confirm")}</button>
      </div>
      </>
      :
      <>
        <div className="buttons">
          <button onClick={hide}>{t("ecommerce.common.popup.ok")}</button>
        </div>
      </>
      }

    </div>
  </div>
};



