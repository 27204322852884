import styled from "styled-components";

export const StyledPromoCodeForm = styled.div`
width: 100%;

input,
button {
    border-radius: 0;
    //-webkit-appearance: none;
    margin-bottom: 0.5rem;
}

button {  
    flex: 2 1 30%;
}

label {
    flex: 0 0 100%;  
}

input {
    &[type="text"] {
        line-height: 1.5;
        width: 100%;
        padding: 16px 10px;
        border: 1px solid var(--color-primary);
        padding-top: 7px;
        padding-bottom: 7px; 
        flex: 2 1 70%;
        min-width: 0;
    }

    &:disabled {
        border: 0;
    }
}

.promoInput{
  display:flex;
  height:50px;
}
`;