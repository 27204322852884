import styled from "styled-components";

export const StyledSignup = styled.div`
form{
    width: 100%;
    margin:0;
    .radioGroup__sex{
        .radioButton{
            display: inline-block;
        }
        label{
            margin-right:10px;
        }
        input{
            margin-right:5px;
        }
    }

    .boxed-mobile{
        padding:15% 30px;
    }
    /* label{
        visibility:hidden;
        } */
    input{
        text-align:center;
        border:unset;
        border-bottom:1px solid var(--color-primary);
        /* &:focus::placeholder {
            color: transparent;
        } */
    }
}
`;