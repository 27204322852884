import * as React from "react"
import ReactDOM from "react-dom"
import { Checkout } from "../components/checkout/checkout.component"
import { StyledPages } from "./commonPages.style"
import { EShippingType } from "../common/common.models"
import { GeneralContext } from "../core/general.context"
import { LabelContext } from "../core/label.context"
import { PROJECT_COUNTRY, BRX_BASE_URL, PROJECT_SETTINGS, calls } from "../core/APIUtils"
import { HeaderTitle } from "../components/header/title/headerTitle.component"
import { CartContext } from "../core/cart.context"
import { getAnalyticsProducts, parsePrice } from "../common/constants"
import { KlarnaCheckout } from "../components/klarnaCheckout/klarnaCheckout.component"
import { Authentication } from "../components/login/authentication"
import { AWSlogger, RESTContext } from "../core/rest.context";
import { BannerContext } from "../core/banner.context"


export const CheckoutPage = (_props: any) => {
  const { t } = React.useContext(LabelContext)
  const container = document.getElementById("checkout")
  const { userData, shippingMethods, currentOrder, temporaryOrder, cart } = React.useContext(GeneralContext)
  const { isEmptyCart, resetCartForCheckout, setProcessingOrder } = React.useContext(CartContext)
  const { ajax$ } = React.useContext(RESTContext);
  const bannerConsumer = React.useContext(BannerContext);
  const [guest, setGuest] = React.useState(false)
  const [redirectResult, setRedirectResult] = React.useState("")
  const params = new URLSearchParams(window.location.search)
  const result = params.get("redirectResult")
  const canProceed = Boolean(userData || guest || result)

  const isPickUpAvailable = shippingMethods?.find((store: any) => store.custom?.fields["shipping-type-list"] === EShippingType.Pickup)
  const localStorage = (window as any).localStorage || []
  const dataLayer = (window as any).dataLayer || []
  const analytics: any = localStorage?.analytics ? JSON.parse(localStorage?.analytics) : {}

  const awc = params.get('awc');

  React.useEffect(() => {
    if (result) {
      setRedirectResult(result);
    } else {
      if (container) {
        resetCartForCheckout(null, false);
      }
    }
    if (container) {

      if (canProceed) {

        dataLayer.push({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: { step: 2 },
              products: getAnalyticsProducts(cart?.lineItems),
            }
          }
        });
      } else {
        dataLayer.push({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: { step: 1 },
              products: getAnalyticsProducts(cart?.lineItems),
            }
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  React.useEffect(() => {
    if (container) {
      if (!canProceed) {
        const btns = container.querySelectorAll("button")
        btns.forEach((btn) => {
          btn.addEventListener("click", (event: any) => {
            dataLayer.push({
              event: "checkoutOption",
              ecommerce: {
                checkout_option: {
                  actionField: { step: 1, option: event?.target?.value },
                },
              },
            })
          })
        })
      }
    }
    let params: URLSearchParams = new URLSearchParams(window.location.search)
    if (params?.get("status") && params?.get("status") === 'error') {
      bannerConsumer.show({
          type: t("ecommerce.error.payment-generic-error-title"),
          description: t("ecommerce.error.payment-generic-error-description")
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (currentOrder) {
      setProcessingOrder(false)
      dataLayer.push({
        pageLang: `${analytics?.currentLang || ""}`,
        pageType: `${currentOrder ? "purchase" : analytics?.pageType}`,
        visitorId: `${userData?.id}`,
        visitorLoginState: `${userData?.id ? "logged-in" : "not-logged"}`,
        visitorNewsletterSubscription: `${userData?.custom?.fields?.subscribed ? "yes" : "no"}`,
        visitorEmail: currentOrder.customerEmail
      })

      dataLayer.push({
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: {
              step: 2,
              option: [
                `${currentOrder?.shippingInfo?.shippingMethodName} - ${currentOrder?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.paymentInterface}`,
              ],
            },
          },
        },
      })

      const { lineItems } = currentOrder

      dataLayer.push({
        pageType: "purchase",
        visitorId: `${userData?.id}`,
      })

      // integration So vendus for germany
      let sovendusOrderValue = ""
      if (["DE"].includes(PROJECT_COUNTRY)) {
        sovendusOrderValue = String((((currentOrder?.totalPrice?.centAmount - currentOrder?.shippingInfo?.price?.centAmount) / (1 + currentOrder?.lineItems[0]?.taxRate?.amount)) / 100).toFixed(2));
      }

      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currencyCode: `${currentOrder?.totalPrice?.currencyCode}`,
          purchase: {
            actionField: {
              id: `${currentOrder?.orderNumber}`,
              affiliation: 'Online store',
              revenue: `${parsePrice(currentOrder?.totalPrice, true)}`,
              tax: `${parsePrice(currentOrder?.taxedPrice?.taxPortions[0]?.amount, true)}`,
              revenueNotax: `${parsePrice(currentOrder?.taxedPrice?.totalNet, true)}`,
              shipping: `${parsePrice(currentOrder?.shippingInfo?.price, true)}`,
              coupon: `${currentOrder?.discountCodes?.map((item: any) => { return item?.discountCode?.obj?.code }).toString()}`,
              email: `${["DE"].includes(PROJECT_COUNTRY) && userData?.email}`,
              sovendusOrderValue: `${["DE"].includes(PROJECT_COUNTRY) && sovendusOrderValue}`,
              country: `${currentOrder?.shippingAddress?.country}`,
              postalCode: `${currentOrder?.shippingAddress?.postalCode}`,
            },
            products: getAnalyticsProducts(lineItems),
          }
        }
      });


      // integration https://wiki.awin.com/ marketing
      if (["DE"].includes(PROJECT_COUNTRY) && awc) {
        const awinUrl = calls.Project.awinOrderCreated(
          '20891',
          `${parsePrice(currentOrder?.totalPrice, true)}`,
          'aw',
          'DEFAULT',
          'EUR',
          `${currentOrder?.discountCodes?.map((item: any) => { return item?.discountCode?.obj?.name?.it })}`,
          `${currentOrder?.orderNumber}`,
          `0`,
          awc)

        ajax$(awinUrl)
          .subscribe(
            res =>
              AWSlogger({
                type: "log",
                action: "awinServiceSuccess",
                url: awinUrl
              })
            ,
            err => AWSlogger({
              type: "log",
              action: "awinServiceError",
              url: awinUrl
            })
          );
      }



    } else {
      dataLayer.push({
        'pageLang': `${analytics?.currentLang}`,
        'pageType': `${analytics?.pageType ? analytics?.pageType : (analytics?.sitemap === 'root') ? 'homepage' : 'other'}`,
        'visitorId': `${userData?.id}`,
        'visitorLoginState': `${userData?.id ? 'logged-in' : 'not-logged'}`,
        'visitorNewsletterSubscription': `${userData?.custom?.fields?.subscribed ? 'yes' : 'no'}`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder])

  if (!container) return null
  else
    return ReactDOM.createPortal(
      <>
        {!currentOrder && !temporaryOrder && (
          <StyledPages>
            <HeaderTitle
              title={t("ecommerce.checkout.page.title")}
              message={isPickUpAvailable && canProceed ? t("ecommerce.checkout.page.message") : ""}
            ></HeaderTitle>
            <div className="container">
              {!canProceed ? (
                <>
                  <Authentication
                    setGuest={() => {
                      setGuest(true)
                    }}
                  />
                </>
              ) : isEmptyCart ? (
                <div className="emptyCart">
                  <div className="message">{t("ecommerce.checkout.empty-cart-title")}</div>
                  <a href={BRX_BASE_URL}> &lt; {t("ecommerce.checkout.goto-shopping")} </a>
                </div>
              ) : PROJECT_SETTINGS?.isKlarnaActive ? (
                <KlarnaCheckout redirectResult={redirectResult} />
              ) : (
                  <Checkout redirectResult={redirectResult} />
              )}
            </div>
          </StyledPages>
        )}
        {currentOrder && <ThankYouMessage currentOrder={currentOrder} />}
        {temporaryOrder && !currentOrder && <ThankYouMessage currentOrder={temporaryOrder} />}
      </>,
      container
    )
}

const ThankYouMessage = (props: any) => {
  const { t } = React.useContext(LabelContext)
  let order = props.currentOrder
  let messageOrderNumberConfirmed: string = ""

  if (order.type === "Order") {
    messageOrderNumberConfirmed = t("ecommerce.checkout.order-confirmed-message-with-order-number")
    messageOrderNumberConfirmed = messageOrderNumberConfirmed.replace(
      "[ORDER_NUMBER]",
      "<b>" + order.orderNumber + "</b>"
    )
  }

  return (
    <StyledPages>
      <HeaderTitle></HeaderTitle>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            {order.type === "Order" && <h1>{t("ecommerce.checkout.order-confirmed-page-title")}</h1>}
            {order.type === "Cart" && <h1>{t("ecommerce.checkout.temporary-order-confirmed-page-title")}</h1>}
            <br />
            {order.type === "Order" && <p dangerouslySetInnerHTML={{ __html: messageOrderNumberConfirmed }}></p>}
            {order.type === "Order" && <p>{t("ecommerce.checkout.order-confirmed-title-shipping")}</p>}
            {order.type === "Cart" && <p>{t("ecommerce.checkout.temporary-order-confirmed-title-shipping")}</p>}
            {PROJECT_COUNTRY === "FR" && (
              <p>
                <b>
                  {order.shippingAddress.firstName} {order.shippingAddress.lastName}, {order.shippingAddress.streetName}
                  , {order.shippingAddress.postalCode}, {order.shippingAddress.city}
                </b>
              </p>
            )}
            {PROJECT_COUNTRY !== "FR" && (
              <p>
                <b>
                  {order.shippingAddress.firstName} {order.shippingAddress.lastName}, {order.shippingAddress.streetName}{" "}
                  {order.shippingAddress.streetNumber}, {order.shippingAddress.postalCode}, {order.shippingAddress.city}{" "}
                  ({order.shippingAddress.region})
                </b>
              </p>
            )}

            {order.type === "Order" && (
              <>
                <p dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.order-confirmed-message-in-page-1") }}></p>
                <p dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.order-confirmed-message-in-page-2") }}></p>
              </>
            )}
            {order.type === "Cart" && (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("ecommerce.checkout.temporary-order-confirmed-message-in-page-1"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("ecommerce.checkout.temporary-order-confirmed-message-in-page-2"),
                  }}
                ></p>
              </>
            )}
          </div>
        </div>
      </div>
    </StyledPages>
  )
}
