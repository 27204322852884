import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { LabelContext } from "../core/label.context";
import { StyledPages } from "./commonPages.style";
import { Wishlist } from "../components/wishlist/wishlist.component";


export const WishlistPage = (props: any) => {
    const container = document.getElementById("wishlist");
    const { t } = React.useContext(LabelContext);

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
                <StyledPages>
                    <HeaderTitle title={t("ecommerce.pages.wishlist-title")} message={t("ecommerce.pages.wishlist-message")}></HeaderTitle>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Wishlist className="secondary unboxed" inPage={true} />
                            </div>
                        </div>
                    </div>
                </StyledPages>

            </>
            , container
        )
};