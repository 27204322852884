import styled from "styled-components";

export const StyledHeaderTitle = styled.div`
    min-height:100px;
    margin-top:50px;
    margin-bottom:25px;
    i{
        padding-right: 20px;
    }

`;
