import * as React from "react";
import { CustomercareInfoTelephone } from "../../customercare/customercareInfoTelephone.component";
import { StyledHeaderTitle } from "./headerTitle";

/**
 * HEADERTITLE COMPONENT
 *
 *
 * @param {string} title   = title of section
 * @param {string} message = message of section
 *
 *
 */

export const HeaderTitle = (props: any) => {
  const { title, message } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <>
    <StyledHeaderTitle className="cmp_headerTitle">
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-12 order-md-1">
            <h1>{title}</h1>
            <p>{message}</p>
          </div>

          <div className="col-md-4 order-1 order-md-1">
            <CustomercareInfoTelephone />
          </div>
        </div>
      </div>
    </StyledHeaderTitle>
  </>
};