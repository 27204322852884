import styled from "styled-components";

export const StyledProduct = styled.div`
.productImage{
    max-width: 100%;
}
a.text-underline-hover {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
`;