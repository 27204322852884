import styled from "styled-components";

export const StyledFrequentlyBought = styled.div`
{
    display: flex;
    width: 29%;
    justify-content: space-between;
    align-items: center;
    margin: 2% 4% 2% 0;

    @media only screen and (max-width: 1500px)  {
        width: 45%;
    }

    @media only screen and (max-width: 790px)  {
        width: 100%;
        margin: 30px 0;
    }

    .product-content a{
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        align-items: center;

        .product-preview__gallery {
            margin-right: 15px;
        }

        img {
            max-width: 100px;
        }

        .name {
            color: #161616;
            line-height:normal !important;
            font-size: .75rem;
            max-height: 3rem;
            position: relative;
            overflow: hidden;

            &:after {
                content: "...";
                position: absolute;
                right: 0;
                top: 31px;
                background: #fff;
                padding-right: 30%;
            }
        }

        .name-sku {
            color: #161616;
            font-size: .625rem;
            font-weight: 100;
            line-height: normal !important;
        }
    }

    .promotion-preview__header .prices {
        text-align: left;
        line-height: normal;
        
        .price {
            font-size: .825rem;
            display: inline-block;
        }

        .price--strikethrough {
            font-size: .75rem;
            line-height: 1.3rem;
            position: static;
            display: inline-block;
            margin-right: 8px;
        }
    }

    .product-link__buy button.btn_addToCart {
        display: flex;
        align-items: center;
        font-size: .75rem;
        padding: 7px 15px;
        border: none;

        .icon {
            font-size: 40px;
            font-weight: 100;
            padding-left: 8px;
            margin-top: -3px;

            @media only screen and (max-width: 500px)  {
                padding-left: 0;
            }
        }

        @media only screen and (max-width: 500px)  {
            padding: 0;
            height: 40px;
            width: 40px;
            justify-content: center !important;
            padding: 0 !important;
        }
        
        .btn-text {
            @media only screen and (max-width: 500px)  {
                display: none;
            }
        }
        
    }

}
`;

export const StyledFrequentlyBoughtList = styled.div`
{
    width: 100%;

    .energy_label_text{
        font-size: 12px;
        line-height: normal;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 700;
    }
    
    .products-list-small {
        width: 100%; 
        display: flex; 
        flex-wrap: wrap; 
        justify-content: flex-start;
    }
    .btn-viewMore {
        border: 0;
        background: none;
        margin: 0 auto;
        font-weight: 700;
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: center;
        color: #000;

        &:hover,
        &:active,
        &:visited {
            color: #000;
        }

        .icon-more {
            font-size: 1.2rem;
            margin-right: 5px;
        }
    }
}
`;