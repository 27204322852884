import * as React from "react";
import { AlertContext } from "../../core/alert.context";
import { LabelContext } from "../../core/label.context";


export const Alert = (props: any) => {
  const { state, hide } = React.useContext(AlertContext)
  const { t } = React.useContext(LabelContext); // Not working

  let errorKey = t("ecommerce.error." + state.options?.type?.errors[0]?.code);

  if (state.options?.type?.errors[0]?.code === "InvalidOperation" ||
      state.options?.type?.errors[0]?.code ==="invalid_grant" ||
      state.options?.type?.errors[0]?.code ==="invalid_token")
        return null;
  else
    return <div className='ecommerce_alert'>
      <div className="inner">
        <div className="testo">
          <span>{errorKey}</span>
        </div>
        <div className="buttons">
          <button onClick={hide}>OK</button>
        </div>
      </div>

    </div>
};