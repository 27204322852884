import styled from 'styled-components'

export const StyledExternalPromoPopup = styled.div`
.select-is-invalid{
    border-bottom: 1px solid red;
}
button.primary:disabled {
    background-color: #b4b4b4;
}

 .header{
    .title.title-lg{
        @media (min-width: 992px) and (max-width: 1200px) {
            font-size: 1.4rem;
        }
        @media (min-width: 1201px) and (max-width: 1280px) {
            font-size: 1.8rem;
        }
        @media (min-width: 1281px) and (max-width: 1536px) {
            font-size: 1.6rem;
        }
        @media (min-width: 1537px) and (max-width: 1919px) {
            font-size: 1.8rem;
        }
    }
}

.productItem{
    @media (min-width: 1079px) and (max-width: 1919px) {
      width: 70%;
    }
    margin: 0 auto;

    @media (min-width: 1200px) {
        img.productImage{
            height: 175px !important;
            width: auto !important;
        }
    }
    
    img.productImage{
        height: auto;
        width: auto;
    }

    .textBlock{
        margin:1rem 5% 0;

        .title{
            font-size: 18px;
            padding-bottom: .5rem;
            @media (min-width: 1200px) and (max-width: 1367px) {
            font-size: 14px;
            }
        }
        .text{
            line-height: initial;
        }
    }
}
.radioButton{
        label{
            margin: 0;
            padding:0;
            width: 16px;
        }
    }

.modal-scroll-content{
    max-height: calc(100vh - 270px);
    overflow: auto;
}
`
