import * as React from "react";
import { Box } from "../box/box.component";
import { Button } from "../button/button.component";
import { StyledSignupPreview } from "./signupPreview.style";
import { BRX_BASE_URL } from "../../core/APIUtils";
import { LabelContext } from "../../core/label.context";

export const SignupPreview = (props: any) => {
  const { className } = props;
  const { t } = React.useContext(LabelContext);

  return <StyledSignupPreview className={`${className}`}>
    <Box className="boxed-mobile fixed-height secondary unbordered">
      <div className="text-center">
        <h4>{t("ecommerce.checkout.signup-title")}</h4>
        <p>{t("ecommerce.checkout.signup-description")}</p>

        <Button className="primary color--alert"
          label={t("ecommerce.checkout.signup-button")}
          redirectPath={BRX_BASE_URL + "/register"}
          value={t("ecommerce.checkout.signup-button")} ></Button>

      </div>
    </Box>
  </StyledSignupPreview >
};