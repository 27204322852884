import styled from 'styled-components'

export const StyledAlternativeProduct = styled.div`
.modal{
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 24px;

    .modal-body{
        .title{
            @media (max-width:1200px) {
                font-size: 32px;
            }
        }
    }
    img {
        width: 100%;
    }
    .alternative-product{
        img.productImage{
            height: 100px;
            width: 100px;
            display: flex;
            justify-content: center;
        }

    }
    .alt-product__img{
        display: flex;
        justify-content: center;
    }
    .backInstock{
        .textIsOutOfStock{
            text-align: center;
            font-weight: bold;
        }
        .btn_backInStock{
            width: 100%;
        }
    }
}

`
