import * as React from "react";
import { GeneralContext } from "../../core/general.context";
import { CartProduct } from "./cart.product.component";
import { CartRecap } from "./cart.recap.component";
import { LabelContext } from "../../core/label.context";
import { PROJECT_LANGUAGE, PROJECT_SETTINGS } from "../../core/APIUtils";
import { CartContext } from "../../core/cart.context";
import { SliderItems } from "../sliders/slideritems.component";


export const Cart = (props: any) => {
  const { t } = React.useContext(LabelContext);
  const { cart } = React.useContext(GeneralContext);
  const { 
    hasServicesInCart, 
    numberOfProducts,     
    recalculate,
  } = React.useContext(CartContext);
  const [promoValue, setPromoValue] = React.useState({ promo: "" });
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setPromoValue_ = (prop: any) => {
    setPromoValue(prev => ({
      ...prev,
      ...prop
    }));
  };
  
  // Utilizzato per i bundle, per evitare di inserire due volte un bundle già inserito
  let bundlesInCart:String[] = []
  React.useEffect(() => {    

    if(cart){
      recalculate(cart);
      
    }
  }, [])


  return <>
      
      <div className="checkout-column-main col-lg-8">
        <div className="wrapper">
          <div style={{ lineHeight: '22px' }} className="focus my-3" dangerouslySetInnerHTML={{ __html: t("ecommerce.cart.messageUnderTitleCart") }} />
          {PROJECT_SETTINGS.showDeliveryInStore &&
            <div className="delivery-compatible">
              <div className="label">
                <span>{hasServicesInCart ? t("ecommerce.checkout.deliveryCompatible.NO") : t("ecommerce.checkout.deliveryCompatible.YES")}</span>
                <button className="ec_btn ec_btn-moreInfo" aria-haspopup="true" data-toggle="collapse" aria-expanded="false" data-target="#delivery-compatible-info">
                  <span className="icon"></span>
                  <span className="testo">{t("ecommerce.checkout.moreInfo")}</span>
                </button>
              </div>
              <div className="collapse" id="delivery-compatible-info">
                {hasServicesInCart ? t("ecommerce.checkout.deliveryCompatible.description.NO") : t("ecommerce.checkout.deliveryCompatible.description.YES")}
              </div>
            </div>
          }

          
          <div className="cartProduct-list">
            {cart.lineItems.map((item, i) => {
              //let _relatedServices = item.variant.attributes.find(attr => attr.name === "related-services");
              let relatedServices = [{
                "sku":item.productKey,
                "productId":item.productId,                
                "value":item.variant.attributes.find(attr => attr.name === "related-services")?.value
              }]              
              let selectedServices = cart.lineItems.filter(item2 => item2.custom?.fields?.relatedLineItem?.id === item.productId)              
              let title = item.name[PROJECT_LANGUAGE];
              let displayItem = true
              let bundleKey = ""
              if(item.custom?.fields["lineitem-bundle"]){
                  if(!bundlesInCart.includes(item.custom.fields["lineitem-bundle"])){
                    bundleKey = item.custom.fields["lineitem-bundle"]
                    title = bundleKey
                    bundlesInCart.push(bundleKey)
                    let relatedServicesBundle:any = []
                    // RelatedService per tutti prodotti del bundle
                    cart.lineItems.filter(
                      (_lineItem:any) => _lineItem.custom?.fields?.["lineitem-bundle"] === bundleKey).map(
                        (__lineItem:any) => {
                          let _relatedServicesBundle = __lineItem.variant.attributes.find((attr:any) => attr.name === "related-services")
                          if(_relatedServicesBundle){
                            relatedServicesBundle.push(
                                {
                                  "sku": __lineItem.productKey,
                                  "productId":__lineItem.productId,
                                  "value": _relatedServicesBundle.value
                                }
                            )
                          }

                          // Selected Service
                          selectedServices = [
                            ...selectedServices,
                            ...cart.lineItems.filter(item2 => item2.custom?.fields?.relatedLineItem?.id === __lineItem.productId)
                          ]
                        }
                      )
                    relatedServices = relatedServicesBundle
                  }else{
                    displayItem = false
                  }
              }
              

              if (item.productType.obj?.key === "services" || !displayItem)
                return ""
              return (
                <CartProduct
                  item={item}
                  displayItem={displayItem}
                  bundleKey={bundleKey}
                  key={i}
                  ID={item.id}
                  title={title}
                  productID={item.productId}
                  name={item.name[PROJECT_LANGUAGE]}
                  sku={item.variant.sku}
                  imgSrc={item.variant.images[0]?.url}
                  quantity={item.quantity}
                  relatedServices={relatedServices}
                  selectedServices={selectedServices}
                />
              );
            })
            }
           

            {/*props.relatedBundles.map(
                  (item: any, i: any) => { 
                    return (<BundleItem inCart={true} item={item} i={i} />)
                  }
                )
            */}

          </div>
        </div>
      </div>
      <aside className="checkout-column-aside col-lg-4">
        <CartRecap isCartPage={true} />
      </aside>
     {/*TODO: COMMENTATI PER IL MOMENTO. DA RIATTIVARE il 20 novembre */}
     {props.relatedBundles.length > 0 && cart.lineItems.length > 0 && false &&
        <>
        <h2 className="title text-black mt-5 mb-0">{t("ecommerce.common.relatedBundles.title")}</h2>
        <div className="related-bundle" style={{ width: '100%' }}>
          <SliderItems items={props.relatedBundles} title={t('ecommerce.common.relatedBundles.title')} type={"bundle"} />
        </div>
        </>
      }
    
  </>

};