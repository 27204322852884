import React from "react";
import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map, switchMap} from "rxjs/operators";
import { IRESTContext, IToken } from "../common/common.models";
import { BasicAuthPassword, calls, COOKIES, PROJECT_KEY } from "./APIUtils";
import {getBrowserInfo} from "../common/constants"

const { Logger } = require('aws-cloudwatch-log-browser');

export const RESTContext = React.createContext<IRESTContext>(null!);


export const AWSlogger = (log:any) => {
  const configLogger = { 
      logGroupName: 'react-app', 
      logStreamName: PROJECT_KEY, 
      region: 'eu-west-1', 
      accessKeyId: 'AKIAQL4I5IEVH7LWRK5I', 
      secretAccessKey: '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', 
      local: false    // Optional. If set to true, the log will fall back to the standard 'console.log'.
  }
  const logger = new Logger(configLogger);
  try{
    logger.log(log)
  }catch(err){
      console.log(err)
  }
}

export const RESTContextComponent = (props: any) => { 
  const [token, setToken] = React.useState<IToken | null>(COOKIES.get("cookie_access_token") || null);

  /* === FUNZIONI === */
  const ajaxMiddleware$ = (call: any, token_ = token, body = {}): Observable<any> => {
    const complex$ = ajax({
      url: `${call.url}`,
      method: call.method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${token_?.token_type || "Basic"} ${token_?.access_token || BasicAuthPassword}`
      },
      body: body
    });
    return complex$.pipe(
      map(e => e.response),
      catchError((err, caught) => {
        throw err;
      })
    );
  };

  const ajax$ = (call: any, token_ = token, body = {}, async= true, contentType: string = "application/json"): Observable<any> => {
    const complex$ = ajax({
      url: `${call.url}`,
      method: call.method,
      headers: {
        "Content-Type": contentType,
        "Authorization": `${token_?.token_type || "Basic"} ${token_?.access_token || BasicAuthPassword}`
      },
      body: body,
      async: async
    });
    return complex$.pipe(
      map(e => e.response),
      catchError((err, caught) => {
        if (err.status === 401) {
          return refreshToken(call, body);
        } else if (err.status !== 403) {
            if (err.response && err.response.statusCode && err.response.message){
                let statusCode = err.response.statusCode
                let errMsg = err.response.message
                if((statusCode !== 400 && !errMsg.endsWith("does not have a shipping address set.")) ||
                    (statusCode !== 400 && "No active cart exists." !== errMsg)){

                    AWSlogger({
                        type: "log",
                        action: "ajax",
                        url: `${call.url}`,
                        method: call.method,
                        body: body,
                        request: err.request,
                        response: err.response,
                        browserInfo: getBrowserInfo()
                    })
                }
            }
        }
        throw err;
      })
    );
  };

  const refreshToken = (call: any, body: any) => {
    const complex$ = ajax({
      url: calls.Authorization.refreshToken(token?.refresh_token || "").url,
      method: calls.Authorization.refreshToken(token?.refresh_token || "").method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${BasicAuthPassword}`
      },
      body: {}
    });
    return complex$.pipe(
      map(e => e.response),
      catchError((err, caught) => {
        setToken(null);
        throw err;
      }),
      switchMap((res) => {
        if (!res.errors) {
          setToken(res);
          return ajax$(call, res, body);
        }
        return new Observable();
      }),
    );
  };

  const getAnonymousToken = () => {
    ajax$(calls.Authorization.getAnonymousToken())
      .subscribe((res: any) => setToken(res));
  };


  return (
    <RESTContext.Provider value={{
      token,
      setToken,
      ajax$,
      ajaxMiddleware$,
      getAnonymousToken,
      AWSlogger

    }}>
      {props.children}
    </RESTContext.Provider>
  );
};