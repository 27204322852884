import styled from "styled-components";

export const StyledLogin = styled.div`
  h4 {
    padding-bottom: 0!important;
  }
  &.easy-login {
    //background-color: var(--color-porcelain);
    height: 100%;
    .centerBlocks {
      max-width: 350px;
      margin: 0 auto;
    }
    .passwordRecovery {
      text-align: right;
    }
  }
`;
