import React from "react";
import { SYMBOLS } from "./APIUtils";
import { AWSlogger } from "./rest.context";
import { ISymbolContext } from "../common/common.models";

export const SymbolContext = React.createContext<ISymbolContext>(null!);

export const SymbolContextComponent = (props: any) => {
    const [symbols, setSymbols] = React.useState<any>([]);

    const init = () => {
        if(SYMBOLS !== null && SYMBOLS !== ""){
            setSymbols(JSON.parse(SYMBOLS));
        } else {
            console.log("Symbols not found in <div id='root'>");
            AWSlogger({
                type: "log",
                action: "symbol.context",
                message: "Failed to retrieve symbols from <div> root",
            })
        }
    };

    const getSymbolBySku = (sku: string) => {
        if (symbols && symbols.length > 0) {
            const symbolsFound = symbols.filter((s: any) => s.products.includes(sku) && (s.type === "symbol" || s.type === "extended" || s.type === "label"))
            return symbolsFound || []
        }
        return []
    }

    React.useEffect(() => {
        init();
    }, []);

    return (
        <SymbolContext.Provider value={{
            getSymbolBySku: getSymbolBySku,
            symbols
        }}>
            {props.children}
        </SymbolContext.Provider>
    );
};