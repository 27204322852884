import styled from "styled-components"

export const StyledBundlesCategory = styled.div`
.category-bundles{
    color:var(--color-primary);
    margin-top: 45px;
    margin-bottom: 100px;
    @media (min-width: 768px) {
      margin-top: 80px;
    }
  .header{
    &:not(:empty){
      border-bottom:0px solid var(--color-primary);
      text-transform: uppercase;
    }
  }
  .product-preview{
    width:auto;
  }

`
