import * as React from "react";
import ReactDOM from "react-dom";
import { LabelContext } from "../../core/label.context";
import { Box } from "../box/box.component";
import { Button } from "../button/button.component";
import { InputElement } from "../input/input.component";
import { StyledSignup } from "./signup.style";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { inputMaxLength, regexPwdValidation } from "../../common/constants";
import { UserContext } from "../../core/user.context";
import { BRX_LOCALE, PROJECT_COUNTRY } from "../../core/APIUtils"

type SignupValue = {
  name: string,
  surname: string,
  email: string,
  repeatEmail: string,
  password: string,
  repeatPassword: string,
  birthday: Date,
  sex: string,
  privacy?: any,
  newsletter?: any,
  profiling?: any
};


export const Signup = (props: any) => {
  const { className } = props;
  const { signup } = React.useContext(UserContext);
  const { t } = React.useContext(LabelContext);
  const [SignupValue] = React.useState({
    name: "",
    surname: "",
    email: "",
    repeatEmail: "",
    password: "",
    repeatPassword: "",
    birthday: "",
    sex: ""
  });

  let requiredError = t("ecommerce.form-messagge.required")
  let invalidFormatError = t("ecommerce.form-invalid-date-format")
  let invalidEmail = t("ecommerce.form-messagge.email-invalid")
  let emailNotMatchError = t("ecommerce.form-messagge.email-must-match")
  let passwordNotMatchError = t("ecommerce.form-messagge.password-must-match")
  let passwordInvalidError = t("ecommerce.form-messagge.password-invalid")

  let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

  const schema = yup.object().shape({
    name: yup.string().max(inputMaxLength).required(requiredError),
    surname: yup.string().max(inputMaxLength).required(requiredError),
    email: yup.string().matches(emailRegex, invalidEmail).required(requiredError).trim(),
    repeatEmail: yup.string().when({
      is: () => (!["DK", "NO", "SE", "FI"].includes(PROJECT_COUNTRY)),
      then: yup.string().matches(emailRegex, invalidEmail).oneOf([yup.ref('email')], emailNotMatchError).required(requiredError).trim()
    }),
    password: yup.string().max(inputMaxLength).required(requiredError).trim().matches(regexPwdValidation, passwordInvalidError),
    repeatPassword: yup.string().max(inputMaxLength).oneOf([yup.ref('password')], passwordNotMatchError).required(requiredError),
    birthday: yup.date().nullable().transform((curr, orig) => (orig === '') ? null : curr).when({
      is: () => (!["IT", "NL", "DE", "PL"].includes(PROJECT_COUNTRY)),
      then: yup.date().required(requiredError).typeError(invalidFormatError)
    }),
    sex: yup.string().nullable().transform((curr, orig) => (orig === '' || orig === "") ? null : curr).max(inputMaxLength).when({
      is: () => (!["IT", "DE", "PL"].includes(PROJECT_COUNTRY)),
      then: yup.string().required(requiredError).typeError(requiredError)
    }),
    privacy: yup.bool().required(requiredError).oneOf([true], requiredError),
    newsletter: yup.bool().oneOf([true, false]),
    profiling: yup.bool().when({
      is: () => ["DE"].includes(PROJECT_COUNTRY),
      then: yup.bool().oneOf([true, false])
    }),
  });


  const { register, handleSubmit, formState: { errors } } = useForm<SignupValue>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<SignupValue> = data => {
    signup(data);
  }

  return (
    <StyledSignup className={`${className} cmp_signup`}>
      <Box className="unbordered secondary">
        <div className="centerBlocks">
          <div className="text-center"></div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputElement
              className=""
              label={t("ecommerce.signup.firsName")}
              placeholder={t("ecommerce.signup.firsName")}
              id="name"
              externalRegister={register("name")}
              errors={errors.name?.message}
            ></InputElement>
            <InputElement
              label={t("ecommerce.signup.lastName")}
              placeholder={t("ecommerce.signup.lastName")}
              id="surname"
              externalRegister={register("surname")}
              errors={errors.surname?.message}
            ></InputElement>
            <InputElement
              label={t("ecommerce.signup.email")}
              placeholder={t("ecommerce.signup.email")}
              id="email"
              externalRegister={register("email")}
              errors={errors.email?.message}
            ></InputElement>
            {!["DK", "NO", "SE", "FI"].includes(PROJECT_COUNTRY) && (
              <InputElement
                label={t("ecommerce.signup.confirm-email")}
                placeholder={t("ecommerce.signup.confirm-email")}
                id="repeatEmail"
                externalRegister={register("repeatEmail")}
                errors={errors.repeatEmail?.message}
              ></InputElement>
            )}
            <InputElement
              type="password"
              label={t("ecommerce.signup.password")}
              placeholder={t("ecommerce.signup.password")}
              id="password"
              externalRegister={register("password")}
              errors={errors.password?.message}
            ></InputElement>
            <InputElement
              type="password"
              label={t("ecommerce.signup.confirm-password")}
              placeholder={t("ecommerce.signup.confirm-password")}
              id="repeatPassword"
              externalRegister={register("repeatPassword", {
                validate: (value) => value === SignupValue.password || "The passwords do not match",
              })}
              errors={errors.repeatPassword?.message}
            ></InputElement>
            {!["PL"].includes(PROJECT_COUNTRY) && (
            <InputElement
              label={t("ecommerce.signup.birthday")}
              type="date"
              id="birthday"
              externalRegister={register("birthday")}
              errors={errors.birthday?.message}
            ></InputElement>
            )}
            {!["IT", "PL"].includes(PROJECT_COUNTRY) && (
              <><label className="label-for-radio">{t("ecommerce.signup.gender")}</label>
                <div className="radioGroup__sex">
                  <div className="radioButton">
                    <input {...register("sex", {required: !["DE"].includes(PROJECT_COUNTRY)})} type="radio" id="male"
                           name="sex" value="male"/>
                    <label htmlFor="male">{t("ecommerce.signup.male")}</label>
                  </div>
                  <div className="radioButton">
                    <input {...register("sex", {required: !["DE"].includes(PROJECT_COUNTRY)})} type="radio" id="female"
                           name="sex" value="female"/>
                    <label htmlFor="female">{t("ecommerce.signup.female")}</label>
                  </div>
                  {["sv_SE", "fi_FI", "nb_NO", "da_DK", "de_DE", "nl_NL"].includes(BRX_LOCALE) && (
                    <div className="radioButton">
                      <input {...register("sex", {required: !["DE"].includes(PROJECT_COUNTRY)})} type="radio" id="other"
                             name="sex" value="other"/>
                      <label htmlFor="other">{t("ecommerce.signup.other")}</label>
                    </div>
                  )}
                  {errors.sex?.message && <p className="message--alert small">{errors.sex?.message}</p>}
                </div>
              </>
              )}

            <div className="checkboxButton">
              <>
                <input type="checkbox" defaultChecked={["DE"].includes(PROJECT_COUNTRY)} value="1" {...register("privacy")} name="privacy" id="privacy" />{" "}
                <label htmlFor="privacy" dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.privacy-flag") }} />{" "}
              </>
              {errors.privacy?.message && <p className="message--alert small">{errors.privacy?.message}</p>}
            </div>
            <div className="checkboxButton">
              <>
                <input type="checkbox" value="1" {...register("newsletter")} name="newsletter" id="newsletter" />{" "}
                <label
                  htmlFor="newsletter"
                  dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.newsletter-flag") }}
                />
              </>
            </div>
            {["DE"].includes(PROJECT_COUNTRY) && (
              <div className="checkboxButton">
                <>
                  <input type="checkbox" value="1" {...register("profiling")} name="profiling" id="profiling" />{" "}
                  <label
                    htmlFor="profiling"
                    dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.profiling-flag") }}
                  />
                </>
              </div>
            )}
            <Button
              className="primary mt-4 mx-auto d-block"
              label={t("ecommerce.signup.signup-button")}
              type="submit"
            />
          </form>
        </div>
      </Box>
    </StyledSignup>
  )
}

export const SignupStore = (_props: any) => {
  const container = document.getElementById("signup")

  if (!container) return null
  else return ReactDOM.createPortal(<Signup />, container)
}
