import { useEffect, useState } from "react"
import { redirectTo } from "../../common/constants"
import { StyledButton } from "./button.style"

/*
 *Parametri
 *
 *ClassName:
 *primary= Black button as wide as the box
 *secondary = Gray button with a little border
 *
 *Icon:
 *parameter accept only fontawesome classes
 *
 *Functions:
 *onChange, onClick
 *
 *Loader:
 *object {active:boolean, stopLoader:function stop loader}
 *
 *
 */

export const Button = (props: any) => {
  const { className, id, label, type, icon, loader, onClick, onChange, redirectPath, value, btn_disabled=false } = props

  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    if (loader?.stopLoader) {
      setTimeout(() => {
        setSpinner(false)
      }, 3000)
    }
  }, [loader?.stopLoader])

  return (
    <>
      <StyledButton
        id={id}
        className={`${className} cmp_button`}
        onClick={() => {
          loader && setSpinner(true)
          if (redirectPath) {
            redirectTo(redirectPath)
          } else {
            if (onClick) onClick()
          }
        }}
        value={value}
        type={type}
        onChange={onChange}
        disabled={btn_disabled}
      >
        {icon && <i className={`${icon} ${loader?.startLoader && spinner ? "d-block" : "d-none"}`}></i>} {label}
      </StyledButton>
    </>
  )
}
