import * as React from "react";
import ReactDOM from "react-dom";
import { /*IAlert, IAlertState, IAlertOptions,*/ IBannertState, IBanner, IBannerOptions } from "../common/common.models";
//import { Alert } from "../components/alert/alert.component";
import { Banner } from "../components/banner/banner.component";

export const BannerContext = React.createContext<IBanner>(null!);

export const BannerContextComponent = (props: any) => {
  const { children } = props;

  const [state, setState] = React.useState<IBannertState>({
    show: false,    
    options: {}
  });

  const confirm = (options?: IBannerOptions) => {
    setState({
      show: true,
      bannerType: 'confirm',
      options: options
    });
  };

  const show = (options?: IBannerOptions) => {
    setState({
      show: true,
      options: options
    });
  };

  const hide = () => {
    setState({
      show: false,
      options: {}
    });
  };

  return(
    <BannerContext.Provider
      value={{
        show: show,
        hide: hide,
        confirm: confirm,
        state: state
      }}
    >
      {children}
      {state.show &&
        ReactDOM.createPortal(
          <Banner type={state.bannerType}/>,
          document.body
        )
      }
    </BannerContext.Provider>
  );
};